import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { MarketContext } from "../Context";
import "../css/Error.css";

const ErrorPage = () => {
  const navigation = useNavigate();
  const location = useLocation().state;
  const { token, loca, setMainError,appname } = useContext(MarketContext);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [searchParam, setSearchParam] = useSearchParams();

  const callServer = (er) => {
    axios
      .post(`${loca}/error`, er, {
        headers: {
          Authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then(
        (res) => {
          setLoading(false);
        },
        (er) => {
          setLoading(false);
        }
      );
    // setLoading(false)
  };

  useEffect(() => {
    let er;
    // if (location) {
    // let r = { message: error };
    // er = r;
    // } else {
    if (location) {
      if (location.err.code) {
        setError(location.err);
        er = location.err;
      } else {
        let r = {
          message: location.err.stack.toString(),
          code: "600",
        };
        er = r;
        setError(r);
      }
    } else {
      er = { message: "unknown error", code: "408" };
      setError(er);
    }
    // }
    callServer(er);
  }, []);

  return (
    <div className="m-0 p-0 container-fluid text-center">
      <span className="err-background"></span>
      <span className="err-header"></span>
      <h1>
        Error <span className="err-animtion">⚠️</span>
      </h1>
      {!loading ? <p className="fs-4">{error.message}</p> : <h1>Loading...</h1>}
      <button
        className="btn btn-warning"
        onClick={() => {
          navigation(-2);
        }}
      >
        Go Back
      </button>
    </div>
  );
};

export default ErrorPage;
