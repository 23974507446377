import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function Header({ tabName, isClick, refresh, setRefresh }) {
  const navigation = useNavigate();
  return (
    <div className="pagesetup">
      <div
        className="btndiv"
        onContextMenu={(e) => {
          e.preventDefault();
        }}
      >
        <div className="d-flex justify-content-between align-items-center px-3 py-2">
          <div style={{ alignSelf: "center", textAlign: "start" }}>
            <span>
              <i
                disabled={isClick}
                className=" backic fa fa-arrow-left bck_btt_mrg"
                onClick={() => {
                  if (!isClick) {
                    navigation(-1);
                  }
                }}
              ></i>
            </span>
          </div>
          <div className="tab_head">{tabName}</div>
          <span style={{ paddingLeft: "1.5rem" }}>
            <i
              className="fa fa-refresh"
              title="Refresh"
              onClick={() => {
                setRefresh(!refresh);
              }}
              style={{ cursor: "pointer" }}
            ></i>
          </span>
        </div>
      </div>
    </div>
  );
}
