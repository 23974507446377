import React from "react";

export default function TestCompo() {
  return (
    <div className="d-flex justify-content-center flex-wrap mt-4 gap-2">
    <div
      class="s-product-image-container aok-relative s-text-center s-image-overlay-grey puis-image-overlay-grey s-padding-left-small s-padding-right-small puis-spacing-small s-height-equalized puis puis-v2fl5pkubaqu126k6zseo6li6q"
      data-cy="image-container"
      style={{paddingTop:"0px"}}
    >
      <div class="s-image-padding">
        <span
          data-component-type="s-product-image"
          class="rush-component"
          data-version-id="v2fl5pkubaqu126k6zseo6li6q"
          data-render-id="r33hwfzx56n5dv2lksdlbg4fgq2"
        >
          <a
            class="a-link-normal s-no-outline"
            target="_blank"
            tabindex="-1"
            href=""
          >
            <div class="a-section aok-relative s-image-tall-aspect">
              <img
                class="s-image"
                src="http://localhost:8081/images/items/pc2720240918_165842.jpg"
                // src="https://m.media-amazon.com/images/I/71swiEKi6lL._AC_UL320_.jpg"
                // srcset="https://m.media-amazon.com/images/I/71swiEKi6lL._AC_UL320_.jpg 1x, https://m.media-amazon.com/images/I/71swiEKi6lL._AC_UL480_FMwebp_QL65_.jpg 1.5x, https://m.media-amazon.com/images/I/71swiEKi6lL._AC_UL640_FMwebp_QL65_.jpg 2x, https://m.media-amazon.com/images/I/71swiEKi6lL._AC_UL800_FMwebp_QL65_.jpg 2.5x, https://m.media-amazon.com/images/I/71swiEKi6lL._AC_UL960_FMwebp_QL65_.jpg 3x"
                alt="United Colors of Benetton Men's Slim Shirt"
                aria-hidden="true"
                data-image-index="1"
                data-image-load=""
                // data-image-latency="s-product-image"
                // data-image-source-density="1"
              />
            </div>
          </a>
        </span>
      </div>
    </div>

    <div>
      <img src="http://localhost:8081/images/items/pc2720240918_165842.jpg" alt="" />
    </div>
    <div
      class="s-product-image-container aok-relative s-text-center s-image-overlay-grey puis-image-overlay-grey s-padding-left-small s-padding-right-small puis-spacing-small s-height-equalized puis puis-v2fl5pkubaqu126k6zseo6li6q"
      data-cy="image-container"
      style={{paddingTop:"0px"}}
    >
      <div class="s-image-padding">
        <span
          data-component-type="s-product-image"
          class="rush-component"
          data-version-id="v2fl5pkubaqu126k6zseo6li6q"
          data-render-id="r33hwfzx56n5dv2lksdlbg4fgq2"
        >
          <a
            class="a-link-normal s-no-outline"
            target="_blank"
            tabindex="-1"
            href=""
          >
            <div class="a-section aok-relative s-image-tall-aspect">
              <img
                class="s-image"
                src="https://m.media-amazon.com/images/I/618xpSJi8vL._AC_UL320_.jpg"
                // src="https://m.media-amazon.com/images/I/71swiEKi6lL._AC_UL320_.jpg"
                // srcset="https://m.media-amazon.com/images/I/71swiEKi6lL._AC_UL320_.jpg 1x, https://m.media-amazon.com/images/I/71swiEKi6lL._AC_UL480_FMwebp_QL65_.jpg 1.5x, https://m.media-amazon.com/images/I/71swiEKi6lL._AC_UL640_FMwebp_QL65_.jpg 2x, https://m.media-amazon.com/images/I/71swiEKi6lL._AC_UL800_FMwebp_QL65_.jpg 2.5x, https://m.media-amazon.com/images/I/71swiEKi6lL._AC_UL960_FMwebp_QL65_.jpg 3x"
                alt="United Colors of Benetton Men's Slim Shirt"
                aria-hidden="true"
                data-image-index="1"
                data-image-load=""
                // data-image-latency="s-product-image"
                // data-image-source-density="1"
              />
            </div>
          </a>
        </span>
      </div>
    </div>

    <div>
      <img src="https://m.media-amazon.com/images/I/618xpSJi8vL._AC_UL320_.jpg" alt="" />
    </div>
    </div>
  );
  // return (
  //     <div className="test-img">
  //     {/* <div className="s-product-image-container aok-relative s-text-center s-image-overlay-grey puis-image-overlay-grey s-padding-left-small s-padding-right-small puis-spacing-small s-height-equalized puis puis-v2fl5pkubaqu126k6zseo6li6q"> */}
  //         <img src="" alt="" />
  //     </div>
  // );
}
