import React, { Component } from "react";

export class ApForm extends Component {
  state = {
    record: [],
    sr: null,
    tabRelation: {},
    setTabRelation: null,
  };

  constructor(record, setRecord, tabRelation, setTabRelation) {
    super(record);
    this.state.record = record;
    this.state.sr = setRecord;
    this.state.tabRelation = tabRelation;
    this.state.setTabRelation = setTabRelation;
  }

  getFieldValue(name) {
    const frecord = this.state.record;
    for (let i = 0; i < frecord.length; i++) {
      if (frecord[i].name === name) {
        return frecord[i].value;
      }
    }
  }

  setFieldValue(name, value, id) {
    const frecord = this.state.record;
    for (let i = 0; i < frecord.length; i++) {
      if (
        name === "uni_id" ||
        name === "id" ||
        name === "created" ||
        name === "created_by" ||
        name === "updated" ||
        name === "updated_by"
      ) {
        break;
      }
      if (frecord[i].type === "reference" || frecord[i].type === "key_value") {
        if (frecord[i].name === name) {
          frecord[i].value.name = value; 
          if (!id) {
            frecord[i].value.id = "0";
          }else{
            frecord[i].value.id = id;
          }
          this.state.sr([...frecord]);
        }
      } else {
        if (frecord[i].name === name) {
          frecord[i].value = value;
          this.state.sr([...frecord]);
        }
      }
    }
  }

  setMandatory(name, value) {
    const frecord = this.state.record;
    for (let i = 0; i < frecord.length; i++) {
      if (
        name === "uni_id" ||
        name === "id" ||
        name === "created" ||
        name === "created_by" ||
        name === "updated" ||
        name === "updated_by"
      ) {
        break;
      }
      if (frecord[i].name === name) {
        frecord[i].uivalid.mandatory = value;
        this.state.sr([...frecord]);
      }
    }
  }

  isMandatory(name) {
    const frecord = this.state.record;
    for (let i = 0; i < frecord.length; i++) {
      if (
        name === "uni_id" ||
        name === "id" ||
        name === "created" ||
        name === "created_by" ||
        name === "updated" ||
        name === "updated_by"
      ) {
        break;
      }
      if (frecord[i].name === name) {
        let val = frecord[i].uivalid.mandatory;
        if (val === "true") {
          return true;
        } else {
          return false;
        }
      }
    }
  }

  setReadOnly(name, value) {
    const frecord = this.state.record;
    for (let i = 0; i < frecord.length; i++) {
      if (
        name === "id" ||
        name === "created" ||
        name === "created_by" ||
        name === "updated" ||
        name === "updated_by"
      ) {
        break;
      }
      if (frecord[i].name === name) {
        frecord[i].uivalid.read_only = value;
        this.state.sr([...frecord]);
      }
    }
  }

  setVisible(name, value) {
    const frecord = this.state.record;
    for (let i = 0; i < frecord.length; i++) {
      if (
        name === "uni_id" ||
        name === "id" ||
        name === "created" ||
        name === "created_by" ||
        name === "updated" ||
        name === "updated_by"
      ) {
        break;
      }
      if (frecord[i].name === name) {
        frecord[i].uivalid.visible = value;
        this.state.sr([...frecord]);
      }
    }
  }

  setFilter(value, field, op) {
    const frecord = this.state.record;
    let filt = "";
    for (let i = 0; i < frecord.length; i++) {
      if (frecord[i].name === field) {
        let type = frecord[i].type;
        filt =
          '{"co": "' +
          field +
          '", "cl": "", "mc": "' +
          op +
          '","an": "' +
          value +
          '","ct": "' +
          type +
          '","af": ""' +
          ',"rf": { "id": "' +
          value +
          '", "value": "" }}';
        //console.log("filt : " + filt);
        break;
      }
    }
    if (filt === "") {
      return undefined;
    } else {
      return JSON.parse(filt);
    }
  }

  addOption(value, label, name) {
    //console.log(name);
    const frecord = this.state.record;
    for (let i = 0; i < frecord.length; i++) {
      if (
        name === "uni_id" ||
        name === "id" ||
        name === "created" ||
        name === "created_by" ||
        name === "updated" ||
        name === "updated_by"
      ) {
        break;
      }
      if (frecord[i].name === name && frecord[i].type === "choice") {
        frecord[i].choice.push({ value: value, label: label });
        break;
      }
    }
  }

  removeOption(label, name) {
    const frecord = this.state.record;
    for (let i = 0; i < frecord.length; i++) {
      if (
        name === "uni_id" ||
        name === "id" ||
        name === "created" ||
        name === "created_by" ||
        name === "updated" ||
        name === "updated_by"
      ) {
        break;
      }
      if (frecord[i].name === name && frecord[i].type === "choice") {
        for (let j = 0; j < frecord[i].choice.length; j++) {
          if (frecord[i].choice[j].label === label) {
            frecord[i].choice.splice(j, 1);
            break;
          }
        }
        break;
      }
    }
  }

  removeAllOption(name) {
    const frecord = this.state.record;
    for (let i = 0; i < frecord.length; i++) {
      if (
        name === "uni_id" ||
        name === "id" ||
        name === "created" ||
        name === "created_by" ||
        name === "updated" ||
        name === "updated_by"
      ) {
        break;
      }
      if (frecord[i].name === name && frecord[i].type === "choice") {
        frecord[i].choice = [];
        break;
      }
    }
  }

  setRelationListVisible(name, visible) {
    const frecord = this.state.tabRelation;
    if ("relation" in frecord) {
      for (let i = 0; i < frecord.relation.length; i++) {
        if (
          name === "uni_id" ||
          name === "id" ||
          name === "created" ||
          name === "created_by" ||
          name === "updated" ||
          name === "updated_by"
        ) {
          break;
        }
        let tabName = frecord.relation[i].formRecordList[1].table.name;

        if (tabName === name) {
          frecord.relation[i].visible = visible;
        }
      }
      this.state.setTabRelation({ ...frecord });
    }
  }

  render() {
    return <div>ApForm</div>;
  }
}

export default ApForm;
