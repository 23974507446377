import axios from "axios";
import React, { useState, useContext, useEffect } from "react";
// import {  useNavigate } from "react-router-dom";
import { MarketContext } from "../Context";
import WorkInProgress from "./WorkInProgress";
import "../css/FormCSS.css";
import ModelList from "./ModelList";
import { Modal, Button } from "react-bootstrap";

const VarBlankCompo = () => {
  const { token, loca, setShowContainer, showContainer,appname } =
    useContext(MarketContext);

  // const navigation=useNavigate();

  // useState hooks
  const [loading, setLoading] = useState(true);
  // const [tabRel] = useState(false);
  // const [relation_loading, setRelationload] = useState(false);
  // const [tabRelation, setTabRelation] = useState({});
  const [page_error, setPageError] = useState(false);
  const [error, setError] = useState("");
  const [page_message, setPageMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [record, setRecord] = useState([]);
  const [refrecord, setRefRecord] = useState({});
  const [button, setButton] = useState([]);

  const [mainRecord, setMainRecord] = useState([]);
  const [seFn_type] = useState("");
  const [showModelList, setshowModelList] = useState(false);
  const [form_rcd, setform_rcd] = useState([]);

  // const rty="new";
  const [tablabel, setTabLabel] = useState("");
  const [filt, setFilt] = useState();
  const [columnId, setColumnId] = useState();
  const [objName, setObjName] = useState();
  const [objLabel, setObjLabel] = useState();
  const [objIndex, setObjIndex] = useState();
  const [objType, setObjType] = useState();
  const [setPolicyFlag] = useState(false);
  const [policyaction] = useState([]);
  const [policyFilter, setPolicyFilter] = useState([]);

  const getInitialRecord = () => {
    axios
      .get(loca + "/get/var/list", {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then(
        (resp) => {
          let res = resp.data;
          if (res.forms.length > 0) {
            setform_rcd(res.forms);
          }
          setLoading(false);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const submitFn = (val) => {
    if (val !== "none") {
      axios
        .get(loca + "/get/var/blank/" + val, {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + token,
            "market-application": appname,
          },
        })
        .then(
          (res) => {
            let blankrcd = res.data;
            setMainRecord(blankrcd);

            if ("Error" in blankrcd) {
              setError(blankrcd.Error);
            } else {
              setRecord(blankrcd.record);
              setButton(blankrcd.button);
              setTabLabel(blankrcd.form.name);
            }
          },
          (error) => {
            console.log(error);
          }
        );
    } else {
      setRecord([]);
      setButton([]);
      setTabLabel("");
    }
  };

  const setRefRcd = (vl, index, ob, type, id) => {
    formChangefn(vl, index, ob, type, id, true);
  };

  const clickRefrence = (e, id, name, type, index, label) => {
    e.preventDefault();
    setColumnId(id);
    setObjName(name);
    setObjType(type);
    setObjIndex(index);
    setshowModelList(true);
    setObjLabel(label);
  };

  const formChangefn = (vl, index, ob, type, id, vrf) => {
    var frecord = record;
    if (policyFilter !== undefined) {
      for (let i = 0; i < policyFilter.length; i++) {
        let p_policy = policyFilter;
        let name = policyFilter[i].co;
        let sign = policyFilter[i].mc;
        let answer = policyFilter[i].an;

        if (name === ob) {
          switch (sign) {
            case "=":
              if (answer === vl) {
                for (let j = 0; j < frecord.length; j++) {
                  for (let k = 0; k < policyaction.length; k++) {
                    if (frecord[j].id === policyaction[k].form_column_id) {
                      if (policyaction[k].visible !== "null") {
                        frecord[j].uivalid.visible = policyaction[k].visible;
                      }
                      if (policyaction[k].read_only !== "null") {
                        frecord[j].uivalid.read_only =
                          policyaction[k].read_only;
                      }
                      if (policyaction[k].mandatory !== "null") {
                        frecord[j].uivalid.mandatory =
                          policyaction[k].mandatory;
                      }

                      if (policyaction[k].visible === "true") {
                        policyaction[k].visible = "false";
                      } else if (policyaction[k].visible === "false") {
                        policyaction[k].visible = "true";
                      }
                      if (policyaction[k].mandatory === "true") {
                        policyaction[k].mandatory = "false";
                      } else if (policyaction[k].mandatory === "false") {
                        policyaction[k].mandatory = "true";
                      }
                      if (policyaction[k].read_only === "true") {
                        policyaction[k].read_only = "false";
                      } else if (policyaction[k].read_only === "false") {
                        policyaction[k].read_only = "true";
                      }
                      if (p_policy[i].an === "true") {
                        p_policy[i].an = "false";
                      } else if (p_policy[i].an === "false") {
                        p_policy[i].an = "true";
                      }
                      setPolicyFilter([...p_policy]);
                      // frecord[j].uivalid.read_only = policyaction[k].read_only;
                    }
                  }
                }
              }
              break;
            case ">":
              if (answer > vl) {
                setPolicyFlag(true);
              }
              break;
            case "<":
              if (answer < vl) {
                setPolicyFlag(true);
              }
              break;

            default:
              break;
          }
        }
      }
    }

    if (type === "reference") {
      if (vrf === false) {
        frecord[index].clicked = false;
      }
      if (vl.length > 2) {
        if (vrf === true) {
          if (frecord[index].name === ob) {
            frecord[index].value.name = vl;
            frecord[index].clicked = true;
            frecord[index].value.id = id;
            // setState({ record: frecord, refrecord: [] });
            setRecord([...frecord]);
            setRefRecord({});
            validationfn(vl, index, ob, type, id);
          }
        } else {
          if (frecord[index].name === ob) {
            frecord[index].value.name = vl;
            frecord[index].value.id = id;
            setRecord([...frecord]);
          }

          var veri = '{"referenceRecord":[{"columnid":"' + id + '"},';
          veri += '{"tabvalue":"' + vl + '"}]}';
          var rff = [];
          axios
            .post(loca + `/marketplace/reference/record`, veri.toString(), {
              headers: {
                "Content-Type": "application/json",
                authorization: "Bearer " + token,
                "market-application": appname,
              },
            })
            .then(
              (resp) => {
                const refrencercd = resp.data;
                if ("Error" in refrencercd) {
                } else {
                  rff = {
                    index: index,
                    record: refrencercd.referenceRecordList[2].records,
                  };
                  setShowContainer(true);
                  setRefRecord({ ...rff });
                }
              },
              (error) => {
                console.log(error);
              }
            );
        }
      } else {
        if (frecord[index].name === ob) {
          frecord[index].value.name = vl;
          frecord[index].value.id = id;
          setRecord([...frecord]);
          setRefRecord({});
        }
      }
    } else {
      if (frecord[index].name === ob) {
        frecord[index].value = vl;
        setRecord([...frecord]);
      }
    }
  };

  const fieldverify = (type, vl) => {
    if (type === "String") {
      // if (/^[a-zA-Z_\w]*$/g.test(vl)) {
      return "verified";
      // } else {
      //   return "unverified";
      // }
    }

    if (type === "email") {
      if (/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(vl)) {
        return "verified";
      } else {
        return "unverified";
      }
    }
    if (type === "int") {
      // const regex = new RegExp(/[0-9]/);
      if (/^[0-9]*$/.test(vl)) {
        return "verified";
      } else {
        return "unverified";
      }
    }
    if (type === "datetime") {
      // if (
      //   /^(?:(?:(?:0?[13578]|1[02]|(?:Jan|Mar|May|Jul|Aug|Oct|Dec))(\/|-|\.)31)\1|(?:(?:0?[1,3-9]|1[0-2]|(?:Jan|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec))(\/|-|\.)(?:29|30)\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:(?:0?2|(?:Feb))(\/|-|\.)(?:29)\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:(?:0?[1-9]|(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep))|(?:1[0-2]|(?:Oct|Nov|Dec)))(\/|-|\.)(?:0?[1-9]|1\d|2[0-8])\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/.test(
      //     vl
      //   )
      // ) {
      //   return "verified";
      // } else {
      //   return "unverified";
      // }
      return "verified";
    }
  };

  const validationfn = (vl, index, ob, type, id) => {
    var formrecord = record;
    if (type === "reference") {
      if (vl !== "") {
        var veri = '{"referenceRecord":[{"columnid":"' + id + '"},';
        veri += '{"tabvalue":"' + vl + '"}]}';
        axios
          .post(loca + `/marketplace/reference/verify`, veri.toString(), {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + token,
              "market-application": appname,
            },
          })
          .then((resp) => {
            const refrencercd = resp.data;
            if (refrencercd.Result === "Unverified") {
              formrecord[index].verified = "unverified";
              // this.setState({ record: formrecord });
              setRecord([...formrecord]);
            } else {
              formrecord[index].verified = "verified";
              // this.setState({ record: formrecord, refrecord: [] });
              setRecord([...formrecord]);
              setRefRecord([]);
            }

            // this.setState({ record: formrecord });
            setRecord([...formrecord]);

            return;
          });
      } else {
        formrecord[index].verified = "initial";
        // this.setState({ record: formrecord });
        setRecord([...formrecord]);
      }
    } else {
      if (formrecord[index].name === ob) {
        if (type === "int" || vl !== "") {
          formrecord[index].verified = fieldverify(formrecord[index].type, vl);
        } else {
          formrecord[index].verified = "initial";
        }
      }
      // this.setState({ record: formrecord });

      setRecord([...formrecord]);
    }
    // if (type === "boolean") {
    // } else {
    //   // setCheck(!check);
    // }
  };

  const callbtn = (nam) => {
    var btntype = "";
    var foundbtn = false;
    var btn = button;

    // var rcd = record;
    // var mandatory = [];
    // var unverified = [];

    for (var ij = 0; ij < btn.length; ij++) {
      if (btn[ij].value === nam) {
        btntype = btn[ij].returnWebLocation;
        foundbtn = true;
        break;
      }
    }
    if (foundbtn === true && btntype !== "") {
      setPageError(false);
      setError("");
      setLoading(false);
      setPageMessage(false);
      setMessage("");
      //

      mainRecord.record = record;
      axios
        .post(loca + btn[ij].webUrl, mainRecord, {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + token,
            "market-application": appname,
          },
        })
        .then(
          (resp) => {
            let rcdd = resp.data;
            console.log(rcdd);
          },
          (error) => {
            console.log(error);
          }
        );

      // } else {
      // 	this.setState({
      // 		page_error: true,
      // 		error: error_String,
      // 	});
      // 	document.body.scrollTop = 0;
      // 	document.documentElement.scrollTop = 0;
      // 	// this.props.unmountMe();
      // }
    }
  };

  const cancelModelList = () => {
    setFilt("null");
    setshowModelList(false);
  };

  const onSelection = (type) => {
    seFn_type(type);
    let s;
    switch (type) {
      case "onsubmit":
        s = "function onSubmit(){";
        break;
      case "onload":
        s = "function onLoad(){";
        break;
      case "onchange":
        s = "function onChange(val){";
        break;
      case "oncelledit":
        s = "function onCellEdit(val){";
        break;

      case "onreference":
        s = "function onReference(){";
        break;

      default:
        s = "";
        break;
    }
  };

  const setRef = (id, val) => {
    formChangefn(val, objIndex, objName, objType, id, true);
    cancelModelList();
  };

  useEffect(() => {
    getInitialRecord();
  });
  if (!loading) {
    return (
      <div className="pagesetup">
        <div className="bgvarcompo">
          <div className="varcompo">

          <label className="d-flex">Select Form</label>
          {/* {data.map((obj,ind) => ( */}
          <select
            className="form-select"
            aria-label="Default select example"
            onChange={(e) => {
              submitFn(e.target.value);
            }}
          >
            <option selected value={"none"}>
              None
            </option>
            {form_rcd.map((obj, ind) => (
              <option key={ind} value={obj.name}>
                {obj.name}
              </option>
            ))}
          </select>
          {/* ))} */}
          </div>
        </div>
        <div className="btndiv">
          <div className="row fr">
            <div className="col-md"></div>
            <div className="col-md tab_head">{tablabel}</div>
            <div className="col-md">
              <div className="btndivin">
                {button.map((obj, oo_i) => (
                  <button
                    className=" insrtbtn2 btn btn-primary btn-sm "
                    key={oo_i}
                    onClick={(e) => callbtn(obj.value)}
                  >
                    {obj.value}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="maincompo">
          {page_error === true && (
            <div
              className="alert alert-danger"
              role="alert"
              style={{
                padding: "0.2rem 0.2rem",
                marginBottom: "0px",
              }}
            >
              {error}
            </div>
          )}
          {page_message === true && (
            <div
              className="alert alert-success"
              role="alert"
              style={{
                padding: "0.2rem 0.2rem",
                marginBottom: "0px",
              }}
            >
              {message}
            </div>
          )}

          {record.length === 0 && <div>Dont have response...</div>}
          {record.length > 0 && (
            <div style={{ textAlign: "start" }}>
              {record.map((obj, index) => (
                <span key={index}>
                  {/* {obj.uivalid.visible === "true" && ( */}
                  <div className="  inppd " key={obj.name}>
                    {obj.type === "String" && (
                      <div className=" form-group objpdg ">
                        {/* {obj.uivalid.mandatory === "true" &&
                          obj.value !== "" && (
                            <i
                              className="fa fa-asterisk mndtryfalse"
                              
                            ></i>
                          )} */}

                        {/* {obj.uivalid.mandatory === "true" &&
                          obj.value === "" && (
                            <i
                              className="fa fa-asterisk mndtrytrue"
                              
                            ></i>
                          )} */}
                        <span>{obj.name}</span>
                        {obj.verified === "unverified" && (
                          <div
                            className="alert alert-danger"
                            role="alert"
                            style={{
                              padding: "0.2rem 0.2rem",
                              marginBottom: "0px",
                            }}
                          >
                            Please verify your charecter not accept number!
                          </div>
                        )}
                        <input
                          type="text"
                          className={
                            obj.verified === "unverified"
                              ? "form-control formpadd_danger unverifi"
                              : "form-control formpadd "
                          }
                          value={obj.value}
                          // readOnly={obj.uivalid.read_only === "true"}
                          // maxLength={obj.uivalid.max_length}
                          onChange={(e) =>
                            formChangefn(
                              e.target.value,
                              index,
                              obj.name,
                              obj.type,
                              obj.id,
                              false
                            )
                          }
                          onMouseOut={(e) =>
                            validationfn(
                              e.target.value,
                              index,
                              obj.name,
                              obj.type,
                              obj.id
                            )
                          }
                        ></input>
                      </div>
                    )}

                    {obj.type === "choice" && (
                      <div className="form-group ">

                        <span>{obj.name}</span>
                        <select
                          className="form-control form-select formpadd "
                          aria-label="Default select example"
                          value={obj.value}
                          onChange={(e) => {
                            onSelection(e.target.value);
                            formChangefn(
                              e.target.value,
                              index,
                              obj.name,
                              obj.type,
                              obj.id,
                              false
                            );
                          }}
                          // maxLength={obj.uivalid.max_length}
                          // readOnly={obj.uivalid.read_only === "true"}
                        >
                          <option value="None">None</option>

                          {obj.choice.map((ch, chi) => (
                            <option key={chi} value={ch.value}>
                              {ch.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                    {obj.type === "int" ? (
                      <div className="form-group">
                        {/* {obj.uivalid.mandatory === "true" &&
                          obj.value !== "" && (
                            <i
                                    className="fa fa-asterisk mndtryfalse"
                                    
                                  ></i>
                          )}

                        {obj.uivalid.mandatory === "true" &&
                          obj.value === "" && (
                            <i
                                    className="fa fa-asterisk mndtrytrue"
                                    
                                  ></i>
                          )} */}
                        <span>{obj.name}</span>
                        {obj.verified === "unverified" && (
                          <div
                            className="alert alert-danger"
                            role="alert"
                            style={{
                              padding: "0.2rem 0.2rem",
                              marginBottom: "0px",
                            }}
                          >
                            Please verify your integer number!
                          </div>
                        )}
                        <input
                          type="text"
                          className={
                            obj.verified === "unverified"
                              ? "form-control formpadd_danger unverifi"
                              : "form-control formpadd "
                          }
                          value={obj.value}
                          // readOnly={obj.uivalid.read_only === "true"}
                          // maxLength={obj.uivalid.max_length}
                          onChange={(e) =>
                            formChangefn(
                              e.target.value,
                              index,
                              obj.name,
                              obj.type,
                              obj.id,
                              false
                            )
                          }
                          onMouseOut={(e) =>
                            validationfn(
                              e.target.value,
                              index,
                              obj.name,
                              obj.type,
                              obj.id
                            )
                          }
                        ></input>
                      </div>
                    ) : null}
                    {obj.type === "date" ? (
                      <div className=" form-group">
                        {obj.uivalid.mandatory === "true" &&
                          obj.value !== "" && (
                            <i
                              className="fa fa-asterisk mndtryfalse"
                              
                            ></i>
                          )}

                        {obj.uivalid.mandatory === "true" &&
                          obj.value === "" && (
                            <i
                              className="fa fa-asterisk mndtrytrue"
                              
                            ></i>
                          )}
                        <span>{obj.label}</span>
                        {obj.verified === "unverified" && (
                          <div
                            className="alert alert-danger"
                            role="alert"
                            style={{
                              padding: "0.2rem 0.2rem",
                              marginBottom: "0px",
                            }}
                          >
                            please verify your date!
                          </div>
                        )}
                        <input
                          type="datetime-local"
                          className={
                            obj.verified === "unverified"
                              ? "objmargin unverifi"
                              : "objmargin "
                          }
                          value={obj.value}
                          readOnly={obj.uivalid.read_only === "true"}
                          maxLength={obj.uivalid.max_length}
                          onChange={(e) =>
                            formChangefn(
                              e.target.value,
                              index,
                              obj.name,
                              obj.type,
                              obj.id,
                              false
                            )
                          }
                          onMouseOut={(e) =>
                            validationfn(
                              e.target.value,
                              index,
                              obj.name,
                              obj.type,
                              obj.id
                            )
                          }
                        ></input>
                      </div>
                    ) : null}
                    {obj.type === "datetime" ? (
                      <div className=" form-group">
                        {obj.uivalid.mandatory === "true" &&
                          obj.value !== "" && (
                            <i
                              className="fa fa-asterisk mndtryfalse"
                              
                            ></i>
                          )}

                        {obj.uivalid.mandatory === "true" &&
                          obj.value === "" && (
                            <i
                              className="fa fa-asterisk mndtrytrue"
                              
                            ></i>
                          )}
                        <span>{obj.label}</span>
                        {obj.verified === "unverified" && (
                          <div
                            className="alert alert-danger"
                            role="alert"
                            style={{
                              padding: "0.2rem 0.2rem",
                              marginBottom: "0px",
                            }}
                          >
                            please verify your date!
                          </div>
                        )}
                        <input
                          type="datetime-local"
                          step={1}
                          className={
                            obj.verified === "unverified"
                              ? "objmargin unverifi"
                              : "objmargin "
                          }
                          value={obj.value}
                          readOnly={obj.uivalid.read_only === "true"}
                          maxLength={obj.uivalid.max_length}
                          onChange={(e) =>
                            formChangefn(
                              e.target.value,
                              index,
                              obj.name,
                              obj.type,
                              obj.id,

                              false
                            )
                          }
                          onMouseOut={(e) =>
                            validationfn(
                              e.target.value,
                              index,
                              obj.name,
                              obj.type,
                              obj.id
                            )
                          }
                        ></input>
                      </div>
                    ) : null}

                    {obj.type === "boolean" && (
                      <div className="form-check fmcheck">
                        {obj.uivalid.mandatory === "true" &&
                          obj.value !== "" && (
                            <i
                              className="fa fa-asterisk mndtryfalse"
                              
                            ></i>
                          )}

                        {obj.uivalid.mandatory === "true" &&
                          obj.value === "" && (
                            <i
                              className="fa fa-asterisk mndtrytrue"
                              
                            ></i>
                          )}

                        <input
                          type="checkbox"
                          className={
                            obj.verified === "unverified"
                              ? "checkpadd unverifi"
                              : "checkpadd"
                          }
                          // value={obj.value === "true" ? true : false}

                          checked={obj.value === "true" ? true : false}
                          // readOnly={obj.uivalid.read_only === "true"}
                          onChange={(e) => {
                            formChangefn(
                              e.target.checked.toString(),
                              index,
                              obj.name,
                              obj.type,
                              obj.id,
                              false
                            );
                          }}
                          onMouseOut={(e) =>
                            validationfn(
                              e.target.value,
                              index,
                              obj.name,
                              obj.type,
                              obj.id
                            )
                          }
                        ></input>
                        <span>{obj.name}</span>
                      </div>
                    )}

                    {obj.type === "reference" ? (
                      <div>
                        <div className="form-group">
                          <span>{obj.name}</span>
                          {obj.verified === "unverified" && (
                            <div
                              className="alert alert-danger"
                              role="alert"
                              style={{
                                padding: "0.2rem 0.2rem",
                                marginBottom: "0px",
                              }}
                            >
                              record not found!
                            </div>
                          )}
                          <div style={{ display: "flex" }}>
                            <input
                              type="text"
                              className={
                                obj.verified === "unverified"
                                  ? "form-control formpadd formbor unverifi"
                                  : "form-control formpadd formbor"
                              }
                              // maxLength={obj.uivalid.max_length}
                              value={obj.value.name}
                              // readOnly={
                              //   obj.uivalid.read_only === "true" ? true : false
                              // }
                              onChange={(e) => {
                                formChangefn(
                                  e.target.value,
                                  index,
                                  obj.name,
                                  obj.type,
                                  obj.id,
                                  false
                                );
                              }}
                              // onMouseOut={(e) =>
                              //   validationfn(
                              //     e.target.value,
                              //     index,
                              //     obj.name,
                              //     obj.type,
                              //     obj.id
                              //   )
                              // }
                            ></input>
                            <div className="btnsrc vlpointer">
                              <i
                                className="fa fa-search"
                                
                                data-bs-toggle="modal"
                                data-bs-target="#staticBackdrop"
                                onClick={(e) => {
                                  clickRefrence(
                                    e,
                                    obj.id,
                                    obj.name,
                                    obj.type,
                                    index,
                                    obj.label
                                  );
                                }}
                              ></i>
                            </div>
                          </div>
                        </div>
                        {refrecord.index === index &&
                          showContainer &&
                          refrecord.record.length > 0 && (
                            <div className="sf_container">
                              {refrecord.record.map((obj_ref, or_i) => (
                                <div
                                  className="refrcd"
                                  onClick={(e) =>
                                    setRefRcd(
                                      obj_ref.value,
                                      index,
                                      obj.name,
                                      obj.type,
                                      obj_ref.id
                                    )
                                  }
                                  key={or_i}
                                >
                                  {obj_ref.value}
                                </div>
                              ))}
                            </div>
                          )}
                      </div>
                    ) : null}
                  </div>
                  {/* )} */}
                </span>
              ))}
              <div className="div">
                {button.map((obj, oo_i) => (
                  <button
                    className=" insrtbtn2 btn btn-primary "
                    key={oo_i}
                    onClick={(e) => callbtn(obj.value)}
                  >
                    {obj.value}
                  </button>
                ))}
              </div>
            </div>
          )}
          <Modal show={showModelList} onHide={cancelModelList} size="xl">
            <Modal.Header closeButton>
              <Modal.Title>Personalized List Column</Modal.Title>
            </Modal.Header>
            <Modal.Body className="mod-ht">
              <ModelList
                setRef={setRef}
                columnid={columnId}
                onCancel={cancelModelList}
                previousFilter={filt}
                previousTimeLine={" "}
                tableName={objLabel}
                isVar={true}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={cancelModelList}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    );
  } else {
    return <WorkInProgress />;
  }
};

export default VarBlankCompo;
