import axios from "axios";
import React, { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { ListGroupItem } from "react-bootstrap";
import "../css/MainComponent.css";
import { useNavigate } from "react-router-dom";
import { MarketContext } from "../Context";

const UserNotificationCompo = ({
  msg,
  setCnt,
  callform,
  cancelNotification,
}) => {
  const { token, loca, appname } = useContext(MarketContext);
  const [loading, setLoading] = useState(false);
  const [filtarray, setFiltArray] = useState([]);
  const [pageClicked, setPageClicked] = useState(0);

  const [deletebtn, setDeleteBtn] = useState(false);

  const [sortColumn, setSortColumn] = useState({
    name: "",
    sort: true,
    init: false,
  });

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    setCnt(0);
    let jo = { messages: msg };
    axios
      .post(loca + "/marketplace/set/seen", jo, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then(
        (res) => {
          let resp = res.data;
          setDeleteBtn(true);
          // setIsActive(resp.active);
          setLoading(false);
        },
        (error) => {
          console.log(error);
        }
      );
  }, []);

  const goToRecord = (record, tabname) => {
    if (tabname !== null && record !== null) {
      callform(tabname, record, pageClicked, filtarray, sortColumn);
    }
  };

  return (
    <div>
      <div
        style={{ flexGrow: 1, maxHeight: "300px", overflowY: "auto" }}
        className="usernoti_outerline"
      >
        {msg.length > 0 &&
          msg.slice(0, 10).map((obj, index) => (
            <ListGroupItem
              action
              key={index}
              className="message-item"
              style={{ cursor: "pointer", transition: "color 0.1s" }}
              onClick={(e) => goToRecord(obj.record, obj.marketTable)}
            >
              <div>
                <div
                  className="form-control my-1 "
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div key={index}>
                    <div
                      dangerouslySetInnerHTML={{ __html: obj.message }}
                    ></div>
                  </div>
                  <div
                    className="d-flex"
                    style={{
                      fontSize: "large",
                      color: "black",
                      marginLeft: "3px",
                    }}
                  ></div>
                </div>
              </div>
            </ListGroupItem>
          ))}
      </div>
      {msg.length > 0 && (
        <div
          className=""
          style={{
            textAlign: "end",
            display: "inline-flex",
            width: "100%",
            // marginLeft: "59.8em",
            justifyContent: "end",
            marginTop: "0.5em",
          }}
        >
          <div
            className="cursor-pointer text-primary"
            onClick={() => {
              cancelNotification(true);
              navigate("list?tableName=user_notification");
            }}
          >
            See All Notification
          </div>
        </div>
      )}
    </div>
  );
};

export default UserNotificationCompo;
