import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import {MarketContext } from "../Context";
import WorkInProgress from "./WorkInProgress";
import { Bar, Line, Pie } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";

const ReportCompo = () => {
  // useContext hook
  const { token, loca,appname} = useContext(MarketContext);
  // useState hook
  const [initialReport, setInitialReport] = useState(true);
  const [reportdata, setReportdata] = useState({
    labels: [],
    datasets: [
      {
        label: "Rainfall",
        backgroundColor: ["green"],
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 1,
        data: [],
      },
    ],
  });
  const [reportlist, setReportlist] = useState([]);
  const [reporttype, setReporttype] = useState("");
  const [reportname, setReportname] = useState("");
  const [altColor, setAltColor] = useState(true);
  const [loading, setLoading] = useState(false);
  const [pageError, setPageError] = useState(false);
  const [Error, setError] = useState("");
  const [flag, setFlag] = useState(false);

  Chart.register(...registerables);
  const getAllReport = () => {
    axios
      .get(loca +"/marketplace/get/report/name", {
        headers: {
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then((res) => {
        const rcd = res.data;
        console.log(rcd);
        if ("Error" in rcd) {
          setLoading(false);
          setPageError(true);
          setError(rcd.Error);
        } else {
          console.log("checkkinggggg");
          setReportlist([...rcd.reportNameList[2].records]);
          setFlag(!flag);
        }
      });
  };

  const callReport = (nam) => {
    if (nam === "Report") {
      // this.setState({ initialReport: true });
      setInitialReport(true);
    } else {
      // this.setState({ loading: true, initialReport: false });
      setLoading(true);
      setInitialReport(false);
      axios
        .get(loca + "/marketplace/get/report/" + nam, {
          headers: {
            authorization: "Bearer " + token,
            "market-application": appname,
          },
        })
        .then((res) => {
          const report = res.data;
          const rdata = reportdata;
          var reportty = reporttype;
          if ("Error" in report) {
            //   this.setState({
            //     loading: false,
            //     page_error: true,
            //     error: report.Error,
            //   });
            setLoading(false);
            setPageError(true);
            setError(report.Error);
          } else {
            if ("reportRecord" in report) {
              rdata.datasets[0].data = [];
              rdata.labels = [];
              rdata.datasets[0].label = nam;
              for (var i = 0; i < report.reportRecord[2].record.length; i++) {
                rdata.labels.push(report.reportRecord[2].record[i].name);
                rdata.datasets[0].data.push(
                  parseInt(report.reportRecord[2].record[i].value)
                );
                if (altColor === true) {
                  rdata.datasets[0].backgroundColor.push("green");
                  setAltColor(!altColor);
                } else {
                  rdata.datasets[0].backgroundColor.push("yellow");
                  setAltColor(!altColor);
                }
              }
              reportty = report.reportRecord[3].reportInformation.chart;
              // this.setState({
              //   reportdata: rdata,
              //   reporttype: reportty,
              //   reportname: nam,
              //   loading: false,
              //   initialReport: false,
              //   altColor: altColor,
              // });
              setReportdata(rdata);
              setReporttype(reportty);
              setReportname(nam);
              setLoading(false);
              setInitialReport(false);
            }
          }
        });
    }
  };

  useEffect(() => {
    getAllReport();
  }, []);

  const reportL = () => {
    console.log("report List " + JSON.stringify(reportlist.length));
    if (reportlist.length > 0) {
      return (
        reportlist.length &&
        reportlist.map((item, i) => (
          <option key={i} value={item.name}>
            {item.name}
          </option>
        ))
      );
    }
  };

  return (
    <div className="pagesetup">
      {initialReport === true ? <div>Please select a Report</div> : null}
      {}
      <select
        className="select_opt"
        onChange={(e) => {
          callReport(e.target.value);
        }}
      >
        <option value={"Report"}>Report</option>

        {reportL()}
      </select>
      {loading === true ? <WorkInProgress></WorkInProgress> : null}
      {loading === false && initialReport === false && (
        <div>
          {reporttype === "bar" && (
            <div>
              <Bar
                data={reportdata}
                options={{
                  title: {
                    display: true,
                    text: reportname,
                    fontSize: 20,
                  },
                  legend: {
                    display: true,
                    position: "right",
                  },
                }}
              />
            </div>
          )}
          {reporttype === "pie" && (
            <div>
              <Pie
                data={reportdata}
                options={{
                  title: {
                    display: true,

                    text: reportname,
                    fontSize: 20,
                  },
                  legend: {
                    display: true,
                    position: "right",
                  },
                }}
              />
            </div>
          )}
          {reporttype === "line" && (
            <div>
             {console.log(reporttype)}
              <Line
                data={reportdata}
                options={{
                  title: {
                    display: true,

                    text: reportname,
                    fontSize: 20,
                  },
                  legend: {
                    display: true,
                    position: "right",
                  },
                }}
              />
            </div>
          )}
          {reporttype === "horizontalBar" && (
            <div>
             {console.log(reporttype)}
              <Bar
                data={reportdata}
                options={{
                  title: {
                    display: true,

                    text: reportname,
                    fontSize: 20,
                  },
                  legend: {
                    display: true,
                    position: "right",
                  },
                }}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ReportCompo;
