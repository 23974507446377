import React from "react";
import { useNavigate } from "react-router-dom";
import "../css/DashboardComponent.css";
import db from "../image/db_3.jpg";
const DashboradComponent = () => {
  const navigate = useNavigate();
  return (
    <div style={{ position: "relative", width: "100%" }}>
      <div
        className="ps-3"
        style={{ position: "absolute", width: "80%", height: "100%" }}
      >
        <div className="mt-5 ms-5">
          <div>
            <h2 className="fw-bold text-white" style={{fontFamily:"math"}}>
              Build apps that solve business problems faster.
            </h2>
          </div>
          <div className="mt-3">
            <h4 className="text-white fw-light">
              Rapidly build, test, and deploy applications that make work better
              for your organization. Start building on Now Platform with a free
              account.
            </h4>
          </div>
          <div className="mt-3">
            <button className="btn btn-warning fw-bold" onClick={()=>{
              navigate("/form?rty=new&tableName=market_application")
            }}>
              Start Building Application
            </button>
          </div>
        </div>
      </div>
      <img
        style={{
          width: "100%",
          position: "absolute",
          top: "0px",
          left:"0px",
          zIndex: "-1",
        }}
        src={db}
      />
    </div>
  );
};

export default DashboradComponent;
