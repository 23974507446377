import React, { useContext } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { MarketContext } from "../Context";

const UpdatePassword = () => {
  const {tokenName} = useContext(MarketContext)

  const navigation = useNavigate();

  const logincall = () => {
    localStorage.removeItem(tokenName);
    navigation("/login");
  };

  return (
    <div className="container d-flex justify-content-center" style={{flexGrow:1,alignItems:"center"}}>
    <div style={{flexGrow:1}} className=" d-flex justify-content-center" >
      <Card
        className="carrd"
        style={{ background: "grey",  borderRadius: "8px" }}
      >
        <CardHeader style={{ background: "grey" }}>
          <h3>UpdatePassword</h3>
        </CardHeader>

        <CardBody className="cardchild" style={{ background: "grey" }}>
          <div style={{ textAlign: "center", padding: "15px" }}>
            <i
              className="fa fa-user"
              
              style={{
                color: "white",
                border: "2.5px solid white",
                height: "4.5rem",
                width: "4.5rem",
                fontSize: "62px",
                textAlign: "center",
                borderRadius: "39px",
                marginTop: "-49",
              }}
            ></i>
          </div>
          <div className=" usericpadd input-group form-group pb-2">
            <input
              type="username"
              name="username"
              placeholder="Username"
              className="inpp "
            ></input>
          </div>

          <div className=" usericpadd input-group form-group pb-2">
            <input
              type="text"
              name="password"
              placeholder="Oldpassword"
              className=" inpp "
            ></input>
          </div>
          <div className=" usericpadd input-group form-group pb-2">
            <input
              type="text"
              name="password"
              placeholder="NewPassword"
              className=" inpp "
            ></input>
          </div>

          <div className=" usericpadd input-group form-group">
            {" "}
            <input
              type="text"
              name="password"
              placeholder="ConfirmPassword"
              className=" inpp "
            ></input>
          </div>
          <div className="form-group" style={{ marginTop: "22px" }}>
            <input type="button" value="Update" className="btn-hover"></input>
          </div>
        </CardBody>

        <CardFooter style={{ background: "grey" }}>
          <div className="d-flex links">
            <i
              className="fa fa-arrow-left"
              style={{ color: "black", marginTop: "6px" }}
            ></i>
            <a href="#" onClick={logincall} style={{ color: "black" }}>
              Login
            </a>
          </div>
        </CardFooter>
      </Card>
    </div>
    </div>
  );
};

export default UpdatePassword;
