import React from 'react'
import '../css/WorkInProgress.css'


const WorkInProgressSmall = () => {
  return (
    <div>
    <div className="spinner-border text-primary" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>
  )
}

export default WorkInProgressSmall
