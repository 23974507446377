import React, { useContext, useEffect, useRef, useState } from "react";
import { Modal, Button, Tab, Nav } from "react-bootstrap";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import axios from "axios";
import RelationList from "./RelationList";
import { MarketContext } from "../Context";
import { toast } from "react-toastify";
import WorkInProgress from "./WorkInProgress";

const ConfigureCompo = () => {

  // Base Hooks
  const { loca, token,appname } = useContext(MarketContext)
  const [searchParam, setSearchParam] = useSearchParams();

  // UseStates
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(true);
  const [pageError, setPageError] = useState(true);
  const [record, setRecord] = useState([]);
  const [tabRelation, setTabRelation] = useState([]);

  // UseRefs
  const tableName = useRef("")
  const i_d = useRef(-1)

  // Functions
  const relationVerify = () => {
    tableName.current = searchParam.get("tableName");
    if (tableName.current) {
      var json = {
        relation: [
          {
            tabname: tableName.current
          },
        ],
      };
      setLoading(true);
      axios
        .post(loca + "/marketplace/get/config/relation", json, {
          headers: {
            authorization: "Bearer " + token,
            "market-application":appname
          },
        })
        .then(
          (resp) => {
            const rvrecord = resp.data;
            if ("Error" in rvrecord) {
              setPageError(true);
              setError(rvrecord.error);
              setLoading(false);
            } else {
              if (rvrecord.relation.length === 0) {
                setTabRelation(rvrecord);
                //   tabRe.current = rvrecord;
                // setTabRel(true);
                setLoading(false)
              } else if ("Error" in rvrecord.relation[0]) {
                setLoading(false);
              } else {
                setTabRelation(rvrecord);
                setLoading(false);

                //   tabRe.current = rvrecord;
              }
              // // setTimeout(() => {
              // // setRelationload(false);
              // checkRefrecord();
              // // }, 1000);
            }
          },
          (error) => {
            console.log(error);
          }
        );
    } else {
      toast.error("Table Name not Found")
      setLoading(false)
      return
    }
  };

  // UseEffects
  useEffect(() => {
    relationVerify()
  }, []);

  if (!loading) {
    return (
      <div className="overfl-tab">
        {tabRelation.relation.length > 0 ? (
          <div >
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey={
                tabRelation.relation[
                  tabRelation.relation.findIndex((o, i) => o.visible === "true")
                ].formRecordList[1].table.label
              }
            >
              <Nav variant="pills" className="flex-column ownNav">
                <div className="rel_flex rw">
                  {tabRelation.relation.map((nama, tab_ia) => (
                    <div
                      style={{
                        display: nama.visible === "true" ? "" : "none",
                      }}
                      md={2}
                      key={tab_ia}
                      className="nopad"
                    >
                      {isMobile ? (
                        <Nav.Item className="cur">
                          <Nav.Link
                            className=""
                            eventKey={nama.formRecordList[1].table.label}
                          >
                            {nama.formRecordList[3].page
                              .record_count !== "0"
                              ? nama.formRecordList[1].table
                                .label +
                              " (" +
                              nama.formRecordList[3].page
                                .record_count +
                              ")"
                              : nama.formRecordList[1].table
                                .label}
                          </Nav.Link>
                        </Nav.Item>
                      ) : (
                        <Nav.Item color="black" className="cur">
                          <Nav.Link eventKey={nama.formRecordList[1].table.label}>
                            {nama.formRecordList[3].page
                              .record_count !== "0"
                              ? nama.formRecordList[1].table
                                .label +
                              " (" +
                              nama.formRecordList[3].page
                                .record_count +
                              ")"
                              : nama.formRecordList[1].table
                                .label}
                          </Nav.Link>
                        </Nav.Item>
                      )}
                    </div>
                  ))}
                </div>
              </Nav>

              <Tab.Content>
                {tabRelation.relation.map((nam, tab_i) => (
                  <Tab.Pane
                    key={tab_i}
                    eventKey={nam.formRecordList[1].table.label}
                  >
                    <RelationList
                      listname={nam.formRecordList[1].table.label}
                      tableName={nam.formRecordList[1].table.name}
                      recordList={nam}
                      i_d={i_d.current}
                      prevTab={tableName.current}
                      rd={record}
                    // showFormCompo={showFormCompo(nm, rid, ty)}
                    ></RelationList>
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Tab.Container>
          </div>
        ) : null}
      </div>
    );
  } else {
    return <WorkInProgress />
  }
};


export default ConfigureCompo;