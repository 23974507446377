import React, { useState, useContext } from "react";
import "../css/PrivateWrapper.css";
import { Outlet, createSearchParams, useNavigate } from "react-router-dom";
import { MarketContext } from "../Context";
import { Button } from "react-bootstrap";
import UserNotificationCompo from "./UserNotificationCompo";
import ImpersonateCompo from "./ImpersonateCompo";
import { ToastContainer } from "react-toastify";
import LeftNavigationComponent from "./LeftNavigationComponent";
import LogoutModal from "../small_compo/LogoutModal";
import CommonModal from "../small_compo/CommonModal";

const UserWrapper = () => {
  const {
    token,
    loca,
    flagg,
    setFlagg,
    setShowContainer,
    userDetails,
    msg,
    setCnt,
    cnt,
    user,
    appname,
    userVerified,
  } = useContext(MarketContext);
  const navigation = useNavigate();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  // const [msg, setMsg] = useState([]);
  // const [cnt, setCnt] = useState("");
  const [showEye, setShowEye] = useState(false);

  const Home = () => {
    navigation("/user/dashboard");
  };

  const opennav = () => {
    document.getElementById("mySidebar").style.width = "100%";
    document.getElementById("mySidebar").style.marginLeft = "0";
    // window.scroll({ top: 0, behavior: "smooth" });
    setFlagg(true);
  };

  const openModal = () => {
    console.log("open");
    setIsOpen(true);
  };

  const cancelNotification = () => {
    // console.log("open");
    setShowNotification(false);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const cancelImpersonation = () => {
    setShowEye(false);
  };

  const callform = (tab, r_id, pageClicked, filtarray, sortColumn) => {
    let pg = { page: pageClicked, filter: filtarray, sort: sortColumn };
    localStorage.setItem("pageClicked", JSON.stringify(pg));
    navigation({
      pathname: user + "/form",
      search: createSearchParams({
        rty: "record",
        tableName: tab,
        rid: r_id,
      }).toString(),
    });
    cancelNotification();
  };
  const navModals = (show, hide, title, body, footer) => {
    return (
      <CommonModal
        show={show}
        hide={hide}
        title={title}
        body={body}
        footer={footer}
      />
    );
  };

  if (userVerified) {
    return (
      <div>
        <div id="topbar" className="mainTopBar">
          <div className="disfl mainbgcolor gutter">
            <div className="fl1 no_pad disfl">
              {!flagg && (
                <div className="gutter">
                  <div className="arrow_icon no_pad">
                    <span>
                      <i
                        className="primary_clr backic fa fa-arrow-right  "
                        
                        onClick={opennav}
                      ></i>
                    </span>
                  </div>
                </div>
              )}
              <div className="marleft10 marright10">
                <div className="homeicon">
                  <i
                    className="fa fa-home home_top"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Home"
                    
                    onClick={() => Home()}
                  ></i>
                </div>
              </div>
              <span className="text-light u-name text-capitalize">
                {appname}
              </span>
            </div>
            <div className="fl1 no_pad">
              <div className="rig">
                <span className="u-name ">{userDetails.username}</span>
                <span className="usericon ">
                  <i
                    className="fa fa-user-circle-o"
                    
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Profile"
                    onClick={() => openModal()}
                  ></i>
                </span>
                <span className="usericon ">
                  <i
                    className="fa fa-bell"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Notification"
                    
                    onClick={() => setShowNotification(true)}
                  >
                    {cnt > 0 && <span className="span-cnt">{cnt}</span>}
                  </i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          className="row fr"
          onClick={() => {
            setShowContainer(false);
          }}
        >
          <div
            id="windowscreen"
            className={flagg ? "cl_small gx-0" : "cl_close"}
          >
            <LeftNavigationComponent />
          </div>

          <div className={flagg ? "cl_big gx-0" : "cl_bigClose gx-0"}>
            {/* <ToastContainer /> */}
            <Outlet />
          </div>
        </div>

        <div>
          <LogoutModal
            modalIsOpen={modalIsOpen}
            closeModal={closeModal}
            username={userDetails.username}
          />

          {navModals(
            showNotification,
            cancelNotification,
            "Notification",
            <UserNotificationCompo
              setCnt={setCnt}
              msg={msg}
              token={token}
              loca={loca}
              callform={callform}
              cancelNotification={cancelNotification}
            />,
            <Button variant="secondary" onClick={cancelNotification}>
              Close
            </Button>
          )}

          {navModals(
            showEye,
            cancelImpersonation,
            "Impersonation",
            <ImpersonateCompo cancel={cancelImpersonation} />,
            <button
              className="btn btn-danger"
              variant="secondary"
              onClick={cancelImpersonation}
            >
              Close
            </button>
          )}
        </div>
      </div>
    );
  }
};
export default UserWrapper;
