import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { MarketContext } from "../Context.jsx";
import { Card, CardBody, CardHeader, CardFooter } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "../css/Authentication.css";

const GenerateNewPin = () => {
  const { loca, appname } = useContext(MarketContext);
  const navigation = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [flag, setFlag] = useState(true);
  const [app, setApp] = useState("none");
  const [appList, setAppList] = useState([]);
  // const [choice, setChoice] = useState(appList);

  const logincall = () => {
    navigation("/login");
  };

  const change_password = () => {
    if (password === confirmPassword) {
      let email = localStorage.getItem("email");

      let fp = {
        forget_password: { email: email, password: password, application: app },
      };
      axios
        .post(loca + "/marketplace/set/createnewpassword", fp, {
          headers: {
            "market-application": appname,
          },
        })
        .then(
          (resp) => {
            let cnp = resp.data;
            if ("Error" in cnp) {
              console.log(cnp.Error);
              toast.error(cnp.Error);
            } else {
              console.log(cnp.Success);
              navigation("/login");
              toast.success(cnp.Success);
            }
          },
          (error) => {
            navigation("/error");
            console.log(error);
          }
        );
    } else {
      toast.error("Password not matched");
    }
  };

  useEffect(() => {
    axios
      .get(loca + "/marketplace/registration/client", {
        headers: {
          "market-application": appname,
        },
      })
      .then((resp) => {
        const mltpgrecord = resp.data;
        var mmm = mltpgrecord.formRecord[2].record;
        setAppList(mmm[15].lookup);
      });
  }, []);

  return (
    <div className="parent-div">
      <Card className="card-width-manual bg-auth-1">
        <CardHeader>
          <h4 className="text-center">Set New Password</h4>
          <hr className="my-2" />
        </CardHeader>

        <CardBody className="py-0 text-center">
          <div className="my-2">
            <i
              className="fa fa-user"
              
              style={{
                border: "2.5px solid white",
                height: "4.5rem",
                width: "4.5rem",
                fontSize: "62px",
                textAlign: "center",
                borderRadius: "39px",
              }}
            ></i>
          </div>

          <div className="mt-4">
            <div className=" pb-2">
              <input
                type={flag ? "password" : "text"}
                name="password"
                value={password}
                placeholder="NewPassword"
                className="inpptt"
                onChange={(e) => setPassword(e.target.value)}
              ></input>
            </div>

            <div className="pb-2">
              <input
                type={flag ? "password" : "text"}
                name="password"
                value={confirmPassword}
                placeholder="ConfirmPassword"
                className="inpptt"
                onChange={(e) => setConfirmPassword(e.target.value)}
              ></input>
            </div>
            <div className="pb-2">
              <select
                className="form-select inpptt-select"
                aria-label="Default select example"
                onChange={(e) => {
                  setApp(e.target.value);
                }}
              >
                <option value="None">Select Application</option>
                {appList.map((ch, chi) => (
                  <option key={chi} value={ch.name}>
                    {ch.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="d-flex align-items-center  justify-content-center">
              <span> Show Password</span>
              <input
                className="ms-2 manual-checkbox"
                type="checkbox"
                checked={!flag}
                onChange={(e) => {
                  setFlag(!e.target.checked);
                }}
                
              />
            </div>
          </div>

          <div className="mt-3 mt-2 mb-3">
            <button
              className="btn btn-warning btn-width"
              onClick={change_password}
            >
              Submit
            </button>
          </div>
          <hr className="mt-2 mb-0" />
        </CardBody>

        <CardFooter>
          <div className="d-flex align-items-center gap-1 mb-1 mx-1">
            <i className="fa fa-arrow-left"></i>
            <span onClick={logincall}>Re-Login</span>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};

export default GenerateNewPin;
