import axios from "axios";
import React from "react";
import { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import "../css/FormCSS.css";
import "../css/relationlist.css";
import { useLocation, useNavigate } from "react-router-dom";
import { MarketContext } from "../Context";
import WorkInProgress from "./WorkInProgress";

const AppAccess = ({ }) => {
  // useContext hooks
  const navigation = useNavigate();
  const { token, loca,appname } = useContext(MarketContext);

  //  //   useLocation hook
  //  const { i_d } = useLocation().state;

  // useState hooks
  const [columnarray, setColumnarray] = useState([]);
  const [columnarray2, setColumnarray2] = useState([]);
  const [loading, setLoading] = useState(true);
  const [roles, setRoles] = useState([]);
  const [json, setJson] = useState({ role_id: 0, app: [] });
  const [flag, setFlag] = useState(false);
  const [isProcessing, setProcessing] = useState(false);
  const [isSet, setIsSet] = useState(false);
  const [page_error, setPageError] = useState(false);
  const [error, setError] = useState("");
  const [page_message, setPageMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [isClick, setIsClick] = useState(false);

  const getRoleList = () => {
    var tablere = '{"formRecordList":[';
    tablere += '{"application":{"id":"1","name":"marketplace"}}';
    tablere += ',{"table":{"id":"","name":"local_user"}}';
    tablere += ',{"records":[]}';
    tablere +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    tablere += ',{"sort":{"asc":"true","column":"id","init":"false"}}';
    tablere +=
      ',{"filter": [{"co": "","cl": "","mc": "", "an": "","ct": "","af": "","rf":{"id":"","value":""}}]}';
    tablere += ',{"timeLine":"' + "" + '"}]}';
    axios
      .post(loca + "/marketplace/get/multiple/allrecord", tablere.toString(), {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then(
        (resp) => {
          let roleList = resp.data;
          let recordList = roleList.formRecordList[2].records;
          if ("Error" in roleList) {
          } else {
            let name = [];
            let id = [];
            for (let i = 0; i < recordList.length; i++) {
              for (let j = 0; j < recordList[i].record.length; j++) {
                if (j === 0 && recordList[i].record[j].name === "id") {
                  id.push(recordList[i].record[j].value);
                }
                if (recordList[i].record[j].name === "name") {
                  name.push(recordList[i].record[j].value);
                }
              }
            }
            let col_mn = [];
            for (let i = 0; i < name.length; i++) {
              if (name[i] !== "external") {
                col_mn.push({ label: name[i], id: id[i] });
              }
            }

            setRoles(col_mn);
            setLoading(false);
          }
        },
        (error) => {
          navigation("/error")
        }
      );
  };

  const getAppList = () => {
    var tablere = '{"formRecordList":[';
    tablere += '{"application":{"id":"1","name":"marketplace"}}';
    tablere += ',{"table":{"id":"","name":"market_application"}}';
    tablere += ',{"records":[]}';
    tablere +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    tablere += ',{"sort":{"asc":"true","column":"id" ,"init":"false"}}';
    tablere +=
      ',{"filter": [{"co": "","cl": "","mc": "", "an": "","ct": "","af": "","rf":{"id":"","value":""}}]}';
    tablere += ',{"timeLine":"' + "" + '"}]}';
    axios
      .post(loca + "/marketplace/get/multiple/allrecord", tablere.toString(), {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then(
        (resp) => {
          let roleList = resp.data;
          let recordList = roleList.formRecordList[2].records;
          if ("Error" in roleList) {
          } else {
            let name = [];
            let id = [];
            let app_code = [];
            for (let i = 0; i < recordList.length; i++) {
              for (let j = 0; j < recordList[i].record.length; j++) {
                if (j === 0 && recordList[i].record[j].name === "id") {
                  id.push(recordList[i].record[j].value);
                }
                if (recordList[i].record[j].name === "name") {
                  name.push(recordList[i].record[j].value);
                }
                if (recordList[i].record[j].name === "app_code") {
                  app_code.push(recordList[i].record[j].value);
                }
              }
            }
            let col_mn = [];
            for (let i = 0; i < name.length; i++) {
              col_mn.push({
                label: name[i],
                id: id[i],
                app_code: app_code[i],
                check: false,
              });
            }
            setColumnarray(col_mn);
            getRoleList();
          }
        },
        (error) => {
          navigation("/error")
        }
      );
  };

  const getInitialDetails = (val) => {
    setError("")
    setMessage("")
    setPageMessage(false)
    setPageError(false)
    let j = { role_id: val };
    axios
      .post(loca + "/marketplace/get/appaccess", j, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then(
        (resp) => {
          let result = resp.data;
          setColumn(result.appList);
        },
        (error) => {
          navigation("/error")
        }
      );
  };

  const setColumn = (prf) => {
    var clm = columnarray;
    var clm2 = [];
    // var prf = prefarray;
    for (var ii = 0; ii < clm.length; ii++) {
      clm[ii].check = false;
    }
    for (var i = 0; i < clm.length; i++) {
      if (prf) {
        for (var p = 0; p < prf.length; p++) {
          if (clm[i].id === prf[p].id) {
            clm[i].check = true;
            clm2.push({
              name: clm[i].label,
              id: clm[i].id,
              app_code: clm[i].app_code,
              access: "true",
            });
          } else {
            clm2.push({
              name: clm[i].label,
              id: clm[i].id,
              app_code: clm[i].app_code,
              access: "false",
            });
          }
        }
      }
    }
    setColumnarray([...clm]);
    setColumnarray2([...clm2]);
    // oneRef.current = false;
  };

  const changeCheckBox = (e, index) => {
    var col = columnarray;
    col[index].check = e;
    setColumnarray([...col]);
  };

  const setColumnbtn = () => {
    if (json.role_id === 0 || json.role_id === "none") {
      setFlag(true);
    } else {
      var colm = columnarray;
      var colm2 = [];
      for (var i = 0; i < colm.length; i++) {
        if (colm[i].check === true) {
          colm2.push({
            name: colm[i].label,
            id: colm[i].id,
            app_code: colm[i].app_code,
            access: "true",
          });
        } else {
          colm2.push({
            name: colm[i].label,
            id: colm[i].id,
            app_code: colm[i].app_code,
            access: "false",
          });
        }
      }
      setColumnarray2([...colm2]);
      setIsSet(true);
      setFlag(false);
    }
  };

  const submitColumnbtn = () => {
    setIsClick(true);
    setLoading(true);
    setError("")
    setMessage("")
    setPageMessage(false)
    setPageError(false)
    setIsSet(false);
    setProcessing(true);
    json.app = [];
    for (let i = 0; i < columnarray2.length; i++) {
      json.app.push(columnarray2[i]);
    }

    axios
      .post(loca + "/marketplace/set/appaccess", json, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then(
        (resp) => {
          let result = resp.data;
          if ("Error" in result) {
            setPageError(true);
            setError(result.Error);

          } else {
            setPageMessage(true);
            setMessage(result.Message);
          }
          setLoading(false);
          setProcessing(false);
        },
        (error) => {
          navigation("/error")
          setProcessing(false);
          console.log(error);
        }
      )
      .finally(() => {
        setIsClick(false);
      });
  };

  const setRoleId = (val) => {
    if (val !== "none") {
      let j = json;
      j.role_id = val;
      setJson({ ...j });
      getInitialDetails(val);
      setFlag(false);
    } else {
      let clm = columnarray;
      for (var ii = 0; ii < clm.length; ii++) {
        clm[ii].check = false;
      }
      setColumnarray([...clm]);
      setColumnarray2([]);
    }
  };

  //   useEffect hooks
  useEffect(() => {
    getAppList();
  }, []);

  if (!loading) {
    return (
      <div id={"form"} className="pagesetup">
        <div
          className="btndiv"
          onContextMenu={(e) => {
            e.preventDefault();
            // m_rid.current = obj.id;
            // setContext(e.button, e.nativeEvent.pageX, e.nativeEvent.pageY);
          }}
        >
          <div className="row fr">
            <div
              className="col-md-3"
              style={{ alignSelf:"center", textAlign: "start" }}
            >
              <span className="m-5">
                <i
                  disabled={isClick}
                  className=" backic fa fa-arrow-left bck_btt_mrg"
                  onClick={() => {
                    if (!isClick) {
                      navigation(-1);
                    }
                  }}
                ></i>
              </span>
            </div>
            <div className="col-md tab_head">
              Jwt Application Access
            </div>
            <div className="col-md-3"></div>
          </div>
        </div>
        <div
          style={{ flexGrow: 1 }}
          className="container justifyContentCenter align-items-center my-5 "
        >

          <div className="row" style={{ justifyContent: "center", gap: "3px" }}>

            <div className="card col-md-5">
              <select
                className="form-select my-2"
                aria-label="Default select example"
                onChange={(e) => {
                  setRoleId(e.target.value);
                }}
              >
                <option value={"none"} selected>None</option>
                {roles.map((obj, index) => (
                  <option key={index} value={obj.id}>
                    {obj.label}
                  </option>
                ))}
              </select>
              {columnarray.length > 0 &&
                columnarray.map((obj, obj_i) => (
                  <div key={obj_i}>
                    <div className="row ">
                      <div className="col-md-10">{obj.label}</div>
                      <div className="col-md-2">
                        <input
                          className="chx_bx "
                          type="checkbox"
                          checked={obj.check ? true : false}
                          onChange={(e) => {
                            changeCheckBox(e.target.checked, obj_i);
                          }}
                        ></input>
                      </div>
                    </div>
                  </div>
                ))}
              {flag && (
                <p style={{ color: "red", textAlign: "start" }}>
                  please select the Role
                </p>
              )}
            </div>
            <div className="card col-md-5">
              {page_error === true && (
                <div
                  className="alert alert-danger"
                  role="alert"
                  style={{
                    padding: "0.2rem 0.2rem",
                    marginBottom: "0px",
                  }}
                >
                  {error}
                </div>
              )}
              {page_message === true && (
                <div
                  className="alert alert-success"
                  role="alert"
                  style={{
                    padding: "0.2rem 0.2rem",
                    marginBottom: "0px",
                  }}
                >
                  {message}
                </div>
              )}
              {columnarray2.length > 0 &&
                columnarray2.map((obp, obj_p) => (
                  <div key={obj_p}>
                    {obp.access === "true" && <div>{obp.name}</div>}
                  </div>
                ))}
            </div>
            <div className="row my-3" style={{ justifyContent: "inherit" }}>
              {/* <div className="col-md-4"></div> */}
              <div className="col-md-3">
                <button
                  style={{ borderRadius: "5px" }}
                  type="button"
                  className=" btnnn btn btn-primary"
                  onClick={() => {
                    setColumnbtn();
                  }}
                >
                  Set
                </button>
              </div>
              <div className="col-md-3">
                <button
                  type="button"
                  style={{ borderRadius: "5px" }}
                  className=" btnnn btn btn-primary"
                  disabled={!isSet}
                  onClick={() => {
                    submitColumnbtn();
                  }}
                >
                  {isProcessing ? (
                    <span>
                      <span
                        className="spinner-grow spinner-grow-sm me-2"
                        role="status"
                        
                      ></span>
                      Loading...
                    </span>
                  ) : (
                    "submit"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="compo-loader">
        <WorkInProgress />
      </div>
    );
  }
};

export default AppAccess;



