import axios from "axios";
import React, { useState, useEffect } from "react";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { Button, Card, Col, Container, Input, Row, Table } from "reactstrap";
import { MarketContext } from "../Context";

const ClientInfoCompo = () => {
  const { token, loca,appname} = useContext(MarketContext);

  const { filter } = useLocation().state || null;
  const [gridCount, setGridCount] = useState({
    noOfClm: 5,
    noOfRows: 4,
    headings: ["name", "roll no", "standard", "division", "subject"],
  });
  const [record, setRecord] = useState({ id: "", records: { row: [] } });
  const [headings, setHeadings] = useState([]);
  const [pageError, setPageError] = useState(false);
  const [error, setError] = useState("");

  // const clm = () => {
  //   let cal = [];
  //   for (let i = 0; i < gridCount.noOfClm; i++) {
  //     cal.push(<td>kk</td>);
  //   }
  //   return cal;
  // };
  // const rows = () => {
  //   let row = [];
  //   for (let i = 0; i < gridCount.noOfRows; i++) {
  //     row.push(<tr>{clm()}</tr>);
  //   }
  //   return row;
  // };

  const getInitialData = () => {
    let id = "";
    if (filter[0].co === "id") {
      id = filter[0].an;
    }
    axios
      .get(loca + "/marketplace/get/userform/" + id, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then((resp) => {
        let result = resp.data;
        let frecord = record;
        frecord.id=id;
        for (let i = 0; i < result.no_of_rows; i++) {
          frecord.records.row.push({ cols: [] });
        }
        let count = 1;
        for (let i = 0; i < result.no_of_rows; i++) {
          for (let j = 0; j < result.no_of_columns; j++) {
            frecord.records.row[i].cols.push({
              name: result.headings[j],
              value: "",
            });
          }
        }
        setHeadings(result.headings);
        setRecord({ ...frecord });
      });
  };

  useEffect(() => {
    getInitialData();
  }, []);

  const formChangefn = (val, ob, row_ind, col_ind) => {
    setPageError(false);
    setError("");
    let frecord = record;
    if (frecord.records.row[row_ind].cols[col_ind].name === ob) {
      frecord.records.row[row_ind].cols[col_ind].value = val;
    }
    setRecord({ ...frecord });
  };

  const submitfn = () => {
    setPageError(false);
    setError("");
    axios
      .post(loca + "/marketplace/set/userform", record, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then(
        (resp) => {
          let result = resp.data;
          if ("Error" in result) {
            setPageError(true);
            setError(result.Error);
          } else {
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  return (
    <div>
      {pageError && <div className="alert alert-danger mt-3"> {error}</div>}
      <Table>
        <thead>
          <tr>
            {headings.map((obj, index) => (
              <th key={index}>{obj}</th>
            ))}
          </tr>
        </thead>
        <tbody contentEditable suppressContentEditableWarning>
          {record.records.row.length > 0 &&
            record.records.row.map((obj, row_ind) => (
              <tr key={row_ind} style={{ height: 20 }}>
                {obj.cols.map((obj_in, col_ind) => (
                  <td
                    key={col_ind}
                    onChange={(e) => {
                      formChangefn(
                        e.target.value,
                        obj_in.name,
                        row_ind,
                        col_ind
                      );
                    }}
                  >
                    <input
                      style={{ textAlign: "center" }}
                      value={obj_in.value}
                      onChange={(e) => {
                        formChangefn(
                          e.target.value,
                          obj_in.name,
                          row_ind,
                          col_ind
                        );
                      }}
                    ></input>
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </Table>
      <button
        className="btn btn-primary"
        onClick={() => {
          submitfn();
        }}
      >
        Submit
      </button>
    </div>
  );
};

export default ClientInfoCompo;
