import axios from "axios";
import React, { useState, useEffect } from "react";
import { useContext } from "react";
import { Modal } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Card, Col, Container, Input, Row, Table } from "reactstrap";
import { MarketContext } from "../Context";
import ModelList from "./ModelList";

const ClientInfoRecCompo = () => {
  // useContext hook
  const { token, setShowContainer, showContainer, loca, appname } =
    useContext(MarketContext);

  // useLocation hook
  const { rid, tableName } = useLocation().state || null;

  // useNavigation hooks

  const navigation = useNavigate();

  // useState hooks
  const [record, setRecord] = useState({});
  const [formRecord, setFormRecord] = useState([]);
  const [refrecord, setRefRecord] = useState([]);
  const [button, setButton] = useState([]);
  const [mainRecord, setMainRecord] = useState([]);
  const [flag, setFlag] = useState(false);
  const [headings, setHeadings] = useState([]);
  const [pageError, setPageError] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [columnId, setColumnId] = useState();
  const [objName, setObjName] = useState();
  const [objLabel, setObjLabel] = useState();
  const [objIndex, setObjIndex] = useState();
  const [objType, setObjType] = useState();
  const [showModelList, setshowModelList] = useState(false);

  const getInitialData = () => {
    axios
      .get(loca + "/marketplace/get/userform/record/" + rid, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then((resp) => {
        let result = resp.data;
        getInitialFormData();
        setHeadings(result.headings);
        setRecord(result.records);
      });
  };

  const getInitialFormData = () => {
    axios
      .get(loca + "/marketplace/get/singlerecord/" + tableName + "/" + rid,
        {
          headers: {
            authorization: "Bearer " + token,
            "market-application": appname,
          },
        }
      )
      .then(
        (resp) => {
          const mprecord = resp.data;
          if (mprecord !== "") {
            if ("Error" in mprecord) {
              setLoading(false);
              setPageError(true);
              setError(mprecord.Error);
            } else {
              setFormRecord(mprecord.formRecord[2].record);
              setMainRecord(mprecord);
              setButton(mprecord.formRecord[3].button);
              setFlag(true);
              setLoading(false);
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const getRecordForm = (r_id) => {
    axios
      .get(loca + "/marketplace/get/singlerecord/" + tableName + "/" + r_id,
        {
          headers: {
            authorization: "Bearer " + token,
            "market-application": appname,
          },
        }
      )
      .then(
        (resp) => {
          const mprecord = resp.data;
          if (mprecord !== "") {
            if ("Error" in mprecord) {
              setLoading(false);
              setPageError(true);
              setError(mprecord.Error);
            } else {
              setFormRecord(mprecord.formRecord[2].record);
              setMainRecord(mprecord);
              setButton(mprecord.formRecord[3].button);
              setFlag(true);
              setLoading(false);
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };


  const formChangefn = (vl, index, ob, type, id, vrf) => {
    var frecord = formRecord;
    if (type === "reference") {
      if (vrf === false) {
        frecord[index].clicked = false;
      }
      if (vl.length > 2) {
        if (vrf === true) {
          if (frecord[index].name === ob) {
            frecord[index].value.name = vl;
            frecord[index].clicked = true;
            frecord[index].value.id = id;
            // setState({ record: frecord, refrecord: [] });
            setFormRecord([...frecord]);
            setRefRecord({});
            validationfn(vl, index, ob, type, id);
          }
        } else {
          if (frecord[index].name === ob) {
            frecord[index].value.name = vl;
            frecord[index].value.id = id;
            setFormRecord([...frecord]);
          }

          var veri = '{"referenceRecord":[{"columnid":"' + id + '"},';
          veri += '{"tabvalue":"' + vl + '"}]}';
          var rff = [];
          axios
            .post(loca + "/marketplace/reference/record",
              veri.toString(),
              {
                headers: {
                  "Content-Type": "application/json",
                  authorization: "Bearer " + token,
                  "market-application": appname,
                },
              }
            )
            .then(
              (resp) => {
                const refrencercd = resp.data;
                if ("Error" in refrencercd) {
                } else {
                  rff = {
                    index: index,
                    record: refrencercd.referenceRecordList[2].records,
                  };
                  setShowContainer(true);
                  setRefRecord({ ...rff });
                }
              },
              (error) => {
                console.log(error);
              }
            );
        }
      } else {
        if (frecord[index].name === ob) {
          frecord[index].value.name = vl;
          frecord[index].value.id = id;
          setFormRecord([...frecord]);
          setRefRecord({});
        }
      }
    } else {
      if (frecord[index].name === ob) {
        frecord[index].value = vl;
        setFormRecord([...frecord]);
      }
    }
  };

  const fieldverify = (type, vl) => {
    if (type === "String") {
      if (/[a-zA-Z]/g.test(vl)) {
        return "verified";
      } else {
        return "unverified";
      }
    }

    if (type === "email") {
      if (/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(vl)) {
        return "verified";
      } else {
        return "unverified";
      }
    }
    if (type === "int") {
      // const regex = new RegExp(/[0-9]/);
      // console.log("test :" + regex.test(vl));
      if (/^[0-9]*[1-9][0-9]*$/.test(vl)) {
        return "verified";
      } else {

        return "unverified";
      }
    }
    if (type === "datetime") {
      // if (
      //   /^(?:(?:(?:0?[13578]|1[02]|(?:Jan|Mar|May|Jul|Aug|Oct|Dec))(\/|-|\.)31)\1|(?:(?:0?[1,3-9]|1[0-2]|(?:Jan|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec))(\/|-|\.)(?:29|30)\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:(?:0?2|(?:Feb))(\/|-|\.)(?:29)\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:(?:0?[1-9]|(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep))|(?:1[0-2]|(?:Oct|Nov|Dec)))(\/|-|\.)(?:0?[1-9]|1\d|2[0-8])\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/.test(
      //     vl
      //   )
      // ) {
      //   return "verified";
      // } else {
      //   return "unverified";
      // }
      return "verified";
    }
  };

  const validationfn = (vl, index, ob, type, id) => {
    var formrecord = formRecord;
    if (type === "reference") {
      if (vl !== "") {
        var veri = '{"referenceRecord":[{"columnid":"' + id + '"},';
        veri += '{"tabvalue":"' + vl + '"}]}';
        axios
          .post(loca + "/marketplace/reference/verify", veri.toString(), {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + token,
              "market-application": appname,
            },
          })
          .then((resp) => {
            const refrencercd = resp.data;
            // // console.log(refrencercd);
            if (refrencercd.Result === "Unverified") {
              formrecord[index].verified = "unverified";
              // this.setState({ record: formrecord });
              setFormRecord([...formrecord]);
            } else {
              formrecord[index].verified = "verified";
              // this.setState({ record: formrecord, refrecord: [] });
              setFormRecord([...formrecord]);
              setRefRecord([]);
            }

            // this.setState({ record: formrecord });
            setFormRecord([...formrecord]);

            return;
          });
      } else {
        formrecord[index].verified = "initial";
        // this.setState({ record: formrecord });
        setFormRecord([...formrecord]);
      }
    } else {
      if (formrecord[index].name === ob) {
        if (vl !== "") {
          formrecord[index].verified = fieldverify(formrecord[index].type, vl);
        } else {
          formrecord[index].verified = "initial";
        }
      }
      // this.setState({ record: formrecord });

      setFormRecord([...formrecord]);
    }
    // if (type === "boolean") {
    // } else {
    //   // setCheck(!check);
    // }
  };

  const setRefrecord = (vl, index, ob, type, id) => {
    formChangefn(vl, index, ob, type, id, true);
  };

  const clickRefrence = (e, id, name, type, index, label) => {
    e.preventDefault();
    setColumnId(id);
    setObjName(name);
    setObjType(type);
    setObjIndex(index);
    setshowModelList(true);
    setObjLabel(label);
  };

  const setRef = (val, id) => {
    // formChangefn(val, objIndex, objName, objType, id, true);
    cancelModelList();
  };

  const cancelModelList = () => {
    setshowModelList(false);
  };

  const callbtn = (nam) => {
    var btn = button;
    var mnrecord = mainRecord;
    var rcd = formRecord;
    var mandatory = [];
    var unverified = [];
    for (var i = 0; i < rcd.length; i++) {
      if (rcd[i].uivalid.visible === "true") {
        if (rcd[i].uivalid.mandatory === "true") {
          if (rcd[i].value === "") {
            mandatory.push(rcd[i].name);
          }
        }
        if (
          (!(rcd[i].uivalid.mandatory === "false" && rcd[i].value === "") &&
            rcd[i].type === "String") ||
          rcd[i].type === "int" ||
          rcd[i].type === "email" ||
          rcd[i].type === "date"
        ) {
          var veri = fieldverify(rcd[i].type, rcd[i].value);
          if (veri === "unverified") {
            unverified.push(rcd[i].name);
          }
        }
      }
    }

    var btntype = "";
    var btnRtype = "";
    var foundbtn = false;

    for (var ij = 0; ij < btn.length; ij++) {
      if (btn[ij].value === nam) {
        btntype = btn[ij].returnWebLocation;
        btnRtype = btn[ij].recordReturnType;
        foundbtn = true;
        break;
      }
    }
    // if (btntype === "SamePage") {
    if (foundbtn === true && btntype !== "") {
      setPageError(false);
      setError("");
      setLoading(false);
      
      if (mandatory.length === 0 && unverified.length === 0) {
        if (btnRtype === "Grid") {
          navigation("/clientinforecord", {
            state: { rid: rid, tableName: tableName },
          });
        } else {
          if ("appRecord" in mnrecord) {
            mnrecord.appRecord[2].record = rcd;
          } else if ("columnRecord" in mnrecord) {
            mnrecord.columnRecord[2].record = rcd;
          } else if ("formRecord" in mnrecord) {
            mnrecord.formRecord[2].record = rcd;
          } else if ("choiceRecord" in mnrecord) {
            mnrecord.choiceRecord[2].record = rcd;
          } else if ("labelRecord" in mnrecord) {
            mnrecord.labelRecord[2].record = rcd;
          } else if ("buttonRecord" in mnrecord) {
            mnrecord.buttonRecord[2].record = rcd;
          } else if ("tableRecord" in mnrecord) {
            mnrecord.tableRecord[2].record = rcd;
          } else if ("typeRecord" in mnrecord) {
            mnrecord.typeRecord[2].record = rcd;
          } else if ("uivalidationRecord" in mnrecord) {
            mnrecord.uivalidationRecord[2].record = rcd;
          } else if ("languageRecord" in mnrecord) {
            mnrecord.languageRecord[2].record = rcd;
          } else if ("userRecord" in mnrecord) {
            mnrecord.userRecord[2].record = rcd;
          } else {
          }
          axios
            .post(loca + btn[ij].webUrl, mnrecord, {
              headers: {
                "Content-Type": "application/json",
                authorization: "Bearer " + token,
                "market-application": appname,
              },
            })
            .then(
              (resp) => {
                let rcdd = resp.data;
                if (rcdd === "deleted") {
                  let r_id = 0;
                  if ("appRecord" in mnrecord) {
                    r_id = mnrecord.appRecord[2].record[0].value;
                  } else if ("columnRecord" in mnrecord) {
                    r_id = mnrecord.columnRecord[2].record[0].value;
                  } else if ("formRecord" in mnrecord) {
                    r_id = mnrecord.formRecord[2].record[0].value;
                  } else if ("choiceRecord" in mnrecord) {
                    r_id = mnrecord.choiceRecord[2].record[0].value;
                  } else if ("labelRecord" in mnrecord) {
                    r_id = mnrecord.labelRecord[2].record[0].value;
                  } else if ("buttonRecord" in mnrecord) {
                    r_id = mnrecord.buttonRecord[2].record[0].value;
                  } else if ("tableRecord" in mnrecord) {
                    r_id = mnrecord.tableRecord[2].record[0].value;
                  } else if ("typeRecord" in mnrecord) {
                    r_id = mnrecord.typeRecord[2].record[0].value;
                  } else if ("uivalidationRecord" in mnrecord) {
                    r_id = mnrecord.uivalidationRecord[2].record[0].value;
                  } else if ("languageRecord" in mnrecord) {
                    r_id = mnrecord.languageRecord[2].record[0].value;
                  } else if ("userRecord" in mnrecord) {
                    r_id = mnrecord.userRecord[2].record[0].value;
                  } else {
                  }
                  if (btn[ij].returnWebLocation === "NextPage") {
                    navigation("/list", { state: { listName: tableName } });
                  } else {
                    setLoading(true);
                    getRecordForm(r_id);
                  }
                } else if (rcdd === "updated") {
                  let r_id = 0;
                  if ("appRecord" in mnrecord) {
                    r_id = mnrecord.appRecord[2].record[0].value;
                  } else if ("columnRecord" in mnrecord) {
                    r_id = mnrecord.columnRecord[2].record[0].value;
                  } else if ("formRecord" in mnrecord) {
                    r_id = mnrecord.formRecord[2].record[0].value;
                  } else if ("choiceRecord" in mnrecord) {
                    r_id = mnrecord.choiceRecord[2].record[0].value;
                  } else if ("labelRecord" in mnrecord) {
                    r_id = mnrecord.labelRecord[2].record[0].value;
                  } else if ("buttonRecord" in mnrecord) {
                    r_id = mnrecord.buttonRecord[2].record[0].value;
                  } else if ("tableRecord" in mnrecord) {
                    r_id = mnrecord.tableRecord[2].record[0].value;
                  } else if ("typeRecord" in mnrecord) {
                    r_id = mnrecord.typeRecord[2].record[0].value;
                  } else if ("uivalidationRecord" in mnrecord) {
                    r_id = mnrecord.uivalidationRecord[2].record[0].value;
                  } else if ("languageRecord" in mnrecord) {
                    r_id = mnrecord.languageRecord[2].record[0].value;
                  } else if ("userRecord" in mnrecord) {
                    r_id = mnrecord.userRecord[2].record[0].value;
                  } else {
                  }
                  // if (btn[ij].returnWebLocation === "NextPage") {
                  //   navigation("/list", { state: { listName: tableName } });
                  // } else {
                  setLoading(true);
                  getRecordForm(r_id);
                  // }
                } else if ("Error" in rcdd) {
                  setLoading(false);
                  setPageError(false);
                  setError(rcdd.error);
                } else {
                  // this.setState({ loading: false });
                  let r_id = 0;
                  if ("appRecord" in rcdd) {
                    r_id = rcdd.appRecord[1].record[0].value;
                  } else if ("columnRecord" in rcdd) {
                    r_id = rcdd.columnRecord[2].record[0].value;
                  } else if ("formRecord" in rcdd) {
                    r_id = rcdd.formRecord[2].record[0].value;
                  } else if ("choiceRecord" in rcdd) {
                    r_id = rcdd.choiceRecord[2].record[0].value;
                  } else if ("labelRecord" in rcdd) {
                    r_id = rcdd.labelRecord[2].record[0].value;
                  } else if ("buttonRecord" in rcdd) {
                    r_id = rcdd.buttonRecord[2].record[0].value;
                  } else if ("tableRecord" in rcdd) {
                    r_id = rcdd.tableRecord[2].record[0].value;
                  } else if ("typeRecord" in rcdd) {
                    r_id = rcdd.typeRecord[2].record[0].value;
                  } else if ("uivalidationRecord" in rcdd) {
                    r_id = rcdd.uivalidationRecord[2].record[0].value;
                  } else if ("languageRecord" in rcdd) {
                    r_id = rcdd.languageRecord[2].record[0].value;
                  } else if ("userRecord" in rcdd) {
                    r_id = rcdd.userRecord[1].record[0].value;
                  } else {
                  }
                  setLoading(false);

                  if (btn[ij].returnWebLocation === "NextPage") {
                    // navigation("/list", { state: { listName: tableName } });
                  } else {
                    setLoading(true);
                    // getRecordForm(r_id);
                  }
                  // var msg = rcd.formRecord[4].message;
                  // if (msg !== "") {
                  //   // this.setState({ page_message: true, message: msg });
                  //   setPageMessage(true);
                  // }
                  // if (btntype === "NextPage") {
                  //   // var nextP = btn[ij].nextPage;
                  //   var nextP = rcd.formRecord[3].button.nextPage;
                  //   if (nextP === "List") {
                  //     this.callNextPage();
                  //   }
                  // }
                }
              },
              (error) => {
                console.log(error);
              }
            );
        }
      } else {
        var error_String = "";
        if (mandatory.length > 0) {
          error_String += " Check mandatory fields not set: " + mandatory;
        }
        if (unverified.length > 0) {
          error_String += " Fields unverified:" + unverified;
        }

        setLoading(false);
        setPageError(false);
        setError(rcd.error_String);

        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }
      // } else {
      // 	this.setState({
      // 		page_error: true,
      // 		error: error_String,
      // 	});
      // 	document.body.scrollTop = 0;
      // 	document.documentElement.scrollTop = 0;
      // 	// this.props.unmountMe();
      // }
    }
  };

  //   useEffect hook

  useEffect(() => {
    getInitialData();
  }, []);

  return (
    <div>
      {pageError && <div className="alert alert-danger mt-3"> {error}</div>}
      <Table>
        <thead>
          <tr>
            {flag && headings.map((obj, index) => <th key={index}>{obj}</th>)}
          </tr>
        </thead>
        <tbody>
          {flag &&
            record.row.map((obj, row_ind) => (
              <tr key={row_ind}>
                {obj.cols.map((obj_in, col_ind) => (
                  <td key={col_ind}>
                    {obj_in.value}
                     
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </Table>
      <div className="pagesetup formTop">
        <div className=" maincompo ">
        
          {formRecord.length === 0 && <div>Dont have response...</div>}
          {formRecord.length > 0 && (
            <div style={{ textAlign: "start" }}>
              {formRecord.map((obj, index) => (
                <span key={index}>
                  {obj.uivalid.visible === "true" && (
                    <div key={obj.name}>
                      {obj.type === "String" && obj.name === "comment" ? (
                        <div className=" form-group  ">
                          {obj.uivalid.mandatory === "true" &&
                            obj.value !== "" && (
                              <span className="mndtryfals">*</span>
                            )}

                          {obj.uivalid.mandatory === "true" &&
                            obj.value === "" && (
                              <span className="mndtrytru ">*</span>
                            )}
                          <span>{obj.label}</span>
                          {obj.verified === "unverified" && (
                            <div
                              className="alert alert-danger"
                              role="alert"
                              style={{
                                padding: "0.2rem 0.2rem",
                                marginBottom: "0px",
                              }}
                            >
                              Please verify your cherecter not except number!
                            </div>
                          )}
                          <input
                            type="text"
                            className={
                              obj.verified === "unverified"
                                ? "form-control formpadd_danger unverifi"
                                : "form-control formpadd "
                            }
                            value={obj.value}
                            readOnly={obj.uivalid.read_only === "true"}
                            maxLength={obj.uivalid.max_length}
                            onChange={(e) =>
                              formChangefn(
                                e.target.value,
                                index,
                                obj.name,
                                obj.type,
                                obj.id,
                                false
                              )
                            }
                            onMouseOut={(e) =>
                              validationfn(
                                e.target.value,
                                index,
                                obj.name,
                                obj.type,
                                obj.id
                              )
                            }
                          ></input>
                        </div>
                      ) : null}
                      {obj.type === "choice" && obj.name === "state" && (
                        <div className="form-group ">
                          {/* {console.log("checking")} */}
                          {obj.uivalid.mandatory === "true" &&
                            obj.value !== "None" &&
                            obj.value !== "" && (
                              <span className="mndtryfals">*</span>
                            )}

                          {obj.uivalid.mandatory === "true" &&
                            (obj.value === "None" || obj.value === "") && (
                              <span className="mndtrytru ">*</span>
                            )}
                          <span>{obj.label}</span>
                          <select
                            className="form-control form-select formpadd "
                            aria-label="Default select example"
                            value={obj.value}
                            onChange={(e) => {
                              // onSelection(e.target.value);
                              formChangefn(
                                e.target.value,
                                index,
                                obj.name,
                                obj.type,
                                obj.id,
                                false
                              );
                            }}
                            maxLength={obj.uivalid.max_length}
                            readOnly={obj.uivalid.read_only === "true"}
                          >
                            <option value="None">None</option>

                            {obj.choice.map((ch, chi) => (
                              <option key={chi} value={ch.value}>
                                {ch.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                      {obj.type === "reference" &&
                      obj.name === "local_user_id" ? (
                        <div>
                          <div className="form-group">
                            {obj.uivalid.mandatory === "true" &&
                              obj.value !== "" && (
                                <span className="mndtryfals">*</span>
                              )}

                            {obj.uivalid.mandatory === "true" &&
                              obj.value === "" && (
                                <span className="mndtrytru">*</span>
                              )}
                            <span>{obj.label}</span>
                            {obj.verified === "unverified" && (
                              <div
                                className="alert alert-danger"
                                role="alert"
                                style={{
                                  padding: "0.2rem 0.2rem",
                                  marginBottom: "0px",
                                }}
                              >
                                record not found!
                              </div>
                            )}
                            <div style={{ display: "flex" }}>
                              <input
                                type="text"
                                className={
                                  obj.verified === "unverified"
                                    ? "form-control formpadd formbor unverifi"
                                    : "form-control formpadd formbor"
                                }
                                maxLength={obj.uivalid.max_length}
                                defaultValue={obj.value.name}
                                readOnly={true}
                                // onChange={(e) => {
                                //   formChangefn(
                                //     e.target.value,
                                //     index,
                                //     obj.name,
                                //     obj.type,
                                //     obj.id,
                                //     false
                                //   );
                                // }}
                                // onMouseOut={(e) =>
                                //   validationfn(
                                //     e.target.value,
                                //     index,
                                //     obj.name,
                                //     obj.type,
                                //     obj.id
                                //   )
                                // }
                              ></input>
                              <div className="btnsrc vlpointer">
                                <i
                                  className="fa fa-search"
                                  onClick={(e) => {
                                    clickRefrence(
                                      e,
                                      obj.id,
                                      obj.name,
                                      obj.type,
                                      index,
                                      obj.label
                                    );
                                  }}
                                ></i>
                              </div>
                            </div>
                          </div>
                          {refrecord.index === index &&
                            showContainer &&
                            refrecord.record.length > 0 && (
                              <div className="sf_container">
                                {refrecord.record.map((obj_ref, or_i) => (
                                  <div
                                    className="refrcd"
                                    onClick={(e) =>
                                      setRefrecord(
                                        obj_ref.value,
                                        index,
                                        obj.name,
                                        obj.type,
                                        obj_ref.id
                                      )
                                    }
                                    key={or_i}
                                  >
                                    {obj_ref.value}
                                  </div>
                                ))}
                              </div>
                            )}
                        </div>
                      ) : null}
                    </div>
                  )}
                </span>
              ))}
              <div className="btndiv mt-3">
                {button.map((obj, oo_i) => (
                  <div key={oo_i}>
                    {obj.name === "update" && (
                      <button
                        className=" insrtbtn2 btn btn-primary "
                        key={oo_i}
                        onClick={(e) => callbtn(obj.value)}
                      >
                        {obj.name}
                      </button>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal show={showModelList} onHide={cancelModelList} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModelList
            setRef={setRef}
            columnid={columnId}
            onCancel={cancelModelList}
            previousFilter={"null"}
            previousTimeLine={" "}
            tableName={objLabel}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={cancelModelList}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ClientInfoRecCompo;
