import axios from "axios";
import { MarketContext } from "../Context";
import React, { Component, useEffect, useState, useContext,useRef } from "react";
import WorkInProgress from "./WorkInProgress";
import "../css/ownerPreference.css";
import PreferenceComponent from "./PreferenceComponent";

const OwnerPrefComp = () => {
  const { token , loca ,appname} = useContext(MarketContext);
  const [col_mn, setCol_mn] = useState([{}]);
  const [tab_mn, setTab_mn] = useState([{}]);
  const [listName, setListName] = useState("");
  const [tabState, setTabState] = useState(false);
  const [colState, setColState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPref, setShowPref] = useState(false);
  const [page_error, setPage_Error] = useState(false);
  const [error, setError] = useState();
  const firstrender = useRef(false)
  useEffect(() => {
    getTables();

  }, []);

  const getTables = () => {
    setLoading(true);

    axios
      .get(loca+"/marketplace/get/tables", {
        headers: {
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then((resp) => {
        const prefdata = resp.data;
        console.log("adnnnnnnna " +JSON.stringify(prefdata));
        if (prefdata !== "") {
          if ("Error" in prefdata) {
          } else {
            setTabState(true);
            setTab_mn(prefdata.tableRecords);
            setLoading(false);
          }
        }
      });
  };
  const formChangefn = (val) => {
    console.log(val);
    if (val !== " " && val !== "none") {
      setColState(false);
      setListName(val);
      // setCol_mn([{}]);
      axios
        .get(loca+"/marketplace/get/column/" + val, {
          headers: {
            authorization: "Bearer " + token,
            "market-application": appname,
          },
        })
        .then((resp) => {
          const coldata = resp.data;
          console.log("dddddd "+JSON.stringify(coldata));
          if (coldata !== "") {
            if ("Error" in coldata) {
              setLoading(false);
              setPage_Error(true);
              setError(coldata.Error);
            } else {
             
            console.log("ttttttttrrrrrr  : "+JSON.stringify(coldata.columnRecords));
              setCol_mn([...coldata.columnRecords]);    
      setColState(true); 
               
            }
          }
        });
    }
  };

  const handleClose = () => {
  };

  return (
    <div style={{flexGrow:1}} className="">
      {loading === true ? (
        <WorkInProgress />
      ) : (
        <div  className="Card ccccc">
          {tabState === true && (
            <div>
              <select
                className=" owner-select"
                aria-label="Default"
                onChange={(e) => formChangefn(e.target.value)}
              >
                <option value="none">None</option>
                {tab_mn.map((obj, index) => (
                  <option key={index} value={obj.name}>
                    {obj.label}
                  </option>
                ))}
              </select>
              {colState === true && col_mn.length > 0 && (
              
                <div>
                  <PreferenceComponent
                    pref="owner"
                    colarray={col_mn}
                    tablename={listName}
                    handleClose={() => handleClose()}
                  ></PreferenceComponent>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default OwnerPrefComp;
