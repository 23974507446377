import React, { useContext, useEffect, useRef, useState } from "react";
import { MarketContext } from "../Context";
import axios from "axios";
import "../css/MultipleSelectComp.css";
import { Button, Modal } from "react-bootstrap";
import ModelList from "./ModelList";
import { toast } from "react-toastify";

const MultipleSelectComp = ({
  id,
  list,
  setMSC,
  ind,
  record,
  type,
  tabname,
  dep_choice,
}) => {
  console.log(tabname);
  
  const { token, loca, appname } = useContext(MarketContext);
  const [refRcd, setrefRcd] = useState([]);
  const [value, setValue] = useState("");
  const [show, setShow] = useState(false);
  const [selectedList, setSelectedList] = useState(list.record);
  const [record_rq, setRecord_rq] = useState();

  const [ref_filter, setRef_filter] = useState();
  const [show_model_list, setShow_model_list] = useState(false);
  const [showmodel, setShowmodel] = useState(false);
  const [columnid, setColumnid] = useState("");
  const [cur_ref_index, setCur_ref_index] = useState(0);
  const stdItems = useRef([]);
  const firstRender = useRef(false);
  const [mainFilt, setMainFilt] = useState({
    co: "",
    cl: "",
    mc: "",
    an: "",
    ct: "",
    af: "",
    rf: { id: "", value: "" },
    dc: { id: "", value: "" },
    ch: [],
  });

  useEffect(() => {
    let std = document.getElementById("std" + ind);
    let fn = function (e) {
      if (
        std &&
        !document.getElementById("std" + ind).contains(e.target) &&
        std.children.length > 0
      ) {
        for (let i = 0; i < selectedList.length; i++) {
          let classlists = std.children.item(i).classList;
          classlists.remove("selected-p");
        }
      }
    };
    window.addEventListener("click", fn);

    if (firstRender.current) {
      let r = { record: selectedList };
      setMSC(r);
    } else {
      firstRender.current = true;
    }
    checkRefrecord();
    return () => window.removeEventListener("click", fn);
  }, [selectedList]);
  useEffect(() => {
    if (dep_choice?.length > 0) {
      setrefRcd([...dep_choice]);
    }
  }, [dep_choice]);

  const callMsc = async (val, mod_list, r_id) => {
    if (dep_choice.length === 0) {
      var veri = '{"referenceRecord":[{"columnid":"' + id + '"},';
      veri += '{"tabvalue":"' + val + '","colId":"' + r_id + '"}]}';
      await axios
        .post(loca + `/marketplace/reference/record`, veri.toString(), {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + token,
            "market-application": appname,
          },
        })
        .then(
          (resp) => {
            const refrencercd = resp.data;
            if ("Error" in refrencercd) {
            } else {
              var rff = [];
              rff = {
                record: refrencercd.referenceRecordList[2].records,
              };
              if (rff.record.length > 0) {
                if (mod_list) {
                  setrefRcd([...rff.record]);
                  setValue(val);
                  setShow(false);
                } else {
                  setrefRcd([...rff.record]);
                  setValue(val);
                  setShow(true);
                }
              } else {
                setShow(false);
              }
            }
          },
          (error) => {
            console.log(error);
          }
        );
    } else {
      setrefRcd([...dep_choice]);
      setShow(true);
    }
  };
  const changeFn = (val) => {
    setValue(val);
    if (val !== "") {
      callMsc(val, false, 0);
    } else {
      setShow(false);
    }
  };

  const addFn = () => {
    let arr = selectedList;
    let absent = true;
    console.log(selectedList);
    console.log(refRcd);
    console.log(value);

    for (let i = 0; i < refRcd.length; i++) {
      if (refRcd[i].value === value) {
        for (let j = 0; j < arr.length; j++) {
          console.log(arr[j].id, refRcd[i].id);
          if (arr[j].id === refRcd[i].id) {
            absent = false;
            break;
          }
        }
        if (absent) {
          arr.push(refRcd[i]);
          break;
        }
      }
    }
    console.log(absent);

    setSelectedList([...arr]);
    setValue("");
  };

  const removeFn = () => {
    let arr = selectedList;
    let reqItem = document.getElementById("std" + ind).children;
    for (let j = 0, i = 0; i < reqItem.length; i++) {
      if (reqItem.item(i).classList.contains("selected-p")) {
        arr.splice(i - j, 1);
        j++;
      }
      reqItem.item(i).classList.remove("selected-p");
    }
    setSelectedList([...arr]);
  };

  const removeAllFn = () => {
    let arr = [];
    setSelectedList([...arr]);
  };

  const selectStd = (index, type) => {
    if (type === "single") {
      let elem = document.getElementById("std" + ind).children;
      for (let i = 0; i < selectedList.length; i++) {
        let crnt_tag = elem.item(i).classList;
        if (i === index) {
          crnt_tag.add("selected-p");
        } else {
          crnt_tag.remove("selected-p");
        }
      }
    } else {
      for (let i = 0; i < selectedList.length; i++) {
        if (i === index) {
          let reqItem = document.getElementById("std" + ind).children.item(i);
          let present = reqItem.classList.contains("selected-p");
          if (!present) {
            reqItem.classList.add("selected-p");
          } else {
            reqItem.classList.remove("selected-p");
          }
        }
      }
    }
  };

  const setcolumn = (index, col, type) => {
    var rf = record_rq.referenceQualifier;
    var ref_filt = ref_filter;
    ref_filt = "";
    for (var r = 0; r < rf.length; r++) {
      if (rf[r].marketColumn.id === col) {
        var filt = rf[r].filter.filter;
        for (var f = 0; f < filt.length; f++) {
          if (filt[f].mc === "is dependent" || filt[f].ct === "depend_table") {
            var addFilt = "";
            if (f > 0) {
              addFilt = filt[f].af;
            }
            var co_name = filt[f].dc.value;
            var co_label = filt[f].dc.label;
            let ab = getFieldValue(filt[f].rf.id);
            var filt1 = JSON.parse(JSON.stringify(mainFilt));
            filt1.af = addFilt;
            filt1.co = co_name;
            filt1.cl = co_label;
            filt1.ct = "reference";
            filt1.mc = "is";
            filt1.rf.id = ab.id;
            filt1.rf.value = ab.name;

            ref_filt = filt1;
          }
        }
      }
      if (rf[r].marketColumn.id === col && "filter" in rf[r]) {
        var filtt = rf[r].filter.filter;
        for (var d = 0; d < filtt.length; d++) {
          if (filtt[d].ct === "depend_table") {
            var col_name = filtt[d].dc.value;
            let co_label = filt[f].dc.label;
            let ab = getFieldValue(filtt[d].rf.id);
            filtt = filt;
            filtt.co = col_name;
            filtt.co = co_label;
            filtt.ct = type;
            filtt.mc = "=";
            filtt.rf.id = ab.id;
            filtt.rf.value = ab.value;
            ref_filt.push(filtt);
          }
        }
      }
    }
    if (ref_filt.length === 0) {
      ref_filt = JSON.parse(JSON.stringify(mainFilt));
    }
    console.log(col);
    
    setColumnid(col);
    //   cur_ref_name: name,
    //   cur_ref_type: type,
    setCur_ref_index(index);
    setShowmodel(true);
    setRef_filter(ref_filt);
    handleShow();
  };
  const getFieldValue = (col_id) => {
    var rcd = record;
    if (rcd !== "null" && rcd !== "") {
      for (var r = 0; r < rcd.length; r++) {
        if (rcd[r].id === col_id) {
          return rcd[r].value;
        }
      }
    }
  };

  const checkRefrecord = () => {
    axios
      .get(loca + "/marketplace/get/reference/qualifier/" + tabname.name, {
        headers: {
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then(
        (resp) => {
          const rq_data = resp.data;
          if (rq_data !== "") {
            setRecord_rq(rq_data);
          }
        },
        (error) => {
          toast("Something went wrong", {
            position: "top-center",
            theme: "colored",
            type: "error",
          });
        }
      );
  };

  const handleShow = () => {
    setShow_model_list(true);
  };

  const handleClose = () => {
    setShow_model_list(false);
  };
  const setRef = (r_id, val) => {
    console.log(r_id,val);

    callMsc(val, true, r_id);
    handleClose();
  };
  return (
    <div>
      <div className="col-md-3 col-lg-6">
        <div
          id={"std" + ind}
          className="card form-control formpadd formbor ms-std-container"
          disabled
        >
          {selectedList &&
            selectedList.map((item, index) => (
              <span
                key={index}
                className="mx-1"
                onClick={(e) => {
                  if (e.ctrlKey) {
                    selectStd(index, "multiple");
                  } else {
                    selectStd(index, "single");
                  }
                }}
              >
                {item.value}
              </span>
            ))}
        </div>
        <div style={{ display: "flex" }}>
          <input
            type="text"
            className={
              // obj.verified === "unverified"
              //   ? "form-control formpadd formbor unverifi"
              "form-control formpadd formbor"
            }
            value={value}
            onChange={(e) => {
              changeFn(e.target.value);
            }}
          ></input>
          <div
            className="btnsrc vlpointer"
            onClick={(e) => setcolumn(ind, id, type)}
          >
            <i
              className="fa fa-search"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop"
            ></i>
          </div>
        </div>

        {refRcd.length > 0 && show && (
          <div className="std-sf_container">
            {refRcd.map((obj_ref, or_i) => (
              <div
                key={or_i}
                className="refrcd"
                onClick={(e) => {
                  setValue(obj_ref.value);
                  setShow(false);
                }}
              >
                {obj_ref.value}
              </div>
            ))}
          </div>
        )}
      </div>
      <div style={{ flexDirection: "row py-1" }}>
        <button
          className="btn btn-sm  btn-success m-1 ms-0"
          onClick={(e) => {
            e.preventDefault();
            addFn();
          }}
        >
          add
        </button>
        <button
          className="btn btn-sm   btn-secondary m-1"
          onClick={(e) => {
            e.preventDefault();
            removeFn();
          }}
        >
          remove
        </button>
        <button
          className="btn btn-sm   btn-dark m-1"
          onClick={() => {
            removeAllFn();
          }}
        >
          remove All
        </button>
      </div>
      <Modal
        dialogClassName=""
        size="xl"
        show={show_model_list}
        onHide={handleClose}
      >
        <Modal.Header className="py-3" closeButton>
          <Modal.Title>
            <h4 className="mb-0">Personalized List Column</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModelList
            setRef={(r_id, val) => setRef(r_id, val)}
            columnid={columnid}
            onCancel={handleClose}
            loca={loca}
            colBoolean={true}
            tableName={tabname}
            previousFilter={{ filter: [ref_filter] }}
            setshowModelList={setShow_model_list}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MultipleSelectComp;
