import React from "react";
import ReactDOM from 'react-dom/client';
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-toastify/dist/ReactToastify.css';
import MainComponent from "./component/MainComponent";
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <MainComponent/>
);
