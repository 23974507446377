export const ApplicationProperties = {
    appname: "marketplace",
    tokenname: "marketAdminToken",
    loca: process.env.REACT_APP_LOCA,
    imageloca: process.env.REACT_APP_IMAGE_LOCA,
    // imageloca: "https://imgs.mufama.com/",
  };
  
  
  
  