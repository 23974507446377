import React, { useContext, useEffect, useState } from "react";

import "../css/AllNotification.css";
import { MarketContext } from "../Context";
import axios from "axios";

const AllNotification = () => {
  const { token, loca,appname,formHeight } = useContext(MarketContext);
  const [notiData, setNotiData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [sortBy, setSortBy] = useState("id");
  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {
    axios
      .get(loca + "/marketplace/get/Notification", {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then((resp) => {
        let result = resp.data;
        setNotiData(result.notification.messages);
      });
  }, []);

  // Function to sort data based on column and order
  const sortData = (data, column, order) => {
    return data.slice().sort((a, b) => {
      const compare = a[column] > b[column] ? 1 : -1;
      return order === "asc" ? compare : -compare;
    });
  };

  // Function to handle pagination change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Function to handle items per page change
  const handleItemsPerPageChange = (perPage) => {
    setItemsPerPage(perPage);
    setCurrentPage(1); // Reset to first page when changing items per page
  };

  // Function to handle sorting change
  const handleSortChange = (column) => {
    if (sortBy === column) {
      // If already sorting by the same column, toggle the order
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      // If sorting by a different column, set the new column and default to ascending order
      setSortBy(column);
      setSortOrder("asc");
    }
  };

  // Get sorted and paginated data
  const sortedData = sortData(notiData, sortBy, sortOrder);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);
  return (
    <div style={{flexGrow:1, height: formHeight+40,overflowY:"auto" , width: "100%" }}>
      <div className="card">
        <div className="card-header">
          <h2 style={{ textAlign: "center" }}>Notification</h2>
        </div>
        <div className="card-body">
          <table className="table">
            <thead>
              <tr>
                <th style={{display:"contents"}} scope="col" onClick={() => handleSortChange("id")}>
                  Id {sortBy === "id" && (sortOrder === "asc" ? "↑" : "↓")}
                </th>
                <th scope="col" onClick={() => handleSortChange("message")}>
                  Message {sortBy === "message" && (sortOrder === "asc" ? "↑" : "↓")}
                </th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((obj, ind) => (
                <tr key={ind}>
                  <td>{ind + 1 + (currentPage - 1) * itemsPerPage}</td>
                  <td>{obj.message}</td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
          {/* Pagination controls */}

          <div className="card-footer">
            <div style={{display: "-webkit-inline-box"}}>
            <button
            className="btn btn-primary"
              onClick={() => handleItemsPerPageChange(20)}
              disabled={itemsPerPage === 20}
            >
              20 per page
            </button>
            <button
            className="btn btn-primary"
              onClick={() => handleItemsPerPageChange(50)}
              disabled={itemsPerPage === 50}
            >
              50 per page
            </button>
            <div>
              {Array.from({ length: Math.ceil(sortedData.length / itemsPerPage) }).map((_, i) => (
                <button className="btn btn-primary" key={i} onClick={() => handlePageChange(i + 1)} disabled={currentPage === i + 1}>
                  {i + 1}
                </button>
              ))}
            </div>
          </div>
          </div>
      </div>
    </div>
  );
  
};

export default AllNotification;
