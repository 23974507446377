import axios from "axios";
import React, {
  useEffect,
  useState,
  useContext,
  useRef,
} from "react";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { MarketContext } from "../Context";
import "../css/LeftNavigation_Component.css";

const LeftNavigationComponent = () => {
  let height = "100%";
  const {
    token,
    setFlagg,
    flagg,
    setReloadLeft,
    reLoadLeft,
    user,
    loca,
    setLeftNavData,
    leftNavData,
    headHeight,
    callType,
    setCallType,
    appname,
    setFormHeight,
    formIsDirty,
  } = useContext(MarketContext);

  const [records, setRecords] = useState(leftNavData);

  const [searchrcd, setSearchrcd] = useState(leftNavData);

  const [showLeftPane, setshowLeftPane] = useState(true);

  const [leftNav, setLeftNav] = useState(true);

  const navigation = useNavigate();

  const path = useLocation().pathname;

  const onRender = useRef(false);
  const [navOpen, setNavOpen] = useState(true);

  const [error, setError] = useState("");
  const [pageError, setPageError] = useState(false);
  const [cls_all, setCls_all] = useState(true);
  const [contHeight, setContHeight] = useState(0);
  const [searchParam, setSearchParam] = useSearchParams();

  const applicationSet = (index) => {
    navigation("/application");
    setActive(index);
  };

  // useEffect(() => {
  //   loadleftnav();
  // }, []);

  useEffect(() => {
    if (reLoadLeft === true) {
      loadleftnav();
      setReloadLeft(false);
      // navigation("/dashboard");
    }
  }, [reLoadLeft]);

  const loadleftnav = () => {
    axios
      .get(loca + "/marketplace/get/modules", {
        headers: {
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then((res) => {
        const modul = res.data;
        if ("Error" in modul) {
          setPageError(true);
          setError(modul.Error);
        } else {
          var lstt = [];
          for (var k = 0; k < modul.applicatioMenu[2].records.length; k++) {
            for (
              var j = 0;
              j < modul.applicatioMenu[2].records[k].section.length;
              j++
            ) {
              modul.applicatioMenu[2].records[k].section[j].ref = true;
            }
          }
          for (var i = 0; i < modul.applicatioMenu[2].records.length; i++) {
            lstt.push({
              application_menu:
                modul.applicatioMenu[2].records[i].application_menu,

              // module: modul.applicatioMenu[2].records[i].module,
              section: modul.applicatioMenu[2].records[i].section,
              ref: true,
              ref_all: true,
            });
          }

          setRecords(lstt);
          setSearchrcd(lstt);
          setLeftNavData(lstt);
          onRender.current = true;
        }
      });
  };

  const searchModule = (nam) => {
    if (nam.length > 2) {
      var search = Object.create(records);
      var abc = [];
      for (var i = 0; i < search.length; i++) {
        var app_m = search[i].application_menu.toLowerCase();
        if (app_m.includes(nam.toLowerCase())) {
          abc.push({
            application_menu: search[i].application_menu,
            section: search[i].section,
            ref: true,
            ref_all: true,
          });
        } else {
          var cnt = 0;
          var mod = [];

          for (var j = 0; j < search[i].section.length; j++) {
            var sec_m = search[i].section[j].sec_label.toLowerCase();
            if (sec_m.includes(nam.toLowerCase())) {
              mod.push(search[i].section[j]);
              cnt++;
            } else {
              for (var s = 0; s < search[i].section[j].module.length; s++) {
                var mod_m =
                  search[i].section[j].module[s].form_label.toLowerCase();
                if (mod_m.includes(nam.toLowerCase())) {
                  var sec = {};
                  sec = search[i].section[j].module[s];
                  search[i].section[j].module = [];
                  search[i].section[j].module.push(sec);
                  mod.push(search[i].section[j]);
                  cnt++;
                }
              }
            }
          }
          if (cnt > 0) {
            abc.push({
              application_menu: search[i].application_menu,
              section: mod,
              ref: true,
              ref_all: true,
            });
          }
        }
      }
      setSearchrcd([...abc]);
    } else if (nam === "") {
      setSearchrcd([...records]);
    }
  };

  const setOpen = (i, rf) => {
    const rcd = records;
    rcd[i].ref = !rf;
    setRecords([...rcd]);
  };

  const handleLeft = () => {
    var showlfp = showLeftPane;
    showlfp = !showlfp;
    setshowLeftPane(showlfp);
    setFlagg(false);
  };

  const callRecord = (form_table, form_label, rid, type, index) => {
    if (window.screen.width < 600) {
      handleLeft();
    }
    let param = searchParam.get("rty");
    let paramTab = searchParam.get("tableName");
    if (param !== type || paramTab !== form_table) {
      navigation({
        pathname: user + "/form",
        search: createSearchParams({
          rty: type,
          tableName: form_table,
        }).toString(),
      });
    }
    let n = index;
    localStorage.setItem("active", n);
    setActive(n);
    // window.location.reload()
  };
  const callFlowCompo = (form_table, rid, type, index) => {
    navigation("/flow", {
      state: { rty: type, tableName: form_table, rid: rid },
    });
    let n = index;
    // localStorage.setItem("active", n);
    setActive(n);

    // window.location.reload()
  };

  const callInitialSetupCompo = (form_table, rid, type, index) => {
    navigation("/initialsetup", {
      state: { rty: type, tableName: form_table, rid: rid },
    });
    let n = index;
    // localStorage.setItem("active", n);
    setActive(n);

    // window.location.reload()
  };

  const callchecklist = (index) => {
    navigation("/checklicompo");
    setActive(index);
  };

  const callUpdateCompo = (index) => {
    navigation("/updatepassword");
    setActive(index);
  };

  const callAppAccessCompo = (index) => {
    navigation(user + "/appaccess");
    setActive(index);
  };
  const callClienInfo = (index, filter) => {
    navigation("/clientinfo", { state: { filter: filter } });
    setActive(index);
  };

  const callClienInfoRecord = (index, filter) => {
    navigation("/clientinforecord", { state: { filter: filter } });
    setActive(index);
  };

  const callPortalCompo = (index) => {
    navigation("/portal");
    setActive(index);
  };

  const callGridCompo = (index) => {
    navigation("/grid");
    setActive(index);
  };

  const callVariable = (index) => {
    navigation("/var");
    setActive(index);
  };

  const callAppSetup = (index) => {
    navigation("/appsetup");
    setActive(index);
  };

  const callFormView = (index) => {
    navigation("/viewcompo");
    setActive(index);
  };

  const call_uipolicy = (index) => {
    navigation("/policy");
    setActive(index);
  };

  const call_uipolicy_action = (index) => {
    navigation("/policy_action");
    setActive(index);
  };

  const callMultiTable = (tabName, index) => {
    navigation("/multitable", { state: { tableName: tabName } });
    setActive(index);
  };


  const callOther = () => {
    navigation("/report");
  };

  const callMulti = (md) => {
    navigation("/multiinsert", { state: { tableName: md } });
    // showMultiCompo(md);
  };

  const callMultipage = (md) => {
    // showMultiPage(md);
  };

  const callRegister = (md) => {
    // showClientReg(md);
  };

  const callPreference = (index) => {
    // showOwnerPrefComp(md);
    navigation("/OwnerPref");
    setActive(index);
  };

  const callProperties = () => {
    // showPropCompo();
  };

  const callTheme = () => {
    // showThemeCompo();
  };

  const callView = (md) => {
    // showViewCompo(md);
  };

  const setActive = (index) => {
    // localStorage.setItem("active", index);
    // let active = document.getElementsByClassName("navpointer");
    // for (let i = 0; i < active.length; i++) {
    //   if (i === index) {
    //     active[i].classList.add("activeResult");
    //   } else {
    //     active[i].classList.remove("activeResult");
    //   }
    // }
  };

  const call_list = (tb, filter, mod_i, mod_obj) => {
    // setCallType({ callType: mod_obj.type, table: tb });
    if (window.screen.width < 600) {
      handleLeft();
    }
    localStorage.removeItem("pageClicked");

    let paramTab = searchParam.get("tableName");
    if (path !== "/list" || paramTab !== tb) {
      navigation(
        {
          pathname: user + "/list",
          search: createSearchParams({ tableName: tb }).toString(),
        },
        { state: { filter: filter } }
      );
      let n = mod_i;
      // localStorage.setItem("active", n);
      setActive(n);
      // window.location.reload()
    }
    // window.location.reload()
  };

  // const call_list = (form_table, form_label, rid, type, index) => {
  //   if (window.screen.width < 600) {
  //     handleLeft();
  //   }
  //   let param = searchParam.get("rty");
  //   let paramTab = searchParam.get("tableName");
  //   if (param !== type || paramTab !== form_table) {
  //     navigation({
  //       pathname: user + "/list",
  //       search: createSearchParams({
  //         rty: type,
  //         tableName: form_table,
  //       }).toString(),
  //     });
  //   }
  //   let n = index;
  //   localStorage.setItem("active", n);
  //   setActive(n);
  //   // window.location.reload()
  // };

  const closeAppMenu = () => {
    var search = searchrcd;
    for (var s = 0; s < searchrcd.length; s++) {
      search[s].ref = !cls_all;
    }
    // this.setState({ searchrcd: searchrcd, cls_all: !this.state.cls_all });
    setSearchrcd(search);
    setCls_all(!cls_all);
  };

  const setSection = (o_i, sec_i, val) => {
    let aa = 0;
    aa = "gg";
    var shrcd = searchrcd;
    shrcd[o_i].section[sec_i].ref = val;
    // this.setState({ searchrcd: searchrcd });
    setSearchrcd([...shrcd]);
  };

  const closeSection = (o_i) => {
    var sarchrcd = searchrcd;
    for (var j = 0; j < searchrcd[o_i].section.length; j++) {
      sarchrcd[o_i].section[j].ref = !sarchrcd[o_i].ref_all;
    }
    sarchrcd[o_i].ref_all = !sarchrcd[o_i].ref_all;
    // this.setState({
    //   searchrcd: searchrcd,
    // });
    setSearchrcd([...sarchrcd]);
  };
  useEffect(() => {
    let h = window.innerHeight - headHeight - 85;
    setContHeight(h);
    setFormHeight(h);
    if (onRender.current && path !== "/dashboard") {
      let active = localStorage.getItem("active");
      if (active !== null && active !== undefined) {
        setActive(parseInt(active));
      }
    }

    leftNavData.sort((a, b) =>
      parseInt(a.menu_order) > parseInt(b.menu_order)
        ? 1
        : parseInt(a.menu_order) < parseInt(b.menu_order)
        ? -1
        : 0
    );
    setSearchrcd(leftNavData);
  }, [onRender.current, headHeight]);

  // useEffect(() => {
  //   if (onRender.current && path !== "/dashboard") {
  //     let active = localStorage.getItem("active");
  //     if (active !== null && active !== undefined) {
  //       setActive(parseInt(active));
  //     }
  //   }
  // }, [onRender.current]);

  useEffect(() => {
    if (path === "/dashboard") {
      let active = document.getElementsByClassName("navpointer");
      for (let i = 0; i < active.length; i++) {
        active[i].classList.remove("activeResult");
      }
    }
  }, [path]);

  return (
    <div style={{ flexGrow: 1 }} id="mySidebar">
      <div
        className="list-group "
        style={{ height: height, minHeight: height }}
      >
        {/* <div className="pagesetupleft"> */}
        <div>
          {" "}
          {(flagg || window.screen.width < 600) && (
            <div className="search-block ">
              <input
                className="lfnavserach  form-control mr-sm-2 "
                type="search"
                placeholder="Search"
                aria-label="Search"
                onChange={(e) => searchModule(e.target.value)}
              ></input>
              <div className="arr_width">
                <div className={"left_icon "} onClick={handleLeft}>
                  <i className="fa fa-arrow-left"></i>
                </div>
              </div>
              <div className="arr_width">
                <div
                  type="button"
                  className="left_icon "
                  onClick={closeAppMenu}
                >
                  {cls_all ? "-" : "+"}
                </div>
              </div>
            </div>
          )}
        </div>
        <div style={{ height: contHeight, overflow: "auto" }}>
          {searchrcd.map((obj, o_i) => (
            <div key={o_i}>
              <div className="list-group-item appm">
                {obj.application_menu}
                <span className="flri">
                  {obj.ref_all === true && (
                    <i
                      className="fa fa-arrow-down vlpointer arrow_sty"
                      
                      onClick={(e) => closeSection(o_i)}
                    ></i>
                  )}
                  {obj.ref_all === false && (
                    <i
                      className="fa fa-arrow-right vlpointer arrow_sty"
                      
                      onClick={(e) => closeSection(o_i)}
                    ></i>
                  )}
                  <button
                    type="button"
                    className={obj.ref ? "buttn" : "buttnp"}
                    onClick={(pr) => setOpen(o_i, obj.ref)}
                  >
                    {obj.ref ? "-" : "+"}
                  </button>
                </span>
              </div>
              {obj.ref && (
                <div>
                  {obj.section.map((obj_sec, sec_i) => (
                    <div key={sec_i}>
                      <div className="sec">
                        {obj_sec.ref === true && (
                          <i
                            className="fa fa-arrow-down vlpointer cog_pd"
                            
                            onClick={() => setSection(o_i, sec_i, false)}
                          ></i>
                        )}
                        {obj_sec.ref === false && (
                          <i
                            className="fa fa-arrow-right vlpointer cog_pd"
                            
                            onClick={() => setSection(o_i, sec_i, true)}
                          ></i>
                        )}
                        <span>{obj_sec.sec_label}</span>
                      </div>
                      {obj_sec.ref === true && (
                        <div id={obj.application_menu}>
                          {obj_sec.module.map((obj_m, m_i) => (
                            <div key={m_i}>
                              {obj_m.type === "new" && (
                                <div
                                  onClick={() =>
                                    callRecord(
                                      obj_m.form_table,
                                      obj_m.form_label,
                                      "0",
                                      obj_m.type,
                                      m_i
                                    )
                                  }
                                  className="list-group-item list-group-item-action navpointer "
                                >
                                  <p className="val ">{obj_m.form_label}</p>
                                  <span className="mod-set mod-type mod-col3">
                                    IN
                                  </span>
                                </div>
                              )}

                              {obj_m.type === "view" && (
                                <div
                                  onClick={() =>
                                    callFormView(
                                      obj_m.form_table,
                                      "0",
                                      obj_m.type
                                    )
                                  }
                                  className="list-group-item list-group-item-action navpointer"
                                >
                                  {obj_m.form_module}
                                  <span className="mod-set mod-type mod-col3">
                                    IN
                                  </span>
                                </div>
                              )}

                              {obj_m.type === "list" && (
                                <div
                                  onClick={() =>
                                    call_list(
                                      obj_m.form_table,
                                      obj_m.filter,
                                      m_i,
                                      obj_m
                                    )
                                  }
                                  className="list-group-item list-group-item-action navpointer"
                                  style={{ justifyContent: "space-between" }}
                                >
                                  {obj_m.form_label}
                                  <span className="mod-set mod-type mod-col1">
                                    LI
                                  </span>
                                </div>
                              )}
                              {obj_m.type === "flownew" && (
                                <div
                                  onClick={() => {
                                    callFlowCompo(
                                      obj_m.form_table,
                                      "0",
                                      obj_m.type,
                                      m_i
                                    );
                                  }}
                                  className="list-group-item list-group-item-action navpointer"
                                  style={{ justifyContent: "space-between" }}
                                >
                                  {obj_m.form_label}
                                  <span className="mod-set mod-type mod-col12 ">
                                    FN
                                  </span>
                                </div>
                              )}

                              {obj_m.type === "setup" && (
                                <div
                                  onClick={() => {
                                    callInitialSetupCompo(
                                      obj_m.form_module,
                                      "0",
                                      obj_m.type,
                                      m_i
                                    );
                                  }}
                                  className="list-group-item list-group-item-action navpointer"
                                  style={{ justifyContent: "space-between" }}
                                >
                                  {obj_m.form_label}
                                  <span className="mod-set mod-type mod-col12 ">
                                    IS
                                  </span>
                                </div>
                              )}
                              {obj_m.type === "flowlist" && (
                                <div
                                  onClick={() =>
                                    call_list(
                                      obj_m.form_table,
                                      obj_m.filter,
                                      m_i,
                                      obj_m
                                    )
                                  }
                                  className="list-group-item list-group-item-action navpointer"
                                  style={{ justifyContent: "space-between" }}
                                >
                                  {obj_m.form_label}
                                  <span className="mod-set mod-type mod-col13">
                                    FL
                                  </span>
                                </div>
                              )}

                              {obj_m.type === "record" && (
                                <div
                                  onClick={() =>
                                    callRecord(
                                      obj_m.form_table,
                                      obj_m.recordid,
                                      obj_m.type
                                    )
                                  }
                                  className="list-group-item list-group-item-action navpointer"
                                >
                                  {obj_m.form_label}
                                  <span className="mod-set mod-type mod-col2">
                                    RC
                                  </span>
                                </div>
                              )}
                              {obj_m.type === "other" && (
                                <div
                                  onClick={() => {
                                    callOther();
                                    setActive(m_i);
                                  }}
                                  className="list-group-item list-group-item-action navpointer"
                                >
                                  {obj_m.form_label}
                                  <span className="mod-set mod-type mod-col4">
                                    OT
                                  </span>
                                </div>
                              )}
                              {obj_m.type === "multiinsert" && (
                                <div
                                  onClick={() => callMulti(obj_m.form_table)}
                                  className="list-group-item list-group-item-action navpointer"
                                >
                                  {obj_m.form_label}
                                  <span className="mod-set mod-type mod-col5">
                                    MI
                                  </span>
                                </div>
                              )}
                              {obj_m.type === "multipage" && (
                                <div
                                  onClick={() =>
                                    callMultipage(obj_m.form_table)
                                  }
                                  className="list-group-item list-group-item-action navpointer"
                                >
                                  {obj_m.form_label}
                                  <span className="mod-set mod-type mod-col6">
                                    MP
                                  </span>
                                </div>
                              )}
                              {obj_m.type === "multitable" && (
                                <div
                                  onClick={() =>
                                    callMultiTable(obj_m.form_table, m_i)
                                  }
                                  className="list-group-item list-group-item-action navpointer"
                                >
                                  {obj_m.form_label}
                                  <span className="mod-set mod-type mod-col6">
                                    MT
                                  </span>
                                </div>
                              )}
                              {obj_m.type === "register" && (
                                <div
                                  onClick={() => callRegister(obj_m.form_table)}
                                  className="list-group-item list-group-item-action navpointer"
                                >
                                  {obj_m.form_label}
                                  <span className="mod-set mod-type mod-col7">
                                    RG
                                  </span>
                                </div>
                              )}

                              {obj_m.type === "set" && (
                                <div
                                  onClick={() => applicationSet(m_i)}
                                  className="list-group-item list-group-item-action navpointer"
                                >
                                  {obj_m.form_label}
                                  <span className="mod-set mod-type mod-col5">
                                    AS
                                  </span>
                                </div>
                              )}

                              {obj_m.type === "uipolicy" && (
                                <div
                                  onClick={() => call_uipolicy(m_i)}
                                  className="list-group-item list-group-item-action navpointer"
                                >
                                  {obj_m.form_label}
                                  <span className="mod-set mod-type mod-col15">
                                    UIP
                                  </span>
                                </div>
                              )}

                              {obj_m.type === "uipolicy_action" && (
                                <div
                                  onClick={() => call_uipolicy_action(m_i)}
                                  className="list-group-item list-group-item-action navpointer"
                                >
                                  {obj_m.form_label}
                                  <span className="mod-set mod-type mod-col15">
                                    UIPA
                                  </span>
                                </div>
                              )}

                              {obj_m.type === "fview" && (
                                <div
                                  onClick={() => callFormView(m_i)}
                                  className="list-group-item list-group-item-action navpointer"
                                >
                                  {obj_m.form_label}
                                  <span className="mod-set mod-type mod-col6">
                                    FV
                                  </span>
                                </div>
                              )}

                              {obj_m.type === "checklist" && (
                                <div
                                  onClick={() => callchecklist(m_i)}
                                  className="list-group-item list-group-item-action navpointer"
                                >
                                  {obj_m.form_label}
                                  <span className="mod-set mod-type mod-col8">
                                    Cl
                                  </span>
                                </div>
                              )}

                              {obj_m.type === "up" && (
                                <div
                                  onClick={() => callUpdateCompo(m_i)}
                                  className="list-group-item list-group-item-action navpointer"
                                >
                                  {obj_m.form_label}
                                  <span className="mod-set mod-type mod-col7">
                                    Up
                                  </span>
                                </div>
                              )}

                              {obj_m.type === "appAccess" && (
                                <div
                                  onClick={() => callAppAccessCompo(m_i)}
                                  className="list-group-item list-group-item-action navpointer"
                                >
                                  {obj_m.form_label}
                                  <span className="mod-set mod-type mod-col8">
                                    AA
                                  </span>
                                </div>
                              )}

                              {obj_m.type === "preference" && (
                                <div
                                  onClick={() => {
                                    callPreference(obj_m.form_table);
                                    setActive(m_i + 4);
                                  }}
                                  className="list-group-item list-group-item-action navpointer"
                                >
                                  {obj_m.form_label}
                                  <span className="mod-set mod-type mod-col8">
                                    PF
                                  </span>
                                </div>
                              )}
                              {obj_m.type === "properties" && (
                                <div
                                  onClick={callProperties}
                                  className="list-group-item list-group-item-action navpointer"
                                >
                                  {obj_m.form_label}
                                  <span className="mod-set mod-type mod-col9">
                                    PP
                                  </span>
                                </div>
                              )}
                              {obj_m.type === "theme" && (
                                <div
                                  onClick={callTheme}
                                  className="list-group-item list-group-item-action navpointer"
                                >
                                  {obj_m.form_label}
                                  <span className="mod-set mod-type mod-col10">
                                    TE
                                  </span>
                                </div>
                              )}
                              {obj_m.type === "view" && (
                                <div
                                  onClick={() => callView(obj_m.form_table)}
                                  className="list-group-item list-group-item-action navpointer"
                                >
                                  {obj_m.form_label}
                                  <span className="mod-set mod-type mod-col11">
                                    VE
                                  </span>
                                </div>
                              )}
                              {obj_m.type === "clienInfo" && (
                                <div
                                  onClick={() => {
                                    callClienInfo(m_i, obj_m.filter);
                                  }}
                                  className="list-group-item list-group-item-action navpointer"
                                >
                                  {obj_m.form_label}
                                  <span className="mod-set mod-type mod-col11">
                                    CI
                                  </span>
                                </div>
                              )}
                              {obj_m.type === "clienInfoRecord" && (
                                <div
                                  onClick={() => {
                                    callClienInfoRecord(m_i, obj_m.filter);
                                  }}
                                  className="list-group-item list-group-item-action navpointer"
                                >
                                  {obj_m.form_label}
                                  <span className="mod-set mod-type mod-col11">
                                    CR
                                  </span>
                                </div>
                              )}
                              {obj_m.type === "portal" && (
                                <div
                                  onClick={() => callPortalCompo(m_i)}
                                  className="list-group-item list-group-item-action navpointer"
                                >
                                  {obj_m.form_label}
                                  <span className="mod-set mod-type mod-col1">
                                    PP
                                  </span>
                                </div>
                              )}
                              {obj_m.type === "gd" && (
                                <div
                                  onClick={() => callGridCompo(m_i)}
                                  className="list-group-item list-group-item-action navpointer"
                                >
                                  {obj_m.form_label}
                                  <span className="mod-set mod-type mod-col1">
                                    gd
                                  </span>
                                </div>
                              )}
                              {obj_m.type === "var" && (
                                <div
                                  onClick={() => callVariable(m_i)}
                                  className="list-group-item list-group-item-action navpointer"
                                >
                                  {obj_m.form_label}
                                  <span className="mod-set mod-type mod-col1">
                                    vr
                                  </span>
                                </div>
                              )}

                              {obj_m.type === "appsetup" && (
                                <div
                                  onClick={() => callAppSetup(m_i)}
                                  className="list-group-item list-group-item-action navpointer"
                                >
                                  {obj_m.form_label}
                                  <span className="mod-set mod-type mod-col12">
                                    AS
                                  </span>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
        {/* </div> */}
        {/* </div>   */}
      </div>
    </div>
  );
};

export default LeftNavigationComponent;
