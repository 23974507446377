import React from "react";

export default function CheckMandatoryField({ obj }) {
  if (obj.uivalid.mandatory === "true" && obj.value !== "") {
    return <i className="fa fa-asterisk mndtryfalse"></i>;
  }
  if (obj.uivalid.mandatory === "true" && obj.value === "") {
    return <i className="fa fa-asterisk mndtrytrue"></i>;
  }
}
