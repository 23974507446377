import DOMPurify from 'dompurify';
import React, { useState } from 'react'
import parse from 'html-react-parser'; 
import { useLocation } from 'react-router-dom';



const HtmlPage = () => {
  const { html, script } = useLocation().state||"";
  const sanitizedHtmlContent = DOMPurify.sanitize(html);

  return (
    <div style={{overflow:"scroll"}}>
      {parse(sanitizedHtmlContent)}
    </div>
  )
}

export default HtmlPage
