import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import "../css/multi_insert.css";
import { MarketContext } from "../Context";
import ModelList from "./ModelList";
import { useLocation } from "react-router-dom";

const MultiInsertMT = ({
  multi_record,
  callbtn,
  tableObj,
  inserted_record,
  state_mt,
  child_table,
}) => {

  console.log(multi_record);
  // useContext hooks
  const { token ,setShowContainer,showContainer, loca,appname} = useContext(MarketContext);

  // useLocation hook
  const { tableName } = useLocation().state;

  // useState hooks
  const [record, setRecord] = useState([]);
  const [multiRecord, setMultiRecord] = useState(multi_record);
  const [tabObj, setTabObj] = useState(tableObj);
  const [insertedRecord, setinsertedRecord] = useState(inserted_record);
  const [stateMt, setStateMt] = useState(state_mt);
  const [childTable, setChildTable] = useState(child_table);
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState("");
  const [button, setButton] = useState([]);
  const [page_error, setPage_Error] = useState(false);
  const [error, setError] = useState("");
  const [mainrecord, setMainRecord] = useState({});
  const [heading, setHeading] = useState([]);
  const [json, setJson] = useState({});
  const [refrecord, setrefrecord] = useState({});
  const [tabName, setTabName] = useState(tableName);
  const [tabnamenew, setTabNameNew] = useState("");
  const [fieldblank, setFieldBlank] = useState(false);
  const [errorlist, setErrorList] = useState([]);
  const [tablabel, setTabLabel] = useState("");
  const [columnid, setColumnId] = useState("");
  const [cur_ref_name, setCur_ref_Name] = useState("");
  const [cur_ref_type, setCur_ref_Type] = useState("");
  const [cur_ref_index, setCur_ref_Index] = useState(0);
  const [cur_ref_ri, setCur_ref_Ri] = useState("");
  const [show, setShow] = useState(false);
  const [objLabel, setObjLabel] = useState("");
  const [rcdAry, setRcdAry] = useState([]);
  const [relationType, setRelationType] = useState("");
  const [mtRelationList, setMtRelationList] = useState([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
    41, 42, 43, 44, 45, 46, 47, 48, 49, 50,
  ]);
  const [selectVal, setSelectVal] = useState(1);
  const [choiceRecord, setChoiceRecord] = useState([]);

  const getInitialData = () => {
    setLoading(true);
    axios
      .get(loca +"/marketplace/get/multiple/blankrecord/" + tabName, {
        headers: {
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then(
        (res) => {
          const mltrecord = res.data;
          if ("Error" in mltrecord) {
            setLoading(false);
            setPage_Error(true);
            setError(mltrecord.error);
          } else {
            var hd = [];
            var gg = [];
            // var mmm = mltrecord.formRecordList[2].records;
            for (
              var ii = 0;
              ii < mltrecord.formRecordList[2].records.length;
              ii++
            ) {
              var gw = [];
              for (
                var ij = 0;
                ij < mltrecord.formRecordList[2].records[ii].record.length;
                ij++
              ) {
                if (
                  mltrecord.formRecordList[2].records[ii].record[ij]
                    .multiInsertColumn === "true"
                ) {
                  mltrecord.formRecordList[2].records[ii].record[ij].verified =
                    "initial";
                  mltrecord.formRecordList[2].records[ii].record[
                    ij
                  ].blank = false;
                  gw.push(mltrecord.formRecordList[2].records[ii].record[ij]);
                }
              }
              gg.push({ record: gw });
            }
            for (
              var j = 0;
              j < mltrecord.formRecordList[2].records[0].record.length;
              j++
            ) {
              if (
                mltrecord.formRecordList[2].records[0].record[j]
                  .multiInsertColumn === "true"
              ) {
                hd.push(mltrecord.formRecordList[2].records[0].record[j].label);
              }
            }
            var bttt = mltrecord.formRecordList[8].button;
            console.log(JSON.stringify(mltrecord));
            setTabLabel(mltrecord.formRecordList[1].table.label);
            setTabNameNew(tabName);
            setLoading(false);
            setRecord(gg);
            setButton(bttt);
            setJson(mltrecord);
            setHeading(hd);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  //  const componentDidUpdate = () =>  {
  //     if (setTabName !== setTabNameNew) {
  //       var token = localStorage.getItem("token");
  //       // setState({ loading: true, tabname: props.tabName });
  //       axios
  //         .get(
  //          loca +"/marketplace/get/multiple/blankrecord/" +
  //             setTabName,
  //           {
  //             headers: {
  //               authorization: "Bearer " + token,
  //             },
  //           }
  //         )
  //         .then((res) => {
  //           const mltrecord = res.data;
  //           if ("Error" in mltrecord) {

  //             setLoading(false);
  //               setPage_Error(true);
  //               setError(mltrecord.Error);
  //           } else {
  //             var hd = [];
  //             var gg = [];
  //             // var mmm = mltrecord.formRecordList[2].records;
  //             for (
  //               var ii = 0;
  //               ii < mltrecord.formRecordList[2].records.length;
  //               ii++
  //             ) {
  //               var gw = [];
  //               for (
  //                 var ij = 0;
  //                 ij < mltrecord.formRecordList[2].records[ii].record.length;
  //                 ij++
  //               ) {
  //                 if (
  //                   mltrecord.formRecordList[2].records[ii].record[ij]
  //                     .multiInsertColumn === "true"
  //                 ) {
  //                   mltrecord.formRecordList[2].records[ii].record[ij].verified =
  //                     "initial";
  //                   mltrecord.formRecordList[2].records[ii].record[
  //                     ij
  //                   ].blank = false;
  //                   gw.push(mltrecord.formRecordList[2].records[ii].record[ij]);
  //                 }
  //               }
  //               gg.push({ record: gw });
  //             }
  //             for (
  //               var j = 0;
  //               j < mltrecord.formRecordList[2].records[0].record.length;
  //               j++
  //             ) {
  //               if (
  //                 mltrecord.formRecordList[2].records[0].record[j]
  //                   .multiInsertColumn === "true"
  //               ) {
  //                 hd.push(mltrecord.formRecordList[2].records[0].record[j].label);
  //               }
  //             }
  //             var bttt = mltrecord.formRecordList[8].button;

  //               setTab mltrecord.formRecordList[1].table.label,
  //               setTabNameNew(setTabName);
  //               setLoading(false);
  //               setRecord(gg);
  //               setButton(bttt);
  //               setJson (mltrecord);
  //               setHeading(hd);

  //           }
  //         });
  //     }
  //   }

  const formChangefn = (
    vl,
    row_ind,
    col_ind,
    rcd_id,
    ob,
    type,
    vrf,
    tabname,
    ref_id
  ) => {
    console.log("val : "+vl);
    var frecord = record;
    // varjrecord=json;
    if (type === "reference") {
      if (frecord[row_ind].record[col_ind].noref === "false") {
        // frecord[row_ind].record[id].value = vl;
        if (vl === "none") {
          frecord[row_ind].record[col_ind].value.value =
          "None";
          frecord[row_ind].record[col_ind].value.id =
                    "none";
        } else {
          for (var f = 0; f < rcdAry.length; f++) {
            if (rcdAry[f].tableName === tabname) {
              for (var r = 0; r < rcdAry[f].record.length; r++) {
                if (rcdAry[f].record[r].id === vl) {
                  frecord[row_ind].record[col_ind].value.value =
                    rcdAry[f].record[r].value;
                  frecord[row_ind].record[col_ind].value.id =
                    rcdAry[f].record[r].id;
                }
              }
            }
          }
          
        }
        // this.setState({ record: frecord });
        setRecord([...frecord]);
      } else if (frecord[row_ind].record[col_ind].noref === "true") {
        if (vrf === false) {
          frecord[row_ind].record[col_ind].clicked = false;
        }
        if (vl.length > 2) {
          if (vrf === true) {
            if (frecord[row_ind].record[col_ind].name === ob) {
              frecord[row_ind].record[col_ind].value.value = vl;
              frecord[row_ind].record[col_ind].value.id = ref_id;
              frecord[row_ind].record[col_ind].clicked = true;
              var rfrcd = refrecord;
              rfrcd.record = [];
              this.setState({ record: frecord, refrecord: rfrcd });
              setRecord([...frecord]);
              setrefrecord([...rfrcd]);
              validation(vl, row_ind, col_ind, rcd_id, ob, type);
            }
          } else {
            if (frecord[row_ind].record[col_ind].name === ob) {
              frecord[row_ind].record[col_ind].value.value = vl;
              frecord[row_ind].record[col_ind].value.id = ref_id;
              // this.setState({ record: frecord });
              setRecord([...frecord]);
            }
            var veri = '{"referenceRecord":[{"columnid":"' + rcd_id + '"},';
            veri += '{"tabvalue":"' + vl + '"}]}';
            var rff = [];
            axios
              .post(
                loca +"/marketplace/reference/record",
                veri.toString(),
                {
                  headers: {
                    "Content-Type": "application/json",
                    authorization: "Bearer " + token,
                    "market-application": appname,
                  },
                }
              )
              .then((resp) => {
                const refrencercd = resp.data;
                console.log(refrencercd);
                if (refrencercd !== "") {
                  if ("Error" in refrencercd) {
                    // this.setState({
                    //   loading: false,
                    //   page_error: true,
                    //   error: refrencercd.Error,
                    // });
                    setPage_Error(true);
                    setError(refrencercd.Error);
                    setLoading(false);
                  } else {
                    rff = refrencercd.referenceRecordList[2].records;
                  }
                }
                var rf = { index: row_ind, record: rff };
                // this.setState({ refrecord: rf });
                setShowContainer(true)
                setrefrecord([...rf]);
              });
          }
        } else {
          if (frecord[row_ind].record[col_ind].name === ob) {
            frecord[row_ind].record[col_ind].value.value = vl;
            var refrcd = refrecord;
            refrcd.record = [];
            // this.setState({ record: frecord, refrecord: refrcd });
            setRecord([...frecord]);
            setrefrecord([...refrcd]);
          }
        }
      }
    } else {
      frecord[row_ind].record[col_ind].value = vl;
      if (fieldblank === true) {
        for (var i = 0; i < frecord.length; i++) {
          for (var ii = 0; ii < frecord[i].record.length; ii++) {
            frecord[i].record[ii].blank = false;
          }
        }
      }
      // this.setState({ record: frecord, fieldblank: false });
      setRecord([...frecord]);
    
      setFieldBlank(false);
      console.log(JSON.stringify(frecord));
    }
  };

  const validation = (vl, index, i, ob, type) => {
    console.log(vl, index, i, ob, type);
    setErrorList([]);
    var formrecord = record;
    if (type === "reference") {
      if (vl !== "") {
        if (formrecord[index].clicked === true) {
          formrecord[index].verified = "verified";
        } else {
          var veri = '{"referenceRecord":[{"columnid":"' + i + '"},';
          veri += '{"tabvalue":"' + vl + '"}]}';
          axios
            .post(
              loca +"/marketplace/reference/verify",
              veri.toString(),
              {
                headers: {
                  "Content-Type": "application/json",
                  authorization: "Bearer " + token,
                  "market-application": appname,
                },
              }
            )
            .then((resp) => {
              const refrencercd = resp.data;
              if ("Error" in refrencercd) {
                setLoading(false);
                setPage_Error(true);
                setError(refrencercd.Error);
              } else {
                if (refrencercd.Result === "Unverified") {
                  formrecord[index].verified = "unverified";
                  setRecord(formrecord);
                } else {
                  formrecord[index].verified = "verified";
                  var rfrcd = setrefrecord;
                  rfrcd.record = [];
                  setRecord(formrecord);
                  setrefrecord(rfrcd);
                }
                setRecord(formrecord);
                return;
              }
            });
        }
      } else {
        formrecord[index].verified = "initial";
        setRecord(formrecord);
      }
    } else {
      var err = page_error;
      var nam = formrecord[index].record[i].name;
      var errorList = errorlist;
      if (nam === ob) {
        var lab = formrecord[index].record[i].label;
        if (vl !== "") {
          var vari = fieldverify(formrecord[index].record[i].type, vl);
          if (vari === "verified" && errorList.length === 0) {
            err = false;
          } else if (vari === "verified" && errorList.length > 0) {
            if (errorList.length === 1) {
              if (errorList[0].name === nam) {
                if (errorList[0].count.length === 1) {
                  if (
                    errorList[0].count[0].index === index &&
                    errorList[0].count[0].i === i
                  ) {
                    errorList.pop();
                    err = false;
                  }
                } else if (errorList[0].count.length > 1) {
                  for (var bb = 0; bb < errorList[0].count.length; bb++) {
                    if (
                      errorList[0].count[bb].index === index &&
                      errorList[0].count[bb].i === i
                    ) {
                      errorList[0].count.splice(bb, 1);
                      err = true;
                    }
                  }
                }
              }
            } else if (errorList.length > 1) {
              for (var cc = 0; cc < errorList.length; cc++) {
                if (errorList[cc].name === nam) {
                  if (errorList[cc].count.length === 1) {
                    if (
                      errorList[cc].count[0].index === index &&
                      errorList[cc].count[0].i === i
                    ) {
                      errorList.splice(cc, 1);
                      err = true;
                    }
                  } else if (errorList[cc].count.length > 1) {
                    for (var cb = 0; cb < errorList[cc].count.length; cb++) {
                      if (
                        errorList[cc].count[cb].index === index &&
                        errorList[cc].count[cb].i === i
                      ) {
                        errorList[cc].count.splice(cb, 1);
                        err = true;
                      }
                    }
                  }
                }
              }
            }
          } else if (vari === "unverified" && errorList.length === 0) {
            if (!errorList.includes(nam)) {
              errorList.push({
                name: nam,
                label: lab,
                count: [{ index: index, i: i }],
              });
            }
            err = true;
          } else if (vari === "unverified" && errorList.length > 0) {
            if (errorList.length === 1) {
              if (errorList[0].name === nam) {
                if (errorList[0].count.length === 1) {
                  if (
                    errorList[0].count[0].index === index &&
                    errorList[0].count[0].i === i
                  ) {
                    err = true;
                  } else if (
                    errorList[0].count[0].index !== index ||
                    errorList[0].count[0].i !== i
                  ) {
                    errorList[0].count.push({ index: index, i: i });
                    err = true;
                  }
                } else if (errorList[0].count.length > 1) {
                  var incount = 0;
                  for (var ii = 0; ii < errorList[0].count.length; ii++) {
                    if (
                      errorList[0].count[ii].index === index &&
                      errorList[0].count[ii].i === i
                    ) {
                      err = true;
                    } else if (
                      errorList[0].count[ii].index !== index &&
                      errorList[0].count[ii].i !== i
                    ) {
                      incount++;
                    }
                  }
                  if (incount === errorList[0].count.length) {
                    errorList[0].count.push({ index: index, i: i });
                    err = true;
                  }
                }
              } else {
                errorList.push({
                  name: nam,
                  label: lab,
                  count: [{ index: index, i: i }],
                });
                err = true;
              }
            } else if (errorList.length > 1) {
              var errcount = 0;
              for (var kk = 0; kk < errorList.length; kk++) {
                if (errorList[kk].name === nam) {
                  if (errorList[kk].count.length === 1) {
                    if (
                      errorList[kk].count[0].index === index &&
                      errorList[kk].count[0].i === i
                    ) {
                      err = true;
                    } else if (
                      errorList[kk].count[0].index !== index ||
                      errorList[kk].count[0].i !== i
                    ) {
                      errorList[kk].count.push({ index: index, i: i });
                      err = true;
                    }
                  } else if (errorList[kk].count.length > 1) {
                    var incount2 = 0;
                    for (var iid = 0; iid < errorList[kk].count.length; iid++) {
                      if (
                        errorList[kk].count[iid].index === index &&
                        errorList[kk].count[iid].i === i
                      ) {
                        err = true;
                      } else if (
                        errorList[kk].count[iid].index !== index ||
                        errorList[kk].count[iid].i !== i
                      ) {
                        incount2++;
                      }
                    }
                    if (incount2 === errorList[kk].count.length) {
                      errorList[kk].count.push({ index: index, i: i });
                      err = true;
                    }
                  }
                } else if (errorList[kk].name !== nam) {
                  errcount++;
                }
              }
              if (errcount === errorList.length) {
                errorList.push({
                  name: nam,
                  label: lab,
                  count: [{ index: index, i: i }],
                });
                err = true;
              }
            }
          }
          formrecord[index].record[i].verified = vari;
        } else {
          if (errorList.length === 1) {
            if (errorList[0].name === nam) {
              if (errorList[0].count.length === 1) {
                if (
                  errorList[0].count[0].index === index &&
                  errorList[0].count[0].i === i
                ) {
                  errorList.pop();
                  err = false;
                }
              } else if (errorList[0].count.length > 1) {
                for (var bbd = 0; bbd < errorList[0].count.length; bbd++) {
                  if (
                    errorList[0].count[bbd].index === index &&
                    errorList[0].count[bbd].i === i
                  ) {
                    errorList[0].count.splice(bbd, 1);
                    err = true;
                  }
                }
              }
            }
          } else if (errorList.length > 1) {
            for (var ccd = 0; ccd < errorList.length; ccd++) {
              if (errorList[ccd].name === nam) {
                if (errorList[ccd].count.length === 1) {
                  if (
                    errorList[ccd].count[0].index === index &&
                    errorList[ccd].count[0].i === i
                  ) {
                    errorList.splice(ccd, 1);
                  }
                } else if (errorList[ccd].count.length > 1) {
                  for (var cf = 0; cf < errorList[ccd].count.length; cf++) {
                    if (
                      errorList[ccd].count[cf].index === index &&
                      errorList[ccd].count[cf].i === i
                    ) {
                      errorList[ccd].count.splice(cf, 1);
                      err = true;
                    }
                  }
                }
              }
            }
          }
          formrecord[index].record[i].verified = "initial";
        }
      }
      setRecord(formrecord);
      setPage_Error(err);
      setErrorList(errorList);
    }
  };

  const setRefrecord = (vl, row_ind, col_ind, ob, type, id, ref_id) => {
    console.log(vl, row_ind, col_ind, ob, type, id, ref_id);
    formChangefn(vl, row_ind, col_ind, id, ob, type, true, ref_id);
  };

  const selectNumberOfRecords = (val) => {
    setSelectVal(val)
    console.log(
      "m  : " + JSON.stringify(multi_record.formRecordList[2].records)
    );
    let temp = JSON.parse(JSON.stringify(multi_record));
    var multi_rcd = temp;
    var temp2 = JSON.parse(
      JSON.stringify(multi_rcd.formRecordList[2].records[0])
    );
    var rrr = temp2;
    for (var m = 1; m < val; m++) {
      multi_rcd.formRecordList[2].records.push(rrr);
    }
    console.log(JSON.stringify(multi_rcd.formRecordList[2].records))
    setMI(multi_rcd);
    // this.setState({
    //   multi_record: multi_rcd,
    // });
  };

  const setcolumn = (index, r_i, name, type, col) => {
    console.log(index, r_i, name, type, col);

    setColumnId(col);
    setCur_ref_Name(name);
    setCur_ref_Type(type);
    setCur_ref_Index(index);
    setCur_ref_Ri(r_i);
    //   showmodel(true);

    handleShow();
  };

  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  const fieldverify = (type, vl) => {
    if (type === "String") {
      if (/[a-zA-Z]/g.test(vl)) {
        return "verified";
      } else {
        return "unverified";
      }
    }

    if (type === "email") {
      if (/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(vl)) {
        return "verified";
      } else {
        return "unverified";
      }
    }
    if (type === "int") {
      if (/^[0-9]*[0-9][0-9]*$/.test(vl)) {
        return "verified";
      } else {
        return "unverified";
      }
    }
    if (type === "date") {
      // if (
      //   /^(?:(?:(?:0?[13578]|1[02]|(?:Jan|Mar|May|Jul|Aug|Oct|Dec))(\/|-|\.)31)\1|(?:(?:0?[1,3-9]|1[0-2]|(?:Jan|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec))(\/|-|\.)(?:29|30)\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:(?:0?2|(?:Feb))(\/|-|\.)(?:29)\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:(?:0?[1-9]|(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep))|(?:1[0-2]|(?:Oct|Nov|Dec)))(\/|-|\.)(?:0?[1-9]|1\d|2[0-8])\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/.test(
      //     vl
      //   )
      // ) {
      //   return "verified";
      // } else {
      //   return "unverified";
      // }
      return "verified";
    }
    if (type === "reference") {
      if (/[a-zA-Z]/g.test(vl)) {
        return "verified";
      } else {
        return "unverified";
      }
    }
  };

  // onChangefn(vl, index, i, ob) {
  // 	let frecord = record;
  // 	frecord[index].record[i].value = vl;

  // 	setState({ record: frecord });
  // }

  //   const callbtn = (nam) => {
  //     var btn = button;
  //     var jso = json;
  //     var mnrecord = jso.formRecordList[2].records;
  //     var rcd = record;
  //     var blankcount = 0;
  //     for (var i = 0; i < rcd.length; i++) {
  //       for (var k = 0; k < rcd[i].record.length; k++) {
  //         validation(
  //           rcd[i].record[k].value,
  //           i,
  //           k,
  //           rcd[i].record[k].name,
  //           rcd[i].record[k].type
  //         );
  //         if (rcd[i].record[k].value === "") {
  //           rcd[i].record[k].blank = true;
  //           blankcount++;
  //         }
  //       }
  //     }

  //     if (blankcount === 0) {
  //       if (setErrorList.length === 0) {
  //         for (var kk = 0; kk < mnrecord.length; kk++) {
  //           for (var r = 0; r < mnrecord[kk].record.length; r++) {
  //             for (var m = 0; m < rcd[kk].record.length; m++) {
  //               if (mnrecord[kk].record[r].name === rcd[kk].record[m].name) {
  //                 mnrecord[kk].record[r].value = rcd[kk].record[m].value;
  //               }
  //             }
  //           }
  //         }
  //         jso.formRecordList[2].records = mnrecord;
  //       }
  //     } else {
  //       setRecord(rcd);
  //       setFieldBlank(true);
  //     }

  //     if (blankcount === 0) {
  //       for (var ij = 0; ij < btn.length; ij++) {
  //         console.log(nam + " btn : " + JSON.stringify(btn[ij]));
  //         if (btn[ij].name === nam) {
  //           // var btntype = btn[ij].buttonWebType;
  //           // if (btntype === "NextPage") {
  //           // mnrecord.formRecord[2].record = record;
  //           console.log("checking");
  //           axios
  //             .post(loca + btn[ij].webUrl, jso, {
  //               headers: {
  //                 "Content-Type": "application/jso",
  //                 authorization: "Bearer " + token,
  //               },
  //             })
  //             .then(
  //               (resp) => {
  //                 const refrencercd = resp.data;
  //                 console.log(refrencercd);
  //                 // return;
  //               },
  //               (error) => {
  //                 console.log(error);
  //               }
  //             );
  //           // } else {
  //           // }
  //         }
  //       }
  //     }
  //   };
  const copyparent = (index, ii) => {
    let frecord = record;
    var vall = frecord[index - 1].record[ii].value;
    if (vall !== "") {
      let temp=JSON.parse(JSON.stringify(vall))
      frecord[index].record[ii].value = temp;
    }
    setRecord([...frecord]);
  };

  const copyAll = (ij) => {
    let frecord = record;
    var val = "";
    // var mnrecord = mainrecord;
    var mandatory = [];
    var unverified = [];
    for (var i = 0; i < frecord.length; i++) {
      if (frecord[i].value === "") {
        mandatory.push(frecord[i].name);
      }
      if (frecord[i].type === "String" || frecord[i].type === "int") {
        var veri = fieldverify(frecord[i].type, frecord[i].value);
        if (veri === "unverified") {
          unverified.push(frecord[i].name);
        }
      }
    }
    for (var ii = 0; ii < frecord.length; ii++) {
      if (ii === 0) {
        val = JSON.parse(JSON.stringify(frecord[ii].record[ij].value));
      }
      if (val !== "") {
        if (ii > 0) {
          let temp=JSON.parse(JSON.stringify(val));
          frecord[ii].record[ij].value = temp;
        }
      }
    }
    setRecord([...frecord]);
  };

  const setRef = (val, ref_id) => {
    console.log("val: " + val + " " + ref_id);
    setRefrecord(
      val,
      cur_ref_index,
      cur_ref_ri,
      cur_ref_name,
      cur_ref_type,
      columnid,
      ref_id
    );
    handleClose();
  };

  const setMI = (mltrecord) => {
    if (mltrecord !== "") {
      if ("Error" in mltrecord) {
        // this.setState({
        //   loading: false,
        //   page_error: true,
        //   error: mltrecord.Error,
        // });
        setPage_Error(true);
        setError(mltrecord.Error);
        setLoading(false);
      } else {
        var rcd_ary = rcdAry;
        var hd = [];
        var gg = [];
        var chc_rcd = [];
        var cont = 0;
        // var mmm = mltrecord.formRecordList[2].records;
        for (
          var ii = 0;
          ii < mltrecord.formRecordList[2].records.length;
          ii++
        ) {
          var gw = [];
          for (
            var ij = 0;
            ij < mltrecord.formRecordList[2].records[ii].record.length;
            ij++
          ) {
            if (
              mltrecord.formRecordList[2].records[ii].record[ij]
                .multiInsertColumn === "true" ||
              mltrecord.formRecordList[2].records[ii].record[ij]
                .displayColumn === "true"
            ) {
              if (
                mltrecord.formRecordList[2].records[ii].record[ij].type ===
                "reference"
              ) {
                var count = 0;
                for (var p = 0; p < tabObj.length; p++) {
                  if (
                    tabObj[p].value ===
                    mltrecord.formRecordList[2].records[ii].record[ij].refTable
                      .value
                  ) {
                    count++;
                    var cnt = 0;
                    for (var t = 0; t < rcd_ary.length; t++) {
                      if (rcd_ary[t].tableName === tabObj[p].value) {
                        cnt++;
                      }
                    }
                    if (cnt === 0) {
                      for (var i = 0; i < insertedRecord.length; i++) {
                        var inst = null;
                        if ("formRecordList" in insertedRecord[i]) {
                          inst = insertedRecord[i].formRecordList;
                          if (inst !== null) {
                            if (inst[1].table.name === tabObj[p].value) {
                              var mrcd = inst[2].records;
                              var dis_rcd = [];
                              for (var mi = 0; mi < mrcd.length; mi++) {
                                var frl_id = "";
                                var frl_name = "";
                                var m_rcd = mrcd[mi].record;
                                for (var fr = 0; fr < m_rcd.length; fr++) {
                                  if (m_rcd[fr].name === "id") {
                                    frl_id = m_rcd[fr].value;
                                  }
                                  if (m_rcd[fr].displayColumn === "true") {
                                    frl_name = m_rcd[fr].value;
                                  }
                                }
                              }
                              dis_rcd.push(
                                '{"id":"' +
                                  frl_id +
                                  '","value":"' +
                                  frl_name +
                                  '"}'
                              );
                              var frl_tab = JSON.parse(
                                '{"tableName":"' +
                                  tabObj[p].value +
                                  '","record":[' +
                                  dis_rcd +
                                  "]}"
                              );
                              rcd_ary.push(frl_tab);
                            }
                          }
                        } else if ("formRecord" in insertedRecord[i]) {
                          inst = insertedRecord[i].formRecord;
                          if (inst !== null) {
                            if (inst[1].table.name === tabObj[p].value) {
                              var rcd = inst[2].record;
                              var dis = [];
                              if (rcd.length > 0) {
                                var id = "";
                                var name = "";
                                for (var r = 0; r < rcd.length; r++) {
                                  if (rcd[r].name === "id") {
                                    id = rcd[r].value;
                                  }
                                  if (rcd[r].displayColumn === "true") {
                                    console.log(rcd[r].value);
                                    name = rcd[r].value;
                                  }
                                }
                                dis.push(
                                  '{"id":"' + id + '","value":"' + name + '"}'
                                );
                                var tab_tab = JSON.parse(
                                  '{"tableName":"' +
                                    tabObj[p].value +
                                    '","record":[' +
                                    dis +
                                    "]}"
                                );
                                rcd_ary.push(tab_tab);
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              if (count > 0) {
                mltrecord.formRecordList[2].records[ii].record[ij].noref =
                  "false";
              } else {
                mltrecord.formRecordList[2].records[ii].record[ij].noref =
                  "true";
              }
              mltrecord.formRecordList[2].records[ii].record[ij].verified =
                "initial";
              mltrecord.formRecordList[2].records[ii].record[ij].blank = false;
              gw.push(mltrecord.formRecordList[2].records[ii].record[ij]);
            }
          }
          gg.push({ record: gw });
        }
        for (
          var j = 0;
          j < mltrecord.formRecordList[2].records[0].record.length;
          j++
        ) {
          if (
            mltrecord.formRecordList[2].records[0].record[j]
              .multiInsertColumn === "true" ||
            mltrecord.formRecordList[2].records[0].record[j].displayColumn ===
              "true"
          ) {
            hd.push(mltrecord.formRecordList[2].records[0].record[j].label);
            if (
              mltrecord.formRecordList[2].records[0].record[j].type === "choice"
            ) {
              chc_rcd.push({
                position: cont,
                choice: mltrecord.formRecordList[2].records[0].record[j].choice,
              });
            }
            cont++;
          }
        }
        var bttt = mltrecord.formRecordList[8].button;

        // this.setState({
        //   tablabel: mltrecord.formRecordList[1].table.label,
        //   tabname: mltrecord.formRecordList[1].table.label,
        //   loading: false,
        //   record: gg,
        //   button: bttt,
        //   json: mltrecord,
        //   heading: hd,
        //   rcd_ary: rcd_ary,
        //   relationType: mltrecord.formRecordList[9].multiPage.mtr.type,
        // });
        console.log(JSON.stringify(gg));
        setTabLabel(mltrecord.formRecordList[1].table.label);
        setTabName(mltrecord.formRecordList[1].table.label);
        setRecord([...gg]);
        setButton(bttt);
        setJson(mltrecord);
        setChoiceRecord(chc_rcd);
        setHeading(hd);
        setRcdAry([...rcd_ary]);
        setRelationType(mltrecord.formRecordList[9].multiPage.mtr.type);
      }
    }
  };

  useEffect(() => {
    setMI(multi_record);
  }, []);

  return (
    <div>
      {record.length === 0 && <div>Don't have response...</div>}
      {page_error === true ? (
        <div
          className="alert alert-danger"
          role="alert"
          style={{
            padding: "0.2rem 0.2rem",
            marginBottom: "0px",
          }}
        >
          Please check these fields:
          {errorlist.map((er, ii) => (
            <span key={ii}>
              {ii > 0 ? ", " : ""}
              {er.label}
            </span>
          ))}
        </div>
      ) : null}
      {fieldblank === true ? (
        <div
          className="alert alert-danger"
          role="alert"
          style={{
            padding: "0.2rem 0.2rem",
            marginBottom: "0px",
          }}
        >
          Please fill fields mark in red
        </div>
      ) : null}
      {relationType === "user" && (
        <div>
          <div className="row">
            <div className="col-md-2">Select Number Of Records :</div>
            <div className="col-md-2">
              <select
                className="form-select evtinputlis"
                aria-label="Default"
                value={selectVal}

                onChange={(e) => selectNumberOfRecords(e.target.value)}
              >
                {mtRelationList.map((obj, index) => (
                  <option key={index} value={obj}>
                    {obj}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      )}
      <table className=" obj_tab table table-bordered table-striped table-hover">
        <thead className="objthead">
          <tr>
            {heading.map((objj, st_i) => (
              <th className="objname" key={st_i}>
                <div className="">
                  <span>{objj}</span>
                </div>
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {record.map((obj, row_ind) => (
            <tr key={row_ind} style={{ justifyContent: "center" }}>
              {obj.record.map((rcd, col_ind) => (
                <td key={col_ind}>
                  <span className="lom_td">
                    {obj.verified === "unverified" && (
                      <span
                        className="alert alert-danger"
                        role="alert"
                        style={{
                          padding: "0.2rem 0.2rem",
                          marginBottom: "0px",
                        }}
                      >
                        Please verify field!
                      </span>
                    )}
                    {rcd.type === "String" ? (
                      <input
                        type="text"
                        className={
                          rcd.verified === "unverified" || rcd.blank === true
                            ? "lom_form_control formpadd unverifi"
                            : "lom_form_control "
                        }
                        // className="lom_form_control formpadd"
                        value={rcd.value}
                        onChange={(e) =>
                          formChangefn(
                            e.target.value,
                            row_ind,
                            col_ind,
                            rcd.id,
                            rcd.name,
                            rcd.type,
                            false
                          )
                        }
                        onBlur={(e) =>
                          validation(
                            e.target.value,
                            row_ind,
                            col_ind,
                            rcd.name,
                            rcd.type
                          )
                        }
                      ></input>
                    ) : null}
                    {rcd.type === "int" ? (
                      <input
                        type="text"
                        className={
                          rcd.verified === "unverified" || rcd.blank === true
                            ? "lom_form_control formpadd unverifi"
                            : "lom_form_control "
                        }
                        // className="lom_form_control formpadd"
                        value={rcd.value}
                        onChange={(e) =>
                          formChangefn(
                            e.target.value,
                            row_ind,
                            col_ind,
                            rcd.id,
                            rcd.name,
                            rcd.type,
                            false
                          )
                        }
                        onBlur={(e) =>
                          validation(
                            e.target.value,
                            row_ind,
                            col_ind,
                            rcd.name,
                            rcd.type
                          )
                        }
                      ></input>
                    ) : null}
                    {rcd.type === "datetime" ? (
                      <input
                        type="date"
                        className={
                          rcd.verified === "unverified" || rcd.blank === true
                            ? "lom_form_control formpadd unverifi"
                            : "lom_form_control "
                        }
                        // className="lom_form_control formpadd"
                        value={rcd.value}
                        onChange={(e) =>
                          formChangefn(
                            e.target.value,
                            row_ind,
                            col_ind,
                            rcd.id,
                            rcd.name,
                            rcd.type,
                            false
                          )
                        }
                        onBlur={(e) =>
                          validation(
                            e.target.value,
                            row_ind,
                            col_ind,
                            rcd.name,
                            rcd.type
                          )
                        }
                      ></input>
                    ) : null}
                    {rcd.type === "choice" ? (
                      <div>
                        {console.log(choiceRecord)}
                        {choiceRecord.map((ch, chi) => (
                          <div key={chi}>
                            {/* {ch.position === col_ind && ( */}
                              <select
                                className="form-control form-select formpadd "
                                aria-label="Default select example"
                                onChange={(e) =>
                                  formChangefn(
                                    e.target.value,
                                    row_ind,
                                    col_ind,
                                    rcd.id,
                                    rcd.name,
                                    rcd.type,
                                    false
                                  )
                                }
                                value={rcd.value}
                                onBlur={(e) =>
                                  validation(
                                    e.target.value,
                                    row_ind,
                                    col_ind,
                                    rcd.id,
                                    rcd.name,
                                    rcd.type
                                  )
                                }
                              >
                                <option value="None">None</option>
                                {ch.choice.map((ch_c, chi_c) => (
                                  <option key={chi_c} value={ch_c.value}>
                                    {ch_c.name}
                                  </option>
                                ))}
                              </select>
                            {/* )} */}
                          </div>
                        ))}
                      </div>
                    ) : null}
                    {rcd.type === "date" ? (
                      <input
                        type="date"
                        className={
                          rcd.verified === "unverified" || rcd.blank === true
                            ? "lom_form_control formpadd unverifi"
                            : "lom_form_control "
                        }
                        // className="lom_form_control formpadd"
                        value={rcd.value}
                        onChange={(e) =>
                          formChangefn(
                            e.target.value,
                            row_ind,
                            col_ind,
                            rcd.id,
                            rcd.name,
                            rcd.type,
                            false
                          )
                        }
                        onBlur={(e) =>
                          validation(
                            e.target.value,
                            row_ind,
                            col_ind,
                            rcd.name,
                            rcd.type
                          )
                        }
                      ></input>
                    ) : null}
                    {rcd.type === "boolean" ? (
                      <input
                        type="checkbox"
                        // className={
                        //   // rcd.verified === "unverified" || rcd.blank === true
                        //   //   ? "lom_form_control formpadd unverifi"
                        //   //   : "lom_form_control "
                        // }
                        // className="lom_form_control formpadd"
                        // value={obj.value === "true" ? true : false}
                        checked={rcd.value === "true" ? true : false}
                        onChange={(e) => {
                          console.log("e : " + e.target.checked.toString());
                          formChangefn(
                            e.target.checked.toString(),
                            row_ind,
                            col_ind,
                            rcd.id,
                            rcd.name,
                            rcd.type,
                            false
                          );
                        }}
                        onBlur={(e) =>
                          validation(
                            e.target.value,
                            row_ind,
                            col_ind,
                            rcd.name,
                            rcd.type
                          )
                        }
                      ></input>
                    ) : null}
                    {rcd.type === "reference" ? (
                      <div>
                        {rcd.noref === "false" && (
                          <div>
                            {console.log("rcdAry : " + JSON.stringify(rcdAry))}
                            {rcdAry.map((reftab, indx) => (
                              <div key={indx}>
                                {reftab.tableName === rcd.refTable.value ? (
                                  <div>
                                    <select
                                      className="form-select"
                                      value={rcd.value.id}
                                      // selected={rcd.value.id}
                                      onChange={(e) =>
                                        formChangefn(
                                          e.target.value,
                                          row_ind,
                                          col_ind,
                                          rcd.id,
                                          rcd.name,
                                          rcd.type,
                                          false,
                                          reftab.tableName
                                        )
                                      }
                                    >
                                      <option value="none">None</option>
                                      {reftab.record.map((obj_t, index_tab) => (
                                        <option
                                          key={index_tab}
                                          value={obj_t.id}
                                        >
                                          {obj_t.value}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                ) : null}
                              </div>
                            ))}
                          </div>
                        )}
                        {rcd.noref === "true" && (
                          <div>
                            <div className="lom_td">
                              <input
                                type="text"
                                className={
                                  rcd.verified === "unverified" ||
                                  rcd.blank === true
                                    ? "lom_form_control formpadd unverifi"
                                    : "lom_form_control"
                                }
                                // className="lom_form_control formpadd"
                                value={rcd.value.value}
                                onChange={(e) =>
                                  formChangefn(
                                    e.target.value,
                                    row_ind,
                                    col_ind,
                                    rcd.id,
                                    rcd.name,
                                    rcd.type,
                                    false,
                                    ""
                                  )
                                }
                                onBlur={(e) =>
                                  validation(
                                    e.target.value,
                                    row_ind,
                                    col_ind,
                                    rcd.id,
                                    rcd.name,
                                    rcd.type
                                  )
                                }
                              ></input>
                              <div className="btnsrc vlpointer">
                                <i
                                  className="fa fa-search"
                                  onClick={(e) => {
                                    setObjLabel(heading[col_ind]);
                                    setcolumn(
                                      row_ind,
                                      col_ind,
                                      rcd.name,
                                      rcd.type,
                                      rcd.id
                                    );
                                  }}
                                ></i>
                              </div>
                              {refrecord.index === row_ind && showContainer &&
                                refrecord.record.length > 0 && (
                                  <div className="s_container">
                                    {refrecord.record.map((obj_ref, or_i) => (
                                      <div
                                        className="refrcd"
                                        onClick={(e) =>
                                          setRefrecord(
                                            obj_ref.value,
                                            row_ind,
                                            col_ind,
                                            rcd.name,
                                            rcd.type,
                                            rcd.id,
                                            obj_ref.id
                                          )
                                        }
                                        key={or_i}
                                      >
                                        {obj_ref.value}
                                      </div>
                                    ))}
                                  </div>
                                )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}
                    <span className="lom_div_margin">
                      {row_ind === 0 && (
                        <button
                          className="lom_button"
                          onClick={() => copyAll(col_ind)}
                        >
                          ca
                        </button>
                      )}
                      {row_ind > 0 && (
                        <button
                          className="lom_button"
                          onClick={() => copyparent(row_ind, col_ind)}
                        >
                          cp
                        </button>
                      )}
                    </span>
                  </span>
                </td>
              ))}
            </tr>
          ))}
          <span>
            {button.map((btobj, bt_i) => (
              <span key={bt_i}>
                
                  <button
                    key={btobj.name}
                    className="btn btn-primary"
                    onClick={() =>{ 
                      console.log(json);
                      callbtn(btobj.name,record,json)
                    }}
                  >
                    {btobj.name}
                  </button>
                
              </span>
            ))}
          </span>
        </tbody>
      </table>

      <Modal
        dialogClassName="my-modal"
        show={show}
        onHide={handleClose}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModelList
            setRef={setRef}
            columnid={columnid}
            onCancel={handleClose}
            previousFilter={"null"}
            previousTimeLine={" "}
            tableName={objLabel}
          ></ModelList>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MultiInsertMT;
