import { useEffect, useRef, useState } from "react";
import { MarketContext } from "../Context";
import axios from "axios";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { ApplicationProperties } from "../ApplicationProperties.js";
import errorImg from "../image/error.webp";

export default function WebWrapper() {
  const navigate = useNavigate();
  const [formtable, setFormTable] = useState("");
  const [formid, setFormId] = useState("");
  const [formtype, setFormType] = useState("");
  const [isVerified, setVerified] = useState(false);
  const [adminVerified, setAdminVerified] = useState(false);
  const [userVerified, setUserVerified] = useState(false);
  const [ipaddress, setIpaddress] = useState(
    localStorage.getItem("marketIpaddress")
  );
  const [marketTime, setMarketTime] = useState(
    localStorage.getItem("marketTime")
  );
  const [reLoadLeft, setReloadLeft] = useState(true);
  const [flagg, setFlagg] = useState(true);
  const [cartId, setCartId] = useState("");
  const [itemid, setItemId] = useState("");
  const [userid, setUserId] = useState("");
  const [landing, setLanding] = useState({});
  const imageloca = ApplicationProperties.imageloca;
  const loca = ApplicationProperties.loca;
  const [appname, setAppName] = useState(ApplicationProperties.appname);
  const tokenName = ApplicationProperties.tokenname;
  const [token, setToken] = useState(localStorage.getItem(tokenName));
  const [lanPref, setLanPref] = useState({});
  const [lst, setLst] = useState([]);
  const [showContainer, setShowContainer] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [msg, setMsg] = useState([]);
  const [showAll, setShowAll] = useState("false");
  const [cnt, setCnt] = useState("");
  const [leftNavData, setLeftNavData] = useState([]);
  const [user, setUser] = useState("");
  const [contextMenu, setContextMenu] = useState([]);
  const [labelContext, setLabelContext] = useState([]);
  const [landingPref, setLandingPref] = useState({});
  const [headHeight, setheadHeight] = useState(0);
  const [formHeight, setFormHeight] = useState(0);
  const [callType, setCallType] = useState({});
  const [loading, setLoading] = useState(true);
  const [serverTimeout, setServerTimeout] = useState(false);
  const locData = useLocation();
  const listAccess = useRef([]);
  const newAccess = useRef([]);

  const getVerify = async () => {
    setLoading(true);
    let tkn = localStorage.getItem(tokenName);
    if (tkn !== "" && tkn !== null) {
      await axios
        .get(loca + "/verify", {
          headers: {
            authorization: "Bearer " + tkn,
            "market-application": appname,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            var rsp = res.data[0].response;
            let adminverified = res.data[5].admin === "true";
            if (rsp === "verified") {
              setAdminVerified(adminverified);
              setUserVerified(!adminverified);
              setToken(tkn);
              appNameCall(tkn);
              setShowAll(res.data[6].showAll);
            } else if (rsp === "refereshed") {
              localStorage.setItem(tokenName, res.data[1].token);
              setToken(res.data[1].token);
              setAdminVerified(adminverified);
              setUserVerified(!adminverified);
              setShowAll(res.data[6].showAll);
              appNameCall(tkn);
            } else if (rsp === "not_verified" || rsp === "failed") {
              localStorage.setItem(tokenName, "");
              navigate("/login");
              setLoading(false);
            }
          }
        })
        .catch((error) => {
          if (error.code === "ERR_NETWORK") {
            setLoading(false);
            setServerTimeout(true);
          }
        });
    } else {
      navigate("/login");
      setLoading(false);
    }
  };

  const appNameCall = (tkn) => {
    axios
      .get(loca + "/marketplace/get/appname", {
        headers: {
          Authorization: "Bearer " + tkn,
          "market-application": appname,
        },
      })
      .then((res) => {
        setAppName(res.data);
        getUserDetails(tkn, false, null, res.data);
      });
  };

  const getUserDetails = (tkn, login, apnme) => {
    let u = localStorage.getItem("userDetails");
    let ud = JSON.parse(u);
    if (ud === null || ud === "undefined" || login) {
      axios
        .get(loca + "/marketplace/get/userdetails", {
          headers: {
            authorization: "Bearer " + tkn,
            "market-application": apnme,
          },
        })
        .then(
          (resp) => {
            let result = resp.data;

            localStorage.setItem(
              "userDetails",
              JSON.stringify(result.userdetails)
            );
            let user = "";
            if (result.userdetails.isadmin === "true") {
              setAdminVerified(true);
              setUser("");
            } else {
              setUserVerified(true);
              setUser("/user");
              user = "/user";
            }
            setUserDetails({ ...result.userdetails });
            getInitialData(tkn, result.userdetails, user, apnme);
          },
          (error) => {
            setLoading(false);
          }
        );
    } else {
      let user = "";
      if (ud.isadmin === "true") {
        setAdminVerified(true);
        setUser("");
      } else {
        setUserVerified(true);
        setUser("/user");
        user = "/user";
      }
      setUserDetails({ ...ud });
      getInitialData(tkn, ud, user, apnme);
    }
  };

  const getInitialData = (tkn, ud, user, apnme) => {
    axios
      .get(loca + "/marketplace/get/Notification", {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + tkn,
          "market-application": apnme,
        },
      })
      .then(
        (resp) => {
          let result = resp.data;
          let tmp = [];
          let msgg = result.notification.messages;
          for (let i = 0; i < msgg.length; i++) {
            if (msgg[i].active === "true") {
              tmp.push(msgg[i]);
            }
          }
          setMsg(tmp.reverse());
          setCnt(result.notification.count);
          loadleftnav(tkn, ud, user, apnme);
        },
        (error) => {
          if (error.response.status === 401) {
            localStorage.clear();
            getVerify();
          }
        }
      );
  };

  const loadleftnav = (tkn, ud, user, apnme) => {
    axios
      .get(loca + "/marketplace/get/modules", {
        headers: {
          authorization: "Bearer " + tkn,
          "market-application": apnme,
        },
      })
      .then((res) => {
        const modul = res.data;
        if ("Error" in modul) {
          console.log("error");
        } else {
          var lstt = [];
          let la = [];
          let na = [];

          for (var k = 0; k < modul.applicatioMenu[2].records.length; k++) {
            for (
              var j = 0;
              j < modul.applicatioMenu[2].records[k].section.length;
              j++
            ) {
              for (
                let o = 0;
                o < modul.applicatioMenu[2].records[k].section[j].module.length;
                o++
              ) {
                if (
                  modul.applicatioMenu[2].records[k].section[j].module[o]
                    .type === "new"
                ) {
                  na.push(
                    modul.applicatioMenu[2].records[k].section[j].module[o]
                      .form_table
                  );
                } else if (
                  modul.applicatioMenu[2].records[k].section[j].module[o]
                    .type === "list"
                ) {
                  la.push(
                    modul.applicatioMenu[2].records[k].section[j].module[o]
                      .form_table
                  );
                }
              }
              modul.applicatioMenu[2].records[k].section[j].ref = true;
            }
          }
          for (var i = 0; i < modul.applicatioMenu[2].records.length; i++) {
            if (
              modul.applicatioMenu[2].records[i].application_menu ===
              "Webservice"
            ) {
            }
            lstt.push({
              application_menu:
                modul.applicatioMenu[2].records[i].application_menu,
              section: modul.applicatioMenu[2].records[i].section,
              menu_order: modul.applicatioMenu[2].records[i].menu_order,
              ref: true,
              ref_all: true,
            });
          }
          listAccess.current = la;
          newAccess.current = na;
          getContextMenu(tkn, apnme);
          setLeftNavData(lstt);
          if ("landingPref" in modul) {
            sendLandingPref(modul.landingPref, ud, user);
          } else {
            sendLandingPref({ type: null }, ud, user);
          }
        }
      });
  };

  const sendLandingPref = (lannPref, ud, user) => {
    var lan_type = lannPref.type;
    let pref = "";
    if (lan_type === "dashboard") {
      pref = "/dashboard";
    } else if (lan_type === "report") {
      pref = "/report";
    } else if (lan_type === "checklist") {
      pref = "/checklicompo";
    } else {
      pref = "/dashboard";
    }
    if (locData.pathname === "/" || locData.pathname === "/login") {
      navigate(user + pref);
      if (ud.OS !== "null" && isMobile) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({ landing: user + pref })
        );
      }
    }
    setLoading(false);
  };

  const getContextMenu = (tkn, apnme) => {
    axios
      .get(loca + "/fetch/contextMenu", {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + tkn,
          "market-application": apnme,
        },
      })
      .then(
        (resp) => {
          setContextMenu(resp.data);
          getLabelContext(tkn, apnme);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const getLabelContext = (tkn, apnme) => {
    axios
      .get(loca + "/fetch/labelContext", {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + tkn,
          "market-application": apnme,
        },
      })
      .then(
        (resp) => {
          setLabelContext(resp.data);
          setLoading(false);
        },
        (error) => {
          setLoading(false);
          console.log(error);
        }
      );
  };

  const detectEdgeBrowser = () => {
    const userAgent = window.navigator.userAgent;
    if (userAgent.indexOf("Edge") > -1 || userAgent.indexOf("Edg") > -1) {
      document.body.classList.add("edge-browser");
    }
  };

  useEffect(() => {
    if (!token) {
      let body = document.getElementsByTagName("body");
      body[0].style.overflow = "auto";
    }
  }, [token]);

  useEffect(() => {
    detectEdgeBrowser();
    getVerify();
  }, []);
  if (!loading) {
    if (serverTimeout) {
      return (
        <div className="container d-flex flex-column mt-5">
          <img width={50} src={errorImg} alt="" />
          <h1>Server not reachable</h1>
          <p>Please try after sometime</p>
        </div>
      );
    } else {
      return (
        <MarketContext.Provider
          value={{
            token,
            setToken,
            tokenName,
            isVerified,
            setVerified,
            ipaddress,
            setIpaddress,
            setCartId,
            cartId,
            formtable,
            setFormTable,
            formid,
            setFormId,
            formtype,
            setFormType,
            marketTime,
            setMarketTime,
            itemid,
            setItemId,
            userid,
            setUserId,
            landing,
            setLanding,
            setAppName,
            adminVerified,
            userVerified,
            userDetails,
            showContainer,
            setShowContainer,
            landingPref,
            setLandingPref,
            lanPref,
            setLanPref,
            loca,
            lst,
            setLst,
            flagg,
            setFlagg,
            reLoadLeft,
            setReloadLeft,
            contextMenu,
            labelContext,
            user,
            msg,
            setMsg,
            cnt,
            setCnt,
            setUserDetails,
            showAll,
            setShowAll,
            leftNavData,
            setLeftNavData,
            imageloca,
            appname,
            setheadHeight,
            headHeight,
            listAccess,
            newAccess,
            callType,
            setCallType,
            formHeight,
            setFormHeight,
            setUser,
            setContextMenu,
            setLabelContext,
            getUserDetails,
            getVerify,
          }}
        >
          <Outlet />
        </MarketContext.Provider>
      );
    }
  }
}
