import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import imgg from "../image/applicationimg.png";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { Card, CardBody, CardHeader, CardFooter } from "reactstrap";

const NewApplication = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loginuser, setLoginUser] = useState(false);
  const [loginclient, setLoginclient] = useState(false);

  const onsubmit = () => {
    if (loginuser) {
      navigate("/urlogin");
    } else if (loginclient) {
      navigate("/cllogin");
    } else {
    }
  };
  const selectfn = (e) => {
    if (e==="userlogin") {
      setLoginUser(true);
      setLoginclient(false);
    } else if (e==="clientlogin") {
      setLoginclient(true);
      setLoginUser(false);
    } else {
      setLoginUser(false);
      setLoginclient(false);
    }
  };
  return (
    <div>
      <div className="parent-div">
        <Card className="bg-auth-1 card-width-manual">
          <CardHeader>
            <h4 className="text-center mb-0 mt-1">Application</h4>
            <hr />
          </CardHeader>

          <CardBody className="pt-0 pb-0 text-center">
            <div className="mt-2 text-center">
              <img style={{ height: "100px" }} src={imgg} alt="..." />
            </div>

            <div>
              <span>
                Welcome! If you wanted to be a create new application OR
                Application Login click on button?
              </span>
            </div>
            <div className="d-flex justify-content-center gap-2 my-3">
              <button
                className="btn btn-warning btn-width"
                onClick={handleShow}
              >
                App Login
              </button>

              <button type="button" className="btn btn-warning btn-width">
                Create App
              </button>
            </div>
            <hr className="mb-2 mt-4" />
          </CardBody>

          <CardFooter className="border-0 p-0">
            <div className="d-flex align-items-center gap-1 mb-3 mx-4">
              Don't have an account?
              <span className="underline text-warning cursor-pointer">
                Sign Up
              </span>
            </div>
          </CardFooter>
        </Card>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Application Login! </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>SelectType of login?</Form.Label>
              <Form.Select
                onChange={(e) => selectfn(e.target.value)}
                type="select"
                placeholder="select type of login"
              >
                <option>None</option>
                <option id="user" value={"userlogin"}>
                  UserLogin
                </option>
                <option id="client" value={"clientlogin"}>
                  ClientLogin
                </option>
              </Form.Select>
              <button
                style={{
                  marginTop: "23px",
                  width: "105px",
                  height: "37px",
                  borderRadius: "20px",
                  background: "#010b38",
                  color: "white",
                }}
                onClick={onsubmit}
              >
                submit
              </button>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default NewApplication;
