import React, { useContext, useEffect } from "react";
import { MarketContext } from "../Context";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import logo from "../image/Cloudsmayalogo.png";

const PortalComponent = () => {
  const { token, loca,  setAppName ,appname} = useContext(MarketContext);
  const [searchParams, setSearchParams] = useSearchParams();
  var app = searchParams.get("app");
  // console.log(app);
  var page = searchParams.get("page");
  // console.log(page);
  const navigation = useNavigate();

  useEffect(() => {
    setAppName(app);
    if (page !== "" && app !== "") {
      console.log(page);
      console.log(app);
      axios
        .get(loca + "/marketplace/get/index/" + app + `/` + page, {
          headers: {
            // "Content-Type": "application/json",
            authorization: "Bearer " + token,
            "market-application": appname,
          },
        })
        .then(
          (resp) => {
            let res = resp.data;
            console.log(res);
            let css = res.landing.page[0].css;
            let html = res.landing.page[0].html;

            let script = res.landing.page[0].script;
            const styleTag = document.createElement("style");
            const scriptTag = document.createElement("script");
            //createElement() is used to create html tags like <div>,<p> <style>etc
            const open = script.indexOf("{");
            const close = script.indexOf("}");
            //indexOf() returns the position of the first occurrence of a value in a string.
            const body = script.slice(open, close + 1);
           //Array.slice() returns selected array elements as a new array:
            styleTag.textContent = css;
            scriptTag.textContent = script;
            //textContent  Return the text content of an element:
            document.body.appendChild(styleTag);
            eventType(html, body);
          },
          (error) => {
            console.log(error);
          }
        );
    } else if (app !== "") {
      console.log(app);
      axios
        .get(loca + "/marketplace/get/index/" + app, {
          headers: {
            // "Content-Type": "application/json",
            authorization: "Bearer " + token,
            "market-application": appname,
          },
        })
        .then(
          (resp) => {
            let res = resp.data;
            let css = res.landing.page[0].css;
            let html = res.landing.page[0].html;

            let script = res.landing.page[0].script;
            const styleTag = document.createElement("style");
            const scriptTag = document.createElement("script");
            const open = script.indexOf("{");
            const close = script.indexOf("}");
            const body = script.slice(open, close + 1);

            styleTag.textContent = css;
            scriptTag.textContent = script;
            document.body.appendChild(styleTag);
            eventType(html, body);
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }, []);

  const eventType = (tag, body) => {
    if (tag.includes("onclick")) {
      let firstTag = tag.slice(tag.indexOf("<"), tag.indexOf(">") + 1);
      let fnName = "";
      let arr = firstTag.split("=");
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].includes("()")) {
          let k = arr[i];
          fnName = k.slice(k.indexOf("'"), k.lastIndexOf("'") + 1);
          console.log(fnName);
        }
      }
      let withoutFn = tag.replace(" onclick =" + fnName, "");
      document.getElementById("sd").innerHTML = withoutFn;
      document.getElementById("sd").firstChild.addEventListener("click", () => {
        let f = new Function(["navigation"], body);
        f(navigation);
      });
    } else if (tag.includes("onload")) {
      document
        .getElementById("sd")
        .firstChild.addEventListener("onload", () => {
          let f = new Function(["navigation"], "{navigation('setting')}");
          f(navigation);
        });
    } else if (tag.includes("onmove")) {
      document
        .getElementById("sd")
        .firstChild.addEventListener("onmove", () => {
          let f = new Function(["navigation"], "{navigation('setting')}");
          f(navigation);
        });
    } else if(tag != null){
       document.getElementById("sd").innerHTML = tag;
    }
    
  };

  return (<div id="sd">

    <img src={logo} alt="logo Image" />
  </div>
  );
};
export default PortalComponent;