import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { MarketContext } from "../Context.jsx";

const AdminComponent = () => {
  const {
    adminVerified,
  } = useContext(MarketContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!adminVerified) {
      navigate("/login");
    } 
  }, []);

  return <div></div>;
};

export default AdminComponent;
