import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../css/Portal.css";
import { Editor } from "@monaco-editor/react";
import { MarketContext } from "../Context";

export default function PortalCompo() {
  const navigation = useNavigate();
  const { html2, css2, script2, arrFunction, arrBody } =
    useLocation().state || "";
  const { token, loca, user, appname } = useContext(MarketContext);
  const [html, setHtml] = useState(html2 ?? "");
  const [script, setScript] = useState(script2 ?? "");
  const [css, setCss] = useState(css2 ?? "");
  const [wwget, setWwget] = useState([]);
  const [pid, setPid] = useState(0);
  const [name, setName] = useState("");

  const submitFn = () => {
    let newHtml = html.replaceAll('"', '\\"');
    let page = {
      html: newHtml,
      script: script,
      css: css,
      pid: pid,
      name: name,
    };

    axios
      .post(loca + "/marketplace/set/portal/pages", page, {
        headers: {
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then(
        (resp) => {
          let result = resp.data;
          console.log(result);
        },
        (error) => {
          navigation("/error");
          console.log(error);
        }
      );
  };

  const call_Widget = () => {
    axios
      .get(loca + "/marketplace/get/portal", {
        headers: {
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then((resp) => {
        let pd = resp.data;
        console.log("i m in " + JSON.stringify(pd));
        let pt = pd.portal;
        setWwget([...pt]);
      });
  };

  const testFn = () => {
    navigation(user + "/portalOutPut", {
      state: {
        html: html,
        script: script,
        css: css,
        arrFunction: arrFunction,
        arrBody: arrBody,
      },
    });
  };

  useEffect(() => {
    call_Widget();
  }, []);
  return (
    <div style={{ flexGrow: 1 }} id="container" className="cont">
      <div className="upper-box">
        <div className="d-flex ">
          <p className="mg-z">portal : </p>
          <div>
            <select
              className="slct mx-1"
              style={{ widht: "50%" }}
              onChange={(e) => {
                setPid(e.target.value);
              }}
            >
              <option value={-1}>None</option>
              {wwget.length > 0 &&
                wwget.map((obj, index) => (
                  <option key={index} value={obj.id}>
                    {obj.name}
                  </option>
                ))}
            </select>
          </div>
          <p className="mg-z mx-1">name : </p>
          <div>
            <input
              type="text"
              className="me-1 slct"
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="d-flex">
          <button
            className="btn btn-primary mx-1"
            style={{ margin: 0, marginTop: 5, marginRight: 5 }}
            onClick={testFn}
          >
            Test
          </button>
          <button
            className="btn btn-primary mx-1"
            style={{ margin: 0, marginRight: 5, marginTop: 5 }}
            onClick={submitFn}
          >
            submit
          </button>
        </div>
      </div>
      <div className="row ct" style={{ height: "80%" }}>
        <div className="wd col">
          <h1 style={{ height: "10%", margin: 0 }}>html</h1>
          <div className="editor">
            <Editor
              language="html"
              theme="vs-dark"
              value={html}
              onChange={(e, i) => {
                setHtml(e);
              }}
              options={{
                inlineSuggest: true,
                fontSize: "16px",
                formatOnType: true,
                autoClosingBrackets: true,
                minimap: { scale: 10 },
              }}
            />
          </div>
        </div>
        <div className="wd col">
          <h1 style={{ height: "10%", margin: 0 }}>css</h1>
          <div className="editor">
            <Editor
              language="css"
              theme="vs-dark"
              value={css}
              onChange={(e, i) => {
                setCss(e);
              }}
              options={{
                inlineSuggest: true,
                fontSize: "16px",
                formatOnType: true,
                autoClosingBrackets: true,
                minimap: { scale: 10 },
              }}
            />
          </div>
        </div>
        <div className="wd col">
          <h1 style={{ height: "10%", margin: 0 }}>js</h1>
          <div className="editor">
            <Editor
              language="javascript"
              theme="vs-dark"
              value={script}
              onChange={(e, i) => {
                setScript(e);
              }}
              options={{
                codeLens: false,
                inlineSuggest: true,
                fontSize: "16px",
                formatOnType: true,
                // autoClosingBrackets: true,
                // autoSurround: true,
              }}
            />
          </div>
        </div>
      </div>
      <div className="m-bottom">
        <button
          className="btn btn-primary align-self-end"
          style={{ margin: 0, marginRight: 5, marginTop: 5 }}
          onClick={testFn}
        >
          Test
        </button>
        <button
          className="btn btn-primary align-self-end"
          style={{ margin: 0, marginRight: 5, marginTop: 5 }}
          onClick={submitFn}
        >
          submit
        </button>
      </div>
      <canvas
        data-mdb-chart="bar"
        data-mdb-dataset-label="Traffic"
        data-mdb-labels="['Monday', 'Tuesday' , 'Wednesday' , 'Thursday' , 'Friday' , 'Saturday' , 'Sunday ']"
        data-mdb-dataset-data="[2112, 2343, 2545, 3423, 2365, 1985, 987]"
      ></canvas>
    </div>
  );
}
