import React, { useEffect, useState } from "react";

export default function ContextMenu({
  menuX,
  menuY,
  contextMenu,
  menuFn,
  excluded_menu,
  conditionFn,
}) {
  const [menus, setMenus] = useState([]);
  useEffect(() => {
    let temp = excluded_menu
      ? contextMenu.filter((e) => !excluded_menu.includes(e.name))
      : contextMenu;
    setMenus(temp);
  }, [contextMenu]);
  return (
    <ul
      className="dropdown-menu"
      style={{
        display: "block",
        top: menuY.current,
        left: menuX.current,
        padding: "0px",
        borderRadius: "0px",
        fontSize: "14px",
      }}
    >
      {conditionFn &&
        menus.map((obj, index) => (
          <li
            className="cursor-pointer"
            key={index}
            onMouseDown={() => {
              menuFn(obj.script);
            }}
          >
            <a
              style={{
                borderBottom: index !== menus.length - 1 && "1px solid #e3e3e3",
                fontWeight: "500",
              }}
              className="dropdown-item px-2"
            >
              {obj.Label}
            </a>
          </li>
        ))}
    </ul>
  );
}
