import React, { useState, useRef, useEffect,useContext } from "react";
import "../css/Index.css";
import { MarketContext } from "../Context";
import { Modal } from "antd";
import FormInnerCompo from "./FormInnerCompo";
import Draggable from "react-draggable";
import {
  createSearchParams,useNavigate
} from "react-router-dom";
import "../css/FormCSS.css";

const DragModel = ({
  setShowModelInfo,
  rcdView,
  showModelInfo,
  closeRef,
  rid,
  m_rid,
  setContextMenu,
  formChangefn,
  validationfn,
  passwordReadOnly,
  tableName,
  selection,
  setShowSuggestion,
  getCaretPos,
  setY,
  setX,
  infoRcdDetail,
  recordSuggestion,
  userRecordSuggestion,
  setSearchrcd,
  searchModule,
  afterCellEdit,
  x,
  y,
  showSuggestion,
  searchrcd,
  ListGroup,
  listRef,
  setCon,
  con,
  onSelection,
  clickRefrence,
  getSingleInfo,
  refrecord,
  showContainer,
  setRefRcd,
  showlist,
  col_mn,
  callfilter,
  setFiltArray,
  timeline,
  calltimeline,
  callScript,
  reScript,
  JoditEditor,
  handleFileChange,
  deleteImage,
  launchChoose,
  editor,
  choice_mn,
  choice_ref_mn,
  mscList,
  setMSC,
  verifyError,
  col_mn_ref,
  column_depend,
  dcFiltVal,
  multiSv,
  add,
  deftimeline,
  setImgObj,
  isMobile,
  filtRefArray,
  setFiltRefArray,
  imgObj,
  setGroupKeyValue
}) => {
  const {
    user,
  } = useContext(MarketContext);
  const navigation = useNavigate();
  const [visible, setVisible] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const [rcdViewTemp, setRcdViewTemp] = useState([]);

  const draggleRef = useRef();

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setShowModelInfo(!showModelInfo);
    closeRef.current = false;
    setTimeout(() => {
    }, 5000);
  };

  const openRecord=()=>{
    let sortColumn={
      name: "",
      sort: true,
      init: false,
    }
    let pageClicked=1;
    let farray=[];
    let fl = {
      co: "",
      cl: "",
      mc: "",
      an: "",
      ct: "",
      af: "",
      rf: {
        id: "",
        value: "",
      },
      dc: {
        id: "",
        value: "",
      },
      ch: [],
    };
    farray.push(fl);
    let filtarray=farray;

    let pg = { page: pageClicked, filter: filtarray, sort: sortColumn };
    navigation({
      pathname: user + "/form",
      search: createSearchParams({
        rty: "record",
        tableName: infoRcdDetail.tabName,
        rid: infoRcdDetail.tabid,
      }).toString(),
    });
    setShowModelInfo(!showModelInfo)
  }

  const onStart = (event, uiData) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement;
    const targetRect = draggleRef?.current?.getBoundingClientRect();
    setBounds({
      left: -targetRect?.left + uiData?.x,
      right: clientWidth - (targetRect?.right - uiData?.x),
      top: -targetRect?.top + uiData?.y,
      bottom: clientHeight - (targetRect?.bottom - uiData?.y),
    });
  };

  useEffect(() => {
    if (showModelInfo === true) {
      let rcdView2 = rcdView.slice();
      for (let i = 0; i < rcdView2.length; i++) {
        if (rcdView2[i].split === "true") {
          for (let k = 0; k < rcdView2[i].rcd.length; k++) {
              rcdView2[i].rcd[k].uivalid.read_only = "true"
              rcdView2[i].rcd[k].rcd_info="false" 
          }
        } else if (rcdView2[i].split === "false") {
          rcdView2[i].uivalid.read_only = "true";
          rcdView2[i].rcd_info ="false"
        }
      }
      setRcdViewTemp(rcdView2);
    }
  }, [showModelInfo]);

  return (
    <>
      {/*  <Button onClick={showModal}>Open Draggable Modal</Button> */}

      <Modal
        mask={false}
        maskClosable={true}
        keyboard={false}
        wrapClassName="aaa"
        width={600}
        height={400}
        style={{
          position: "fixed",
          left: (document.body.clientWidth - 500) / 2,
          top:12
        }}
        title={
          <div style={{ display: "flex" ,width:"100%",height:"36px" , justifyContent: "space-between" }}>
            <div
              style={{
                width: "90%",
              
                cursor: "move",
              }}
              onMouseOver={() => {
                if (disabled) {
                  setDisabled(false);
                }
              }}
              onMouseOut={() => {
                setDisabled(true);
              }}
            >
              <div className="flex justify-between items-end">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h3 style={{alignSelf:"center"}}>{infoRcdDetail.tabLabel}</h3>
                  <div style={{ display: "flex" }}>
                    <button
                      type="button"
                      className="btn btn-outline-dark"
                      style={{ width: "150px" }} 
                      onClick={()=>{openRecord()}}
                    >
                      Open Record
                    </button>
                   
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                width: "10%",
              }}
              onClick={() => {
                handleCancel();
              }}
            >
              <i className="fas fa-window-close" style={{paddingLeft:"30px" ,fontSize:"30px",cursor:"pointer"}}></i>
            </div>
          </div>
        }
        open={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div aa="2" ref={draggleRef}>
              {modal}
            </div>
          </Draggable>
        )}
      >
    
        <div className="maincompo" style={{overflowY:"auto" ,overflowX:"hidden",height:"380px",cursor:"not-allowed"}}>
          {rcdViewTemp.map((obj, index) => (
            <div key={index}>
              {obj.split === "false" && (
                <span key={index}>
                  <FormInnerCompo
                    index={index}
                    mscList={mscList}
                    setMSC={setMSC}
                    obj={obj}
                    rid={rid.current}
                    m_rid={m_rid}
                    tableName={tableName.current}
                    selection={selection}
                    setShowSuggestion={setShowSuggestion}
                    getCaretPos={getCaretPos}
                    setY={setY}
                    setX={setX}
                    recordSuggestion={recordSuggestion}
                    userRecordSuggestion={userRecordSuggestion}
                    setSearchrcd={setSearchrcd}
                    searchModule={searchModule}
                    afterCellEdit={afterCellEdit}
                    x={x}
                    y={y}
                    showSuggestion={showSuggestion}
                    searchrcd={searchrcd}
                    ListGroup={ListGroup}
                    listRef={listRef}
                    setCon={setCon}
                    con={setCon}
                    refrecord={refrecord}
                    showContainer={showContainer}
                    setRefRcd={setRefRcd}
                    showlist={showlist}
                    col_mn={col_mn}
                    callfilter={callfilter}
                    dcFiltVal={dcFiltVal}
                    setFiltArray={setFiltArray}
                    timeline={timeline}
                    calltimeline={calltimeline}
                    callScript={callScript}
                    reScript={reScript}
                    deleteImage={deleteImage}
                    handleFileChange={handleFileChange}
                    launchChoose={launchChoose}
                    JoditEditor={JoditEditor}
                    editor={editor}
                    choice_mn={choice_mn}
                    choice_ref_mn={choice_ref_mn}
                    verifyError={verifyError}
                    multiSv={multiSv}
                    add={add}
                    deftimeline={deftimeline}
                    setGroupKeyValue={setGroupKeyValue}
                  ></FormInnerCompo>
                </span>
              )}

              {obj.split === "true" && (
                <div className={isMobile ? "" : "row"}>
                  <div className="col-md-6">
                    {obj.rcd.map((obj_i, ind_i) => (
                      <div key={ind_i}>
                        {obj_i.formView.position === "left" && (
                          <span>
                            <FormInnerCompo
                              obj={obj_i}
                              rid={rid.current}
                              m_rid={m_rid}
                              index={index}
                              setContextMenu={setContextMenu}
                              formChangefn={formChangefn}
                              validationfn={validationfn}
                              passwordReadOnly={passwordReadOnly}
                              tableName={tableName.current}
                              selection={selection}
                              setShowSuggestion={setShowSuggestion}
                              getCaretPos={getCaretPos}
                              setY={setY}
                              setX={setX}
                              recordSuggestion={recordSuggestion}
                              userRecordSuggestion={userRecordSuggestion}
                              setSearchrcd={setSearchrcd}
                              searchModule={searchModule}
                              afterCellEdit={afterCellEdit}
                              x={x}
                              y={y}
                              showSuggestion={showSuggestion}
                              searchrcd={searchrcd}
                              ListGroup={ListGroup}
                              listRef={listRef}
                              setCon={setCon}
                              con={setCon}
                              onSelection={onSelection}
                              clickRefrence={clickRefrence}
                              getSingleInfo={getSingleInfo}
                              refrecord={refrecord}
                              showContainer={showContainer}
                              setRefRcd={setRefRcd}
                              showlist={showlist}
                              col_mn={col_mn}
                              callfilter={callfilter}
                              col_depend={column_depend}
                              dcFiltVal={dcFiltVal}
                              setFiltArray={setFiltArray}
                              timeline={timeline}
                              calltimeline={calltimeline}
                              callScript={callScript}
                              reScript={reScript}
                              JoditEditor={JoditEditor}
                              deleteImage={deleteImage}
                              setImgObj={setImgObj}
                              imgObj={imgObj}
                              launchChoose={launchChoose}
                              handleFileChange={handleFileChange}
                              editor={editor}
                              choice_mn={choice_mn}
                              choice_ref_mn={choice_ref_mn}
                              verifyError={verifyError}
                              mscList={mscList}
                              setMSC={setMSC}
                              multiSv={multiSv}
                              add={add}
                              deftimeline={deftimeline}
                              setGroupKeyValue={setGroupKeyValue}
                            ></FormInnerCompo>
                          </span>
                        )}
                      </div>
                    ))}
                  </div>
                  <div className="col-md-6">
                    {obj.rcd.map((obj_i, ind_i) => (
                      <div key={ind_i}>
                        {obj_i.formView.position === "right" && (
                          <span key={ind_i}>
                            <FormInnerCompo
                              obj={obj_i}
                              rid={rid.current}
                              m_rid={m_rid}
                              index={index}
                              mscList={mscList}
                              setMSC={setMSC}
                              setContextMenu={setContextMenu}
                              formChangefn={formChangefn}
                              validationfn={validationfn}
                              passwordReadOnly={passwordReadOnly}
                              tableName={tableName.current}
                              selection={selection}
                              setShowSuggestion={setShowSuggestion}
                              getCaretPos={getCaretPos}
                              setY={setY}
                              setX={setX}
                              recordSuggestion={recordSuggestion}
                              userRecordSuggestion={userRecordSuggestion}
                              setSearchrcd={setSearchrcd}
                              searchModule={searchModule}
                              afterCellEdit={afterCellEdit}
                              x={x}
                              y={y}
                              ref_filter={filtRefArray}
                              col_mn_ref={col_mn_ref}
                              setref_filter={setFiltRefArray}
                              showSuggestion={showSuggestion}
                              searchrcd={searchrcd}
                              ListGroup={ListGroup}
                              listRef={listRef}
                              setCon={setCon}
                              con={setCon}
                              onSelection={onSelection}
                              clickRefrence={clickRefrence}
                              getSingleInfo={getSingleInfo}
                              refrecord={refrecord}
                              showContainer={showContainer}
                              setRefRcd={setRefRcd}
                              showlist={showlist}
                              col_mn={col_mn}
                              callfilter={callfilter}
                              col_depend={column_depend}
                              dcFiltVal={dcFiltVal}
                              setFiltArray={setFiltArray}
                              timeline={timeline}
                              calltimeline={calltimeline}
                              callScript={callScript}
                              reScript={reScript}
                              deleteImage={deleteImage}
                              setImgObj={setImgObj}
                              imgObj={imgObj}
                              launchChoose={launchChoose}
                              handleFileChange={handleFileChange}
                              JoditEditor={JoditEditor}
                              editor={editor}
                              choice_mn={choice_mn}
                              choice_ref_mn={choice_ref_mn}
                              verifyError={verifyError}
                              multiSv={multiSv}
                              add={add}
                              setGroupKeyValue={setGroupKeyValue}
                            ></FormInnerCompo>
                          </span>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
};

//ReactDOM.render(<DragModel />, document.getElementById('container'));
export default DragModel;
