import React, { useState, useContext, useEffect, useRef } from "react";
import { MarketContext } from "../Context";
import WorkInProgress from "./WorkInProgress";
import "../css/Uipolicy.css";
import "../css/ListComponent.css";
import axios from "axios";

const UiPolicy = () => {
  const { token, loca ,appname} = useContext(MarketContext);
  const [loading, setLoading] = useState(false);
  let  oneRender =useRef(true)
  const [active, setActive] = useState(false);
  const [record, setRecord] = useState([]);
  const [tableList, setTableList] = useState([]);

  useEffect(() => {
    if (oneRender.current) {
      getTableName();
    getFormDetails();
    } else {
      oneRender.current = false;
    } 
  });

  const getFormDetails = () => {
    axios
      .get(loca +"marketplace/get/blankrecord/uipolicy", {
        headers: {
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then(
        (resp) => {
          const blkrecord = resp.data;
          console.log(JSON.stringify(blkrecord));

          // setState({
          // 	loading: false,
          // 	record: blkrecord.formRecord[2].record,
          // 	button: blkrecord.formRecord[3].button,
          // });
          // const uiScript=JSON.parse(blkrecord.formRecord[3].uiscript[0])
          // console.log("script : "+JSON.stringify(uiScript));
          // var fnString="const fn=()=>console.log('hey javaScript!!!!!!!!!')"

          // let script = blkrecord.formRecord[4].uiscript;

          // // onload
          // for (let i = 0; i < script.length; i++) {
          //   let field = script[i].field.name;
          //   let func = script[i].script;
          //   let type = script[i].type;
          //   if (type === "onload" && count.current === 1) {
          //     count.current = 0;
          //     onLoad(func);
          //   }
          // }
          // setUiScript([...script]);

          setLoading(false);
          setRecord(blkrecord.formRecord[2].record);
          console.log(
            "recorddddddddddddddddd : " +
              JSON.stringify(blkrecord.formRecord[2].record)
          );
          // setap_form(new ApForm(blkrecord.formRecord[2].record, setRecord));
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const getTableName = () => {
    axios
      .get(loca +"marketplace/get/tables", {
        headers: {
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then(
        (resp) => {
          let tableList = resp.data;
          setTableList(tableList.tableRecords);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const changeActive = (e) => {
    let frecord = record;
    console.log(e);
    setActive(e);
    for (let i = 0; i < frecord.length; i++) {
      if (frecord[i].name === "active") {
        if (e) {
          frecord[i].value = "true";
          break;
        } else {
          frecord[i].value = "false";
          break;
        }
      }
      setRecord([...frecord]);
    }
  };

  if (loading === false) {
    return (
      <div className="Card cardcss">
        <h1 className="hhh">UiPolicy</h1>
        <div className="" style={{ display: "flex" }}>
          <div className="ttt">
            <text>Table</text>
          </div>
          <select
            className=" inpttt "
            aria-label="Default select example"
            // onChange={(e) => changeApp(e.target.value)}
          >
            <option>None</option>
            {tableList.map((obj, index) => (
              <option
                key={index}
                value={obj.name}
                
              >
                {obj.label}
              </option>
            ))}
          </select>
          <div style={{ display: "flex", marginTop: "5px" }}>
            <div className="ttt">
              <text>Name</text>
            </div>
            <input
              type="text"
              className=" inpttt2 "
              aria-label="Default select example"
            ></input>
          </div>
        </div>
        <div className="divvvvvcheck">
          <div className="ttt">
            <text>Active</text>
          </div>
          <input
            type="checkbox"
            className=" inpcheckbox "
            aria-label="Default select example"
            checked={active}
            onChange={(e) => changeActive(e.target.checked)}
          ></input>
        </div>

        <div className="">
          <input
            type="button"
            className="submitbtnnnnnn"
            value="submit"
            onClick={() => console.log(JSON.stringify(record))}
          ></input>
        </div>
        <div style={{marginTop:"22px"}}>
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="UiPolicy Action-tab"
                data-bs-toggle="tab"
                data-bs-target="#UiPolicy Action"
                type="button"
                role="tab"
                aria-controls="UiPolicy Action"
                aria-selected="true"
              >
                UiPolicy Action
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="UiPolicy Related List Actions-tab"
                data-bs-toggle="tab"
                data-bs-target="#UiPolicy Related List Actions"
                type="button"
                role="tab"
                aria-controls="UiPolicy Related List Actions"
                aria-selected="true"
              >
                UiPolicy Related List Actions
              </button>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
            ></div>
            <div
              className="tab-pane fade"
            ></div>
          
          </div>
        </div>
      </div>
    );
  } else {
    <WorkInProgress />;
  }
};
export default UiPolicy;
