import axios from "axios";
import React, { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import { MarketContext } from "../Context";
import { toast } from "react-toastify";
import WorkInProgress from "./WorkInProgress";

const ImpersonateCompo = ({ cancel }) => {
  const navigation = useNavigate();
  const { token, loca, setImpersonate, getUserDetails, appname } = useContext(MarketContext);
  const [loading, setLoading] = useState(true)
  const [selected, setSelected] = useState(false)
  const [pSelected, setPselected] = useState(false)
  const [name, setName] = useState([])
  const [role, setRole] = useState([])
  const [appAccess, setAppAccess] = useState([])
  const [page, setPage] = useState([])
  const [index, setIndex] = useState(0)



  const [impName, setImpName] = useState("")
  const [impRole, setImpRole] = useState("")
  const [impApp, setImpApp] = useState("")
  const [impPage, setImpPage] = useState("")




  const submitImpersonation = () => {
    console.log(impApp);

    var ImpJs = { role: impRole, localuser: impName, app_access: impApp }
    navigation({ pathname: "/landing", search: (impPage !== "") ? createSearchParams({ app: impApp, page: impPage }).toString() : createSearchParams({ app: impApp }).toString() })
    // axios
    // .post(loca+"/marketplace/set/impersonation/value",ImpJs, {
    //   headers: {
    //     "Content-Type": "application/json",
    //     authorization: "Bearer " + token,
    //   },
    // })
    // .then(
    //   (resp) => {
    //     let res = resp.data;
    //     localStorage.setItem("impersonate","true")
    //     setImpersonate(true);
    //     cancel();
    //     // getUserDetails(token);
    //     getVerify()
    //     // setTimeout(() => {
    //     //   navigation("/")           
    //     // }, 2000);
    //     console.log(res);
    //   },
    //   (error) => {
    //     console.log(error);
    //     setLoading(false)
    //   }
    // );
  }


  const onSelect = (e) => {
    let i = e.target.selectedIndex;
    setImpName(e.target.value)
    if (i > 0) {
      setRole(name[i - 1].roles)
      setAppAccess(name[i - 1].app_access);
      setIndex(i - 1)
    }

    setSelected(true);
    setPselected(false);
  };

  const onAppSelect = (e) => {
    let i = e.target.selectedIndex;
    console.log(JSON.stringify(name))
    if (i > 0) {
      setPage(name[index].app_access[i - 1].pages);
    }

    setPselected(true);
  };

  const getInitialData = () => {
    axios
      .get(loca + "/marketplace/get/user/record", {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then(
        (resp) => {
          let res = resp.data;
          console.log(res);
          if ("Error" in res) {
            toast(res.Error, {
              position: "bottom-center",
              theme: "colored",
              type: "error",
            });
            setLoading(false)
          } else {
            setName(res.user)
            setLoading(false)

          }
        },
        (error) => {
          console.log(error);
          setLoading(false)
        }
      );
  };

  useEffect(() => {
    getInitialData();
  }, []);
  if (!loading) {
    return (
      <div style={{ flexGrow: 1 }}>
        <div style={{ marginBottom: 4, marginLeft: 1 }}>Local User</div>
        <select
          className="form-select prefinputlis"
          aria-label="Default"
          //   value={this.state.role}
          onChange={(e) => onSelect(e)}
        >
          <option value="none">none</option>

          {name.length > 0 && name.map((e, i) =>
            <option key={i} value={e.id}>{e.name}</option>
          )}

        </select>
        {selected && <div><div style={{ marginBottom: 4, marginLeft: 1 }}>Role</div>
          <select
            className="form-select prefinputlis"
            aria-label="Default"
            //   value={this.state.role}
            onChange={(e) => { setImpRole(e.target.value) }}
          ><div>Roll</div>
            <option value="none">none</option>

            {role.length > 0 && role.map((e, i) =>
              <option key={i} value={e.id}>{e.role}</option>
            )}

          </select></div>}
        {selected && <div> <div style={{ marginBottom: 4, marginLeft: 1 }}>Application</div>
          <select
            className="form-select prefinputlis"
            aria-label="Default"
            //   value={this.state.role}
            onChange={(e) => { setImpApp(e.target.value); onAppSelect(e) }}
          >
            <option value="none">none</option>

            {appAccess.length > 0 && appAccess.map((e, i) =>
              <option key={i} value={e.name}>{e.name}</option>
            )}

          </select></div>}
        {pSelected && <div><div style={{ marginBottom: 4, marginLeft: 1 }}>page</div>
          <select
            className="form-select prefinputlis"
            aria-label="Default"
            //   value={this.state.role}
            onChange={(e) => { setImpPage(e.target.value) }}
          >
            <option value="none">none</option>

            {page.length > 0 && page.map((e, i) =>
              <option key={i} value={e.name}>{e.name}</option>
            )}

          </select>
        </div>}
        <button className="btn btn-primary" onClick={submitImpersonation}>submit</button>
      </div>
    );
  } else {
    return <WorkInProgress />;
  }
};
export default ImpersonateCompo;
