import React, { Component, useContext, useEffect, useState } from "react";
import axios from "axios";
import "../css/Authentication.css";
import { MarketContext } from "../Context";
import { useLocation, useNavigate } from "react-router-dom";
import WorkInProgress from "./WorkInProgress";
import ApForm from "../ApForm";

const RegisterComponent = () => {
  const { type } = useLocation().state || {};
  const [record, setRecord] = useState([]);
  const [formrecord, setFormRecord] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page_error, setPage_error] = useState(false);
  const [error, setError] = useState();
  const [page_message, setpage_message] = useState(false);
  const [message, setMessage] = useState();
  const { loca } = useContext(MarketContext);
  const [ap_form, setap_form] = useState();
  const [ap_user, setap_user] = useState();

  const navigation = useNavigate();

  useEffect(() => {
    getRegisterRecord();
  }, []);

  const logincall = () => {
    navigation("/login");
  };

  const getRegisterRecord = () => {
    setLoading(true);
    axios
      .get(loca + "/marketplace/registration/" + type, {
        headers: {},
      })
      .then((resp) => {
        const mltpgrecord = resp.data;
        if ("Error" in mltpgrecord) {
          setLoading(false);
          setPage_error(true);
          setError(mltpgrecord.Error);
        } else {
          var mmm = mltpgrecord.formRecord[2].record;
          var script = mltpgrecord.formRecord[3].uiscript;
          for (var i = 0; i < mmm.length; i++) {
            mmm[i].verified = "initial";
          }
          setLoading(false);
          setRecord(mmm);
          setFormRecord(mltpgrecord);
          setap_form(new ApForm(mmm, setRecord, {}, null));
        }
        setLoading(false);
      });
  };

  const fieldverify = (type, vl) => {
    if (type === "email") {
      if (/^[a-zA-Z0-9.]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(vl)) {
        return "verified";
      } else {
        return "unverified";
      }
    }
    if (type === "String") {
      if (/[a-zA-Z0-9]/g.test(vl)) {
        return "verified";
      } else {
        return "unverified";
      }
    }

    if (type === "int") {
      if (/^[0-9]*[1-9][0-9]*$/.test(vl)) {
        return "verified";
      } else {
        return "unverified";
      }
    }
    if (type === "choice" || type === "table_lookup") {
      if (vl !== "None") {
        return "verified";
      } else {
        return "unverified";
      }
    }
    if (type === "date") {
      return "verified";
    }
  };

  const onChange = (func, val, obj) => {
    let fn = new Function(["ap_user", "ap_form", "val", "obj"], func);
    fn(ap_user, ap_form, val, obj);
  };

  const validationfn = (vl, index, ob) => {
    var formrecord = record;
    var minLength = formrecord[index].uivalid.min_length;
    if (minLength !== 0 && vl.length < minLength) {
      formrecord[index].verified = "unverified";
    } else {
      if (formrecord[index].name === ob) {
        if (vl !== "") {
          formrecord[index].verified = fieldverify(formrecord[index].type, vl);
        } else {
          formrecord[index].verified = "initial";
        }
      }
    }
    setRecord(formrecord);
  };

  const formChangefn = (vl, index, ob, obj) => {
    var frecord = record;
    if (frecord[index].name === ob) {
      if (frecord[index].type === "String" && vl != "") {
        console.log(frecord[index].validation);
        if (frecord[index].validation === "number") {
          if (/^[0-9]*$/.test(vl)) {
            frecord[index].value = vl;
            setRecord([...frecord]);
          }
        } else if (frecord[index].validation === "character") {
          if (/^[a-zA-Z\s]+$/.test(vl)) {
            frecord[index].value = vl;
            setRecord([...frecord]);
          }
        } else if (frecord[index].validation === "withoutSpecialCharacter") {
          if (/^[_A-z0-9\s]*((-|\s)*[_A-z0-9])*$/.test(vl)) {
            frecord[index].value = vl;
            setRecord([...frecord]);
          }
        } else if (frecord[index].validation === "zipCode") {
          if (/^[0-9]{5}(?:-[0-9]{4})?$/.test(vl)) {
            frecord[index].value = vl;
            setRecord([...frecord]);
          }
        } else if (frecord[index].validation === "decimal") {
          if (/^\d*\.?\d*$/.test(vl)) {
            frecord[index].value = vl;
            setRecord([...frecord]);
          }
        } else if (frecord[index].validation === "ipAddress") {
          if (/((([0-9a-fA-F]){1,4})\\:){7}([0-9a-fA-F]){1,4}$/.test(vl)) {
            frecord[index].value = vl;
            setRecord([...frecord]);
          }
        } else {
          frecord[index].value = vl;
          setRecord([...frecord]);
          // }
        }
      } else {
        frecord[index].value = vl;
        setRecord([...frecord]);
      }
    }
  };

  const Registerfn = () => {
    var rcd = record;
    var mandatory = [];
    var unverified = [];
    for (var i = 0; i < rcd.length; i++) {
      if (rcd[i].uivalid.visible === "true") {
        if (rcd[i].uivalid.mandatory === "true") {
          if (rcd[i].value === "") {
            mandatory.push(rcd[i].name);
          }
        }
        if (
          rcd[i].type === "int" ||
          rcd[i].type === "String" ||
          rcd[i].type === "email" ||
          rcd[i].type === "date"
        ) {
          var veri = fieldverify(rcd[i].type, rcd[i].value);
          if (veri === "unverified") {
            unverified.push(rcd[i].name);
          }
        }
      }
    }
    if (mandatory.length === 0 && unverified.length === 0) {
      var frcd = formrecord;
      frcd.formRecord[2].record = record;
      setLoading(true);
      axios
        .post(loca + "/marketplace/create/externalrecord/" + type, frcd, {
          headers: {},
        })
        .then((resp) => {
          var registerrcd = resp.data;
          if ("Error" in registerrcd) {
            setPage_error(true);
            setError(registerrcd.Error);
            setLoading(false);
          } else {
            setpage_message(true);
            setMessage(registerrcd.message);
            setLoading(false);
          }
        });
    } else {
      setPage_error(true);
      setError(" Check Mandatory fields not set:" + mandatory);
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  };

  return (
    <div
      className="container parent-div"
    >
      {loading === true ? (
        <WorkInProgress />
      ) : (
        <div className="maincss bg-auth-1 p-2 mb-5">
          <div onClick={logincall}>
            <i
              className="fa fa-arrow-left ms-2 mt-2"
              style={{
                color: "white",
                marginTop: "6px",
                marginRight: "4px",
                cursor: "pointer",
              }}
            ></i>
            <span style={{ color: "white", textDecoration: "none" }}>Back</span>
          </div>
          <div>
            {type === "user" || type === "client" ? (
              <div className="useric">
                <i className="fa fa-user-circle-o"></i>
              </div>
            ) : null}
            {type === "reqNewAppCreate" ? (
              <div className="useric">
                <i className="fas fa-user"></i>
              </div>
            ) : null}
            <div>
              {type === "user" ? (
                <h2 className="text-center">User Registration</h2>
              ) : null}
              {type === "client" ? (
                <h2 className="text-center">Client Registration</h2>
              ) : null}
              {type === "reqNewAppCreate" ? (
                <h2 className="text-center">Create Your New Application</h2>
              ) : null}
            </div>
          </div>
          {page_error === true && (
            <div
              className="alert alert-danger"
              role="alert"
              style={{
                padding: "0.2rem 0.2rem",
                marginBottom: "0px",
              }}
            >
              {error}
            </div>
          )}
          {page_message === true && (
            <div
              className="alert alert-success"
              role="alert"
              style={{
                padding: "0.2rem 0.2rem",
                marginBottom: "0px",
              }}
            >
              {message}
            </div>
          )}
          {record.length === 0 && (
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          )}
          <div className="d-gridd px-3">
            {record.map(
              (obj, index) =>
                obj.uivalid.visible === "true" && (
                  <div key={index}>
                    <div className="inppd">
                      {obj.type === "String" ? (
                        <div className="form-group objpdg ">
                          <div className="d-flex align-items-center">
                            {obj.uivalid.mandatory === "true" &&
                              obj.value !== "" && (
                                <i
                                  className="fa fa-asterisk mndtryfalse me-1 mt-1"
                                  
                                ></i>
                              )}

                            {obj.uivalid.mandatory === "true" &&
                              obj.value === "" && (
                                <i
                                  className="fa fa-asterisk mndtrytrue me-1 mt-1"
                                  
                                ></i>
                              )}
                            <span className="txt-white">{obj.label}</span>
                          </div>
                          <input
                            type="text"
                            className="form-control inps-register"
                            value={obj.value}
                            maxLength={obj.uivalid.max_length}
                            onChange={(e) => {
                              formChangefn(e.target.value, index, obj.name);
                            }}
                            onBlur={(e) =>
                              validationfn(e.target.value, index, obj.name)
                            }
                          ></input>
                        </div>
                      ) : null}

                      {obj.type === "int" ? (
                        <div className="form-group">
                          {obj.verified === "unverified" && (
                            <div
                              className="alert alert-danger"
                              role="alert"
                              style={{
                                padding: "0.2rem 0.2rem",
                                marginBottom: "0px",
                              }}
                            >
                              Please verify your integer number!
                            </div>
                          )}

                          <div className="d-flex align-items-center">
                            {obj.uivalid.mandatory === "true" &&
                              obj.value !== "" && (
                                // <span className="mndtryfals">*</span>
                                <i
                                  className="fa fa-asterisk mndtryfalse me-1 mt-1"
                                  
                                ></i>
                              )}
                            {obj.uivalid.mandatory === "true" &&
                              obj.value === "" && (
                                // <i className="mndtrytru ">*</i>
                                <i
                                  className="fa fa-asterisk mndtrytrue me-1 mt-1"
                                  
                                ></i>
                              )}
                            <span className="txt-white">{obj.label}</span>
                          </div>
                          <input
                            type="text"
                            className={
                              obj.verified === "unverified"
                                ? "form-control formpadd_danger"
                                : "form-control inps-register"
                            }
                            value={obj.value}
                            maxLength={obj.uivalid.max_length}
                            // readOnly={obj.uivalid.read_only === "true"}
                            onChange={(e) =>
                              formChangefn(e.target.value, index, obj.name)
                            }
                            onBlur={(e) =>
                              validationfn(e.target.value, index, obj.name)
                            }
                          ></input>
                        </div>
                      ) : null}

                      {obj.type === "email" ? (
                        <div className=" emailpadd form-group">
                          {obj.verified === "unverified" && (
                            <div
                              className="alert alert-danger"
                              role="alert"
                              style={{
                                padding: "0.2rem 0.2rem",
                                marginBottom: "0px",
                              }}
                            >
                              please verify your email
                            </div>
                          )}

                          <div className="d-flex align-items-center">
                            {obj.uivalid.mandatory === "true" &&
                              obj.value !== "" && (
                                <i
                                  className="fa fa-asterisk mndtryfalse me-1 mt-1"
                                  
                                ></i>
                              )}

                            {obj.uivalid.mandatory === "true" &&
                              obj.value === "" && (
                                <i
                                  className="fa fa-asterisk mndtrytrue me-1 mt-1"
                                  
                                ></i>
                              )}
                            <span className="txt-white">{obj.label}</span>
                          </div>
                          <input
                            type="email"
                            className={
                              obj.verified === "unverified"
                                ? "form-control formpadd_danger"
                                : "form-control inps-register"
                            }
                            value={obj.value}
                            maxLength={obj.uivalid.max_length}
                            readOnly={obj.uivalid.read_only === "true"}
                            onChange={(e) =>
                              formChangefn(e.target.value, index, obj.name)
                            }
                            onBlur={(e) =>
                              validationfn(e.target.value, index, obj.name)
                            }
                          ></input>
                        </div>
                      ) : null}

                      {obj.type === "choice" ? (
                        <div className="form-group ">
                          <div className="d-flex align-items-center">
                            {obj.uivalid.mandatory === "true" &&
                              obj.value !== "None" &&
                              obj.value !== "" && (
                                <i
                                  className="fa fa-asterisk mndtryfalse me-1 mt-1"
                                  
                                ></i>
                              )}

                            {obj.uivalid.mandatory === "true" &&
                              (obj.value === "None" || obj.value === "") && (
                                <i
                                  className="fa fa-asterisk mndtrytrue me-1 mt-1"
                                  
                                ></i>
                              )}
                            <span className="txt-white">{obj.label}</span>
                          </div>
                          <select
                            value={obj.value}
                            className="form-control form-select inps-register "
                            aria-label="Default select example"
                            onBlur={(e) =>
                              validationfn(e.target.value, index, obj.name)
                            }
                            onChange={(e) =>
                              formChangefn(e.target.value, index, obj.name)
                            }
                            maxLength={obj.uivalid.max_length}
                            readOnly={obj.uivalid.read_only === "true"}
                          >
                            <option value="None">None</option>
                            {obj.choice.map((ch, chi) => (
                              <option key={chi} value={ch.value}>
                                {ch.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      ) : null}
                      {obj.type === "date" ? (
                        <div className=" form-group">
                          {obj.verified === "unverified" && (
                            <div
                              className={
                                obj.verified === "unverified"
                                  ? "form-control formpadd_danger"
                                  : "form-control inps-register"
                              }
                              role="alert"
                              style={{
                                padding: "0.2rem 0.2rem",
                                marginBottom: "0px",
                              }}
                            >
                              please verify your date of birth!
                            </div>
                          )}
                          <div className="d-flex align-items-center">
                            {obj.uivalid.mandatory === "true" &&
                              obj.value !== "None" &&
                              obj.value !== "" && (
                                <i
                                  className="fa fa-asterisk mndtryfalse me-1 mt-1"
                                  
                                ></i>
                              )}

                            {obj.uivalid.mandatory === "true" &&
                              (obj.value === "None" || obj.value === "") && (
                                <i
                                  className="fa fa-asterisk mndtrytrue me-1 mt-1"
                                  
                                ></i>
                              )}
                            <span className="txt-white">{obj.label}</span>
                          </div>
                          <input
                            type="date"
                            className="form-control inps-register"
                            value={obj.value}
                            maxLength={obj.uivalid.max_length}
                            readOnly={obj.uivalid.read_only === "true"}
                            onChange={(e) =>
                              formChangefn(e.target.value, index, obj.name)
                            }
                            onBlur={(e) =>
                              validationfn(e.target.value, index, obj.name)
                            }
                          ></input>
                        </div>
                      ) : null}
                      {obj.type === "boolean" ? (
                        <div className="form-check">
                          <span className="txt-white">{obj.label}</span>
                          <input
                            type="checkbox"
                            className="checkpadd"
                            // className="form-control checkpadd"
                            maxLength={obj.uivalid.max_length}
                            value={obj.value === "true" ? true : false}
                            readOnly={obj.uivalid.read_only === "true"}
                            onChange={(e) =>
                              formChangefn(e.target.value, index, obj.name)
                            }
                            onBlur={(e) =>
                              validationfn(e.target.value, index, obj.name)
                            }
                          ></input>
                        </div>
                      ) : null}

                      {obj.type === "table_lookup" ? (
                        <div className="form-group ">
                          <div className="d-flex align-items-center">
                            {obj.uivalid.mandatory === "true" &&
                              obj.value !== "None" &&
                              obj.value !== "" && (
                                <i
                                  className="fa fa-asterisk mndtryfalse me-1 mt-1"
                                  
                                ></i>
                              )}

                            {obj.uivalid.mandatory === "true" &&
                              (obj.value === "None" || obj.value === "") && (
                                <i
                                  className="fa fa-asterisk mndtrytrue me-1 mt-1"
                                  
                                ></i>
                              )}
                            <span className="txt-white">{obj.label}</span>
                          </div>
                          <select
                            className="form-control form-select inps-register "
                            aria-label="Default select example"
                            onBlur={(e) =>
                              validationfn(e.target.value, index, obj.name)
                            }
                            onChange={(e) =>
                              formChangefn(e.target.value, index, obj.name)
                            }
                            maxLength={obj.uivalid.max_length}
                            readOnly={obj.uivalid.read_only === "true"}
                            value={obj.value}
                          >
                            <option value="None">None</option>
                            {console.log(obj.lookup)}
                            {obj.lookup.map((ch, chi) => (
                              <option key={chi} value={ch.name}>
                                {ch.value}
                              </option>
                            ))}
                          </select>
                        </div>
                      ) : null}
                    </div>
                  </div>
                )
            )}
          </div>
          <div className="text-center my-3">
            <button
              className=" btn btn-warning"
              onClick={Registerfn}
            >
              Register
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
export default RegisterComponent;
