import axios from "axios";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { MarketContext } from "../Context";
import { Modal, Button } from "react-bootstrap";
import React, { useState, useContext, useEffect, useRef } from "react";
import NewFilterCompo from "./NewFilterCompo";
import { isMobile } from "react-device-detect";
import PrefCompo from "./PrefCompo";
import WorkInProgress from "./WorkInProgress";

const RelationList = ({
  recordList,
  tableName,
  i_d,
  all_ref_cols,
  rd,
  prevTab
}) => {
  const { token, loca,imageloca, user, appname } = useContext(MarketContext);
  const navigation = useNavigate();
  const counter = useRef(0);
  const [searchParam, setSearchParam] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [col_mn, setCol_mn] = useState([]);
  const [list, setlist] = useState([]);
  const [showlist, setShowList] = useState(false);
  const [showbtn, setShowbtn] = useState(false);
  const [button, setButton] = useState([]);
  const [mainrecord, setMainRecord] = useState({});
  const [sortColumn, setSortColumn] = useState({ name: "", sort: false });
  const [prefCall, setPrefCall] = useState([]);
  const [filtarray, setFiltArray] = useState([]);

  const [filString, setfilString] = useState("");
  const [filt, setFilt] = useState({
    co: "",
    cl: "",
    mc: "",
    an: "",
    ct: "",
    af: "",
    rf: { id: "", value: "" },
  });
  const [timeline, setTimeline] = useState("");
  const [pageClicked, setPageClicked] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [pageRecords, setPageRecords] = useState("");
  const [recordCount, setRecordCount] = useState();
  const [listName, setListName] = useState(tableName);
  const [showPref, setShowPref] = useState(false);
  const [columnList, setColumnList] = useState([]);
  const [srch, setSrch] = useState("");
  const [srch_column, setSrch_column] = useState("name");
  const [tableLabel, setTableLabel] = useState("");

  //................ Functions ............//

  useEffect(() => {
    if (counter.current === 0) {
      setListRecord(recordList);
      setTableLabel(recordList.formRecordList[1].table.label);
      counter.current = 1;
    }
  }, [searchParam]);

 

  const handleShow = () => {
    setShowPref(true);
  };

  const handleClose = () => {
    setShowPref(false);
  };

  const handleCloseRefresh = () => {
    handleClose();
    var tablere = '{"formRecordList":[';
    tablere += '{"application":{"id":"","name":"marketplace"}}';
    tablere += ',{"table":{"id":"","name":"' + listName + '"}}';
    tablere += ',{"records":[]}';
    tablere +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    tablere += ',{"sort":{"asc":"true","column":"id","init":"true"}}';
    tablere += ',{"filter":' + JSON.stringify(filtarray) + "}";
    tablere += ',{"timeLine":"' + timeline + '"}]}';
    setList(tablere);
  };

  const leftReadOnly = () => {
    if (pageClicked === 1) {
      return true;
    } else {
      return false;
    }
  };

  const rightReadOnly = () => {
    if (pageClicked === pageCount) {
      return true;
    } else {
      return false;
    }
  };

  const previousPage = () => {
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","name":"marketplace"}}';
    pp += ',{"table":{"id":"","name":"' + listName + '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"' +
      (pageClicked - 1) +
      '","page_records":"0"}}';
    pp += setSort();
    pp += ',{"filter":' + JSON.stringify(filtarray) + "}";
    pp += ',{"timeLine":"' + timeline + '"}]}';

    setList(pp);
  };

  const nextPage = () => {
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","name":"marketplace"}}';
    pp += ',{"table":{"id":"","name":"' + listName + '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"' +
      (pageClicked + 1) +
      '","page_records":"0"}}';
    pp += setSort();
    pp += ',{"filter":' + JSON.stringify(filtarray) + "}";
    pp += ',{"timeLine":"' + timeline + '"}]}';

    setList(pp);
  };

  const firstPage = () => {
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","name":"marketplace"}}';
    pp += ',{"table":{"id":"","name":"' + listName + '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    pp += setSort();
    pp += ',{"filter":' + JSON.stringify(filtarray) + "}";
    pp += ',{"timeLine":"' + timeline + '"}]}';

    setList(pp);
  };

  const lastPage = () => {
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","name":"marketplace"}}';
    pp += ',{"table":{"id":"3","name":"' + listName + '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"' +
      pageCount +
      '","page_records":"0"}}';
    pp += setSort();
    pp += ',{"filter":' + JSON.stringify(filtarray) + "}";
    pp += ',{"timeLine":"' + timeline + '"}]}';

    setList(pp);
  };




  const setTimeLine = (e) => {
    setTimeline(e);
  };

  const showupdownbtn = (hd) => {
    var sarray = sortColumn;
    var clmn = "";
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","name":"marketplace"}}';
    pp += ',{"table":{"id":"","name":"' + listName + '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';

    if (sarray.name === hd) {
      sarray.sort = !sarray.sort;
      for (var l = 0; l < list.length; l++) {
        for (var ll = 0; ll < list[l].heading.length; ll++) {
          if (list[l].heading[ll].label === hd) {
            clmn = list[l].heading[ll].name;
            break;
          }
        }
      }

      if (sarray.sort === true) {
        pp += ',{"sort":{"asc":"true","column":"' + clmn + '","init":"false"}}';
      } else {
        pp +=
          ',{"sort":{"asc":"false","column":"' + clmn + '","init":"false"}}';
      }
    } else {
      sarray.name = hd;
      sarray.sort = true;
      for (var li = 0; li < list.length; li++) {
        for (var lll = 0; lll < list[li].heading.length; lll++) {
          if (list[li].heading[lll].label === hd) {
            clmn = list[li].heading[lll].name;
            break;
          }
        }
      }
      if (sarray.sort === true) {
        pp += ',{"sort":{"asc":"true","column":"' + clmn + '","init":"false"}}';
      } else {
        pp +=
          ',{"sort":{"asc":"false","column":"' + clmn + '","init":"false"}}';
      }
    }
    setCol_mn({ sortColumn: sarray });
    pp += ',{"filter":' + JSON.stringify(filtarray) + "}";
    pp += ',{"timeLine":"' + timeline + '"}]}';
    setList(pp);
  };

  const setSort = () => {
    var clmn = "";
    var pp = "";
    var srt = sortColumn.name;
    if (srt !== "") {
      for (var l = 0; l < list.length; l++) {
        for (var ll = 0; ll < list[l].heading.length; ll++) {
          if (list[l].heading[ll].label === sortColumn.name) {
            clmn = list[l].heading[ll].name;
            break;
          }
        }
      }
      if (sortColumn.sort === true) {
        pp += ',{"sort":{"asc":"true","column":"' + clmn + '","init":"false"}}';
      } else {
        pp +=
          ',{"sort":{"asc":"false","column":"' + clmn + '","init":"false"}}';
      }
      return pp;
    } else {
      pp +=
        ',{"sort":{"asc":"true","column":"' +
        list[0].heading[0].name +
        '","init":"false"}}';
    }
    return pp;
  };

  //.................. Api Call ...............//

  const setListRecord = (listrecord) => {
    var columnarry = [];
    var hd = [];
    var va_ll = [];
    if ("Error" in listrecord) {
    } else {
      var scnd = listrecord.formRecordList[2];
      if ("Error" in scnd) {
        if (scnd.Error === "No record found.") {
          setlist([]);

          // this.setState({ list: [] });
        }
      } else {
        var page_clicked1 = parseInt(
          listrecord.formRecordList[3].page.page_clicked
        );
        var page_count1 = parseInt(
          listrecord.formRecordList[3].page.page_count
        );
        var page_records1 = listrecord.formRecordList[3].page.page_records;
        var record_count1 = parseInt(
          listrecord.formRecordList[3].page.record_count
        );

        var flt = listrecord.formRecordList[5];
        var fltarr = listrecord.formRecordList[5].filter;
        var tmln = listrecord.formRecordList[6].timeLine;
        var filterString = "";
        var leng = fltarr.length;
        for (var f = 0; f < fltarr.length; f++) {
          if (leng === 1 && fltarr[f].cl === "") {
            break;
          } else {
            if (f > 0) {
              filterString += fltarr[f].af + " ";
            }
            filterString += fltarr[f].cl + " ";
            filterString += fltarr[f].mc + " ";
            if (fltarr[f].ct === "reference") {
              filterString += fltarr[f].rf.value + " ";
            } else {
              filterString += fltarr[f].an + " ";
            }
          }
        }
        for (var i = 0; i < listrecord.formRecordList[2].records.length; i++) {
          var in_vl = [];
          var record_id;
          for (
            var j = 0;
            j < listrecord.formRecordList[2].records[i].record.length;
            j++
          ) {
            if (j === 0) {
              record_id =
                listrecord.formRecordList[2].records[i].record[j].value;
            } else if (j === 1) {
              if (
                listrecord.formRecordList[2].records[i].record[j].type ===
                "reference"
              ) {
                in_vl.push({
                  value:
                    listrecord.formRecordList[2].records[i].record[j].value,
                  type: listrecord.formRecordList[2].records[i].record[j].type,
                  firstrecord: true,
                  table: listrecord.formRecordList[1].table.name,
                  r_id: record_id,
                });
              } else {
                in_vl.push({
                  value:
                    listrecord.formRecordList[2].records[i].record[j].value,
                  type: listrecord.formRecordList[2].records[i].record[j].type,
                  firstrecord: true,
                  table: listrecord.formRecordList[1].table.name,
                  r_id: record_id,
                });
              }
            } else {
              if (
                listrecord.formRecordList[2].records[i].record[j].type ===
                "reference"
              ) {
                in_vl.push({
                  value:
                    listrecord.formRecordList[2].records[i].record[j].value,
                  type: listrecord.formRecordList[2].records[i].record[j].type,
                  firstrecord: false,
                  table: listrecord.formRecordList[1].table.name,
                  r_id: record_id,
                });
              } else {
                in_vl.push({
                  value:
                    listrecord.formRecordList[2].records[i].record[j].value,
                  type: listrecord.formRecordList[2].records[i].record[j].type,
                  firstrecord: false,
                  table: listrecord.formRecordList[1].table.name,
                  r_id: record_id,
                });
              }
            }
          }
          va_ll.push({ colr: in_vl });
        }
        let col_list = [];
        for (var c = 0; c < listrecord.formRecordList[9].column.length; c++) {
          if (listrecord.formRecordList[9].column[c].type === "String") {
            col_list.push({
              label: listrecord.formRecordList[9].column[c].label,
              name: listrecord.formRecordList[9].column[c].name,
              type: listrecord.formRecordList[9].column[c].type,
            });
          }
        }
        for (
          var p = 0;
          p < listrecord.formRecordList[10].preference.length;
          p++
        ) {
          hd.push({
            label: listrecord.formRecordList[10].preference[p].label,
            name: listrecord.formRecordList[10].preference[p].name,
            type: listrecord.formRecordList[10].preference[p].type,
          });
        }
        columnarry.push({ heading: hd, rcd: va_ll });
        var cla = JSON.parse(
          JSON.stringify(listrecord.formRecordList[9].column)
        );
        cla.unshift({ label: "None", name: "none" });
        setlist(columnarry);
        setPageClicked(page_clicked1);
        setPageCount(page_count1);
        setPageRecords(page_records1);
        setRecordCount(record_count1);
        setCol_mn(cla);
        setColumnList(col_list);
        setPrefCall(listrecord.formRecordList[9].column);
        setFiltArray(fltarr);
        setTimeLine(tmln);
        setfilString(filterString);
        setButton(listrecord.formRecordList[11].button);
        setLoading(false);
      }
    }
  };

  const setList = (tablere) => {
    axios
      .post(loca + "/marketplace/get/relation/record", tablere.toString(), {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then(
        (resp) => {
          const listrecord = resp.data;
          setListRecord(listrecord);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const changeSearch = (val) => {
    if (val === "") {
      setSrch("");
      filterClear();
    } else {
      setSrch(val);
    }
  };

  const callSearchbtn = () => {
    var type = "";
    var label = "";
    for (var c = 0; c < columnList.length; c++) {
      if (srch_column === columnList[c].name) {
        type = columnList[c].type;
        label = columnList[c].label;
      }
    }
    let filte = [
      {
        ct: type,
        af: "",
        mc: "=",
        cl: label,
        co: srch_column,
        an: srch,
        rf: { id: "", value: "" },
      },
    ];
    var fs = '{"formRecordList":[';
    fs += '{"application":{"id":"","name":"marketplace"}}';
    fs += ',{"table":{"id":"","name":"' + listName + '","label":""}}';
    fs += ',{"records":[]}';
    fs +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    fs += ',{"sort":{"asc":"true","column":"id","init":"false"}}';
    fs += ',{"filter":' + JSON.stringify(filte) + "}";
    fs += ',{"timeLine":"' + timeline + '"}]}';
    setFiltArray([...filte]);
    setList(fs);
  };

  const searchColumn = (e) => {
    // this.setState({ srch_column: e.target.value });
    setSrch_column(e.target.value);
  };

  const callform = (nam, tab, r_id) => {
    if (nam === "first") {
      navigation({
        pathname: user + "/form",
        search: createSearchParams({
          rty: "record",
          tableName: tab,
          rid: r_id,
        }).toString(),
      });
      // props.showFormCompo(tab, r_id, "record");
    }  else if (nam === "new") {
      navigation({
        pathname: user + "/form",
        search: createSearchParams({
          rty: "new",
          tableName: tab,
          rid: r_id,
        }).toString(),
      });
      // props.showFormCompo(tab, 0, "new");
    }
  };

  // const dismiss = () => {
  //   unmountMe();
  // }

  const filterItem = () => {
    setShowList(!showlist);
    setShowbtn(!showbtn);
  };

  const filterClear = () => {
    var fltarray = [];
    fltarray.push(filt);
    setFiltArray(fltarray);
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","name":"marketplace"}}';
    pp += ',{"table":{"id":"","name":"' + listName + '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    pp += setSort();
    pp += ',{"filter":' + JSON.stringify(filtarray) + "}";
    pp += ',{"timeLine":"' + timeline + '"}]}';

    setList(pp);
  };

  const filtersubmit = () => {
    var fs = '{"formRecordList":[';
    fs += '{"application":{"id":"","name":"marketplace"}}';
    fs += ',{"table":{"id":"","name":"' + listName + '"}}';
    fs += ',{"records":[]}';
    fs +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    fs += ',{"sort":{"asc":"true","column":"id","init":"false"}}';
    fs += ',{"filter":' + JSON.stringify(filtarray) + "}";
    fs += ',{"timeLine":"' + timeline + '"}]}';
    setList(fs);
  };

  const callbtn = (nam) => {
    var btn = button;
    var mnrecord = mainrecord;

    for (var ij = 0; ij < btn.length; ij++) {
      if (btn[ij].value === nam) {
        var btntype = btn[ij].returnWebLocation;
        var callType = btn[ij].call_type;
        setLoading(true);
        if (callType === "selection") {
          // navigation(user + "/role-selection", {
          //   state: { i_d: i_d, rt: tableName },
          // });
          navigation({
            pathname: user + "/role-selection",
            search: createSearchParams({ tableName: tableName, rid: i_d }).toString(),
          });
        } else if (callType === "module") {
          var scpt = btn[ij].script;
          var setTab = "";
          var tabId = "";
          for (let i = 0; i < rd.length; i++) {
            if (rd[i].name === "market_table_id") {
              setTab = rd[i].value.name;
              tabId = rd[i].value.id;
            }
          }
          
          var f = new Function(["navigation", "fieldName", "record","all_ref_cols", "createSearchParams,user", "setTab", "tabId"], scpt);
          f(navigation, prevTab, rd,all_ref_cols, createSearchParams, user, setTab, tabId);
        } else {
          axios 
            .get(loca + btn[ij].webUrl, {
              headers: {
                "Content-Type": "application/json",
                authorization: "Bearer " + token,
                "market-application":appname
              },
            })
            .then((resp) => {
              const rcd = resp.data;
              setLoading(false);
              if (btntype === "NextPage") {
                navigation("/form", { state: { rty: "new", tableName: listName, rid: "0" }, })
             
              }
            });
        }
      }
    }
  };


  const getChoiceRcd = async (col_id) => {
    let chk = [];
    let ck = await axios
      .get(loca + "/marketplace/get/choice/" + col_id, {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          let res = resp.data;
          let chk = res.choiceRecords;
          return chk;
          // console.log(rcd);
        },
        (error) => {
          console.log(error);
        }
      );
    return ck;
  };

  const callfilter = async (farray, in_index, col_id) => {
    if (col_id !== -1) {
      if (
        farray[in_index].ct === "choice" &&
        farray[in_index].ch.length === 0
      ) {
        let chc = await getChoiceRcd(col_id).then((res) => {
          if (res.length > 0) {
            farray[in_index].ch = res;
            farray[in_index].an = res[0].name;
          } else {
            farray[in_index].ch = [];
          }
        });
      }
    }
    setFiltArray([...farray]);
  };

  const calltimeline = (timeline) => {
    setTimeline(timeline);
  };

  if (!loading) {
    return (
      <div className="pagesetup">
        {loading === true ? (
          <WorkInProgress />
        ) : (
          <div>
            {isMobile === true ? (
              <div className="row bck bck-rel">
                <div className="col-lg mid">
                  <span className="obj_head ">{listName}</span>
                </div>
                <div className="col-lg filter-ic">
                  {showlist === false && (
                    <select
                      className="form-select namelist-mob"
                      aria-label="Default"
                      value={srch_column}
                      onChange={(e) => searchColumn(e)}
                    >
                      {columnList.map((obj2, index) => (
                        <option key={index} value={obj2.name}>
                          {obj2.label}
                        </option>
                      ))}
                    </select>
                  )}

                  {showlist === false && (
                    <input
                      className="srch-mob form-"
                      type="search"
                      aria-label="Search"
                      value={srch}
                      
                      onChange={(e) => changeSearch(e.target.value)}
                    ></input>
                  )}

                  {showlist === false && (
                    <input
                      className="csm_btn csm_btn_pri col-md-2 sub-btn"
                      type="button"
                      value="Search"
                      
                      onClick={() => callSearchbtn()}
                    ></input>
                  )}
                </div>
                <div className="col-lg martop10 disfl filter-ic">
                  <i
                    className="fa fa-filter vlpointer pdtop5"
                    
                    onClick={filterItem}
                  ></i>
                  <i
                    className="fa fa-cog vlpointer cog_pd pdtop5"
                    
                    onClick={handleShow}
                  ></i>
                  <div className="">
                    <span style={{ marginLeft: "0.5em" }}>
                      <input
                        className=" mybt"
                        type="button"
                        value="<<"
                        onClick={firstPage}
                        disabled={leftReadOnly()}
                      />
                      <input
                        className=" mybt"
                        type="button"
                        value="<"
                        onClick={previousPage}
                        disabled={leftReadOnly()}
                      />
                    </span>
                    <span>
                      <input
                        className="in-put"
                        type="text"
                        readOnly={true}
                        value={pageRecords}
                      ></input>
                    </span>
                    <span className="text-uppercase spfont"> of </span>
                    <span className="rcd_count spfont">{recordCount}</span>
                    <span>
                      <input
                        className=" mybt"
                        type="button"
                        value=">"
                        onClick={nextPage}
                        disabled={rightReadOnly()}
                      />
                    </span>
                    <span>
                      <input
                        className=" mybt"
                        type="button"
                        value=">>"
                        disabled={rightReadOnly()}
                        onClick={lastPage}
                      />
                    </span>
                  </div>
                </div>
                <div className="col-lg martop10">
                  {button.length > 0 &&
                    button.map((obj, oo_i) => (
                      <button
                        type="button"
                        className="csm_btn csm_btn_pri col-md-2 sub-btn"
                        key={oo_i}
                        onClick={(e) => callbtn(obj.value)}
                      >
                        {obj.name}
                      </button>
                    ))}
                </div>
                <div className="col-lg martop10">
                  {showlist === true && (
                    <input
                      className="csm_btn csm_btn_pri col-md-2 sub-btn"
                      type="button"
                      value="Run"
                      
                      onClick={filtersubmit}
                    ></input>
                  )}

                  {showlist === true && (
                    <input
                      className="csm_btn csm_btn_pri col-md-2 sub-btn"
                      type="button"
                      value="Clear"
                      
                      onClick={filterClear}
                    ></input>
                  )}
                </div>
              </div>
            ) : (
              <div
                className="bck bck-rel"
                style={{
                  display: "grid",
                  gridTemplateColumns: "2fr 1fr 2fr",
                  justifyContent: "between",
                  alignItems: "center",
                  gridTemplateAreas: "a b c",
                }}
              >
                {/* <div className="row bck bck-rel"> */}
                <div className="filter-ic">
                  <i
                    className="fa fa-filter vlpointer"
                    
                    onClick={filterItem}
                  ></i>

                  <i
                    className="fa fa-cog vlpointer cog_pd"
                    
                    onClick={handleShow}
                  ></i>

                  {showlist === true && (
                    <input
                      className="btn btn-primary btn-sm me-2"
                      type="button"
                      value="Run"
                      
                      onClick={filtersubmit}
                    ></input>
                  )}

                  {showlist === true && (
                    <input
                      className="btn btn-primary btn-sm"
                      type="button"
                      value="Clear"
                      
                      onClick={filterClear}
                    ></input>
                  )}

                  {showlist === false && (
                    <select
                      style={{
                        // lineHeight: "0.8em",
                        width: "10em",
                        display: "inline",
                      }}
                      className="form-select namelist"
                      aria-label="Default"
                      value={srch_column}
                      onChange={(e) => searchColumn(e)}
                    >
                      {columnList.map((obj2, index) => (
                        <option key={index} value={obj2.name}>
                          {obj2.label}
                        </option>
                      ))}
                    </select>
                  )}

                  {showlist === false && (
                    <input
                      className="srch form-control"
                      type="search"
                      aria-label="Search"
                      value={srch}
                      
                      style={{ height: "1.7rem" }}
                      onChange={(e) => changeSearch(e.target.value)}
                    ></input>
                  )}

                  {showlist === false && (
                    <button
                      className="btn btn-primary btn-sm"
                      type="button"
                      value="Search"
                      
                      onClick={() => callSearchbtn()}
                    >
                      Search
                    </button>
                  )}
                </div>
                <div className="col-lg mid">
                  <p className="obj_head ">{tableLabel}</p>
                </div>
                <div className="bt_padd">
                  {button.length > 0 &&
                    button.map((obj, oo_i) => (
                      <button
                        className=" insrtbtn2 btn btn btn-primary btn-sm"
                        key={oo_i}
                        onClick={(e) => callbtn(obj.value)}
                      >
                        {obj.name}
                      </button>
                    ))}
                  <div>
                    <input
                      className=" mybt"
                      type="button"
                      value="<<"
                      onClick={firstPage}
                      disabled={leftReadOnly()}
                    />
                    <input
                      className=" mybt"
                      type="button"
                      value="<"
                      onClick={previousPage}
                      disabled={leftReadOnly()}
                    />

                    <span className="in-put">{pageRecords}</span>
                    <span className="text-uppercase spfont"> of </span>

                    <span className="rcd_count spfont">{recordCount}</span>
                    <input
                      className=" mybt"
                      type="button"
                      value=">"
                      onClick={nextPage}
                      disabled={rightReadOnly()}
                    />
                    <input
                      className=" mybt"
                      type="button"
                      value=">>"
                      disabled={rightReadOnly()}
                      onClick={lastPage}
                    />
                  </div>
                </div>
              </div>
            )}
            {showlist === false && filString !== "" && <div>{filString}</div>}
            <div></div>
            {showlist === true && (
              <NewFilterCompo
                showlist={showlist}
                col_mn={col_mn}
                call_fil={(filtarray, in_index, col_id) => {
                  callfilter(filtarray, in_index, col_id);
                }}
                filtarray={filtarray}
                setFiltArray={setFiltArray}
                timeline={timeline}
                setTimeLine={(timeline) => calltimeline(timeline)}
                setScript={(script) => {}}
                isScript={false}
                tableName={listName}
                isRelatedList={true}
              />
            )}
            {list.length > 0 &&
              list.map((lstobj, lst_i) => (
                <div
                  className="heading_top table_set overflow-auto"
                  style={{ position: "relative" }}
                  key={lst_i}
                >
                  {/* Moved key to the outer div */}
                  <table className="table table-bordered table-striped table-hover p-1">
                    <thead
                      className="table-dark"
                      style={{ position: "sticky" }}
                    >
                      <tr className="obj_name">
                        {lstobj.heading.map(
                          (obj, obj_i) =>
                            obj.type !== "group_key_value" &&
                            obj.type !== "key_value" &&
                            obj.type !== "password" && (
                              <th
                              key={obj_i}
                                className="vlpointer"
                                onClick={(e) => showupdownbtn(obj.label)}
                                style={{ textWrap: "nowrap" }}
                              >
                                {obj.label}
                                {sortColumn.name === obj.label &&
                                  sortColumn.sort === true && (
                                    <i className=" icpadding fa fa-arrow-up"></i>
                                  )}
                                {sortColumn.name === obj.label &&
                                  sortColumn.sort === false && (
                                    <i className=" icpadding fa fa-arrow-down"></i>
                                  )}
                              </th>
                            )
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {lstobj.rcd.map((objj, objj_i) => (
                        <tr className="obj_value" key={objj_i}>
                          {objj.colr.map(
                            (objr, objr_i) =>
                              objr.type !== "group_key_value" &&
                              objr.type !== "key_value" &&
                              objr.type !== "password" && (
                                <td
                                key={objr_i}
                                  className={
                                    objr.firstrecord === true
                                      ? "val_pad val_under vlpointer"
                                      : "val_pad"
                                  }
                                  onClick={
                                    objr.firstrecord === true
                                      ? (e) =>
                                          callform(
                                            "first",
                                            objr.table,
                                            objr.r_id
                                          )
                                      : (e) => callform("second", "", "")
                                  }
                                >
                                  {objr.type === "image_upload" ? (
                                    objr.value !== "null" &&
                                    objr.value !== "" ? (
                                      <img
                                        className="list-images"
                                        // style={{
                                        //   height: "90px",
                                        //   width: "100%",
                                        //   objectFit: "contain",
                                        // }}
                                        src={imageloca + objr.value}
                                        alt=""
                                      />
                                    ) : (
                                      <span>No Image</span>
                                    )
                                  ) : objr.type === "video" ? (
                                    <video
                                      loop
                                      className="user-video-list"
                                      controls
                                    >
                                      <source
                                        src={imageloca + objr.value}
                                        type="video/mp4"
                                      />
                                      Your browser does not support the video
                                      tag.
                                    </video>
                                  ) : objr.type === "reference" ? (
                                    <span>
                                      {objr.value.name !== "null"
                                        ? objr.value.name
                                        : ""}
                                    </span>
                                  ) : (
                                    <span>
                                      {(objr.type === "filter" ||
                                        objr.type === "json") &&
                                      objr.value !== null
                                        ? JSON.stringify(objr.value.name)
                                        : objr.value === "null"
                                        ? ""
                                        : objr.value}
                                    </span>
                                  )}
                                </td>
                              )
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {lstobj.rcd.length === 0 && (
                    <div
                      style={{
                        fontWeight: "bold",
                        textDecoration: "none",
                        fontStyle: "normal",
                      }}
                    >
                      No Record Found
                    </div>
                  )}
                </div>
              ))}
          </div>
        )}

        <Modal
          dialogClassName="my-modal"
          size="xl"
          show={showPref}
          onHide={handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Personalized List Column</Modal.Title>
          </Modal.Header>
          <Modal.Body className="mod-ht">
            <PrefCompo
              pref="user"
              colarray={prefCall}
              tablename={tableName}
              handleClose={() => handleCloseRefresh()}
            ></PrefCompo>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  } else {
    <WorkInProgress />;
  }
};

export default RelationList;
