import axios from "axios";
import React, { useContext, useEffect, useState,useRef } from "react";
import { MarketContext } from "../Context";

const PreferenceComponent = ({
  handleClose,
  pref,
  setRef,
  colarray,
  tablename,
}) => {
  console.log(tablename);
  
  const { token, loca ,appname} = useContext(MarketContext);
  const firstRender = useRef(false);
  const oneRef = useRef(false)
  // const [tablename, setTableName] = useState("");
  const [columnarray, setColumnArray] = useState(colarray);
  const [columnarray2, setColumnarray2] = useState([""]);
  const [prefarray, setPrefArray] = useState([]);
  const [json, setJson] = useState({});
  console.log(" ffffff : "+JSON.stringify(colarray));
  // const [pref, setPref] = useState("");

  useEffect(() => {
    getPrefRecord();
  }, []);

  useEffect(() => {
    if (firstRender.current && oneRef.current) {
      setColumn()
  
    } else {
      firstRender.current=true
      oneRef.current = true
    }
    
  }, [prefarray])
  

  const getPrefRecord = () => {
   console.log(pref,tablename);
    axios
      .get(loca +"/marketplace/get/pref/" + pref + "/" + tablename, {
        headers: {
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then((resp) => {
        var sysrecord = resp.data;
        console.log(sysrecord);
        if (pref === "user") {
          console.log(sysrecord);
          setPrefArray([...sysrecord.userPreference[2].column]);
          
          setJson(sysrecord);
        } else if (pref === "owner") {
          setPrefArray([...sysrecord.ownerPreference[2].column]);
          setJson(sysrecord);
        }

      });
  };

  const setColumn = () => {
    
    var clm = columnarray;
    var clm2 = columnarray2;
    var prf = prefarray;
    for (var ii = 0; ii < clm.length; ii++) {
      clm[ii].check = false;
    }
    for (var i = 0; i < clm.length; i++) {
      for (var p = 0; p < prf.length; p++) {
        if (clm[i].name === prf[p].name) {
          clm[i].check = true;
          clm2.push(clm[i].label);
        }
      }
    }
   
    setColumnArray([...clm]);
    setColumnarray2([...clm2]);
    oneRef.current = false;
  };

  const changeColumn = (e, index) => {
    var col = columnarray;
    col[index].check = e;
    setColumnArray([...col]);
  };

  const setColumnbtn = () => {
    var colm = columnarray;
    var colm2 = columnarray2;
    colm2 = [];
    for (var i = 0; i < colm.length; i++) {
      if (colm[i].check === true) {
        colm2.push(colm[i].label);
      }
    }
    
    setColumnarray2(colm2);
  };

  const submitColumnbtn = () => {
    
    var colm = columnarray;
    var colm2 = columnarray2;
    var sub = [];
    // var count = 0;
    for (var i = 0; i < colm2.length; i++) {
      for (var j = 0; j < colm.length; j++) {
        if (colm2[i] === colm[j].label) {
          sub.push({ name: colm[j].name });
          // count++;
        }
      }
    }
    if (pref === "user") {
      console.log("kmds;;");  
      json.userPreference[2].column = sub;
      json.userPreference[1].table.name = tablename;
      console.log("kmds;;");
      axios
        .post(loca +"marketplace/set/user/preference/", json, {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + token,
            "market-application": appname,
          },
        })
        .then((resp) => {
            var sysrecord = resp.data; 
          handleClose();
        });
    } else if (pref === "owner") {
      json.ownerPreference[2].column = sub;
      json.ownerPreference[1].table.name = tablename;
      axios
        .post(loca +"marketplace/set/owner/preference/", json, {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + token,
            "market-application": appname,
          },
        })
        .then((resp) => {
          var sysRecord = resp.data;
          console.log("syssssssssss : "+JSON.stringify(sysRecord));
          handleClose();
        });
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-6">
          {columnarray.length > 0 &&
            columnarray.map((obj, obj_i) => (  
              <div key={obj_i}>
                <div className="row">
                  <div className="col-md-10">{obj.label}</div>
                  <div className="col-md-2">
                    <input
                      className="chx_bx"
                      type="checkbox"
                      checked={obj.check ? true : false}
                      onChange={(e) => changeColumn(e.target.checked, obj_i)}
                    ></input>
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div className="col-md-6">
          {columnarray2.length > 0 &&
            columnarray2.map((obp, obj_p) => <div key={obj_p}>{obp}</div>)}
        </div>
      </div>
      <div className="row">
        <div className="col-md-4"></div>
        <div className="col-md-3">
          <button
            type="button"
            className=" btnnn btn btn-primary"
            onClick={() => setColumnbtn()}
          >
            Set
          </button>
        </div>
        <div className="col-md-3">
          <button
            type="button"
            className=" btnnn btn btn-primary"
            onClick={() => submitColumnbtn()}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default PreferenceComponent;
