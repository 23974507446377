import React, { useEffect, useRef, useState } from "react";
import "../css/InvoiceLabel.css";
import { useReactToPrint } from "react-to-print";
import {
    useLocation,
    useNavigate
  } from "react-router-dom";

export default function InvoiceLabel() {
  const handlePrint = useReactToPrint({
    content: () => invoiceRef.current,
  });
  const invoiceRef = useRef(null);
  // const { toPDF, invoiceRef } = usePDF({filename: 'invoice.pdf'});
  const Data = useLocation();
  console.log(Data);
  const [data,setData] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    if (Data) {
      setData(Data.state.data)
    }
  }, [Data]);
  useEffect(() => {
    if (data) {
      // setData(Data.state.data)
      handlePrint();
      navigate(-1)
    }
  }, [data]);


  return (
    <>
      <section className="overfl-tab mt-5">
        <div
          className="shipping-label scaled-wrapper prnt-div mt-5"
          ref={invoiceRef}
        >
          {data && 
          <div className="scaled-content prnt-ch-1">
            <table
              className="w-100 inv-table"
              style={{
                borderLeft: "2px solid black",
                borderRight: "2px solid black",
                borderBottom: "2px solid black",
              }}
            >
              <tbody>
                <tr>
                  {/* <td className="" style={{ width: "38%" }}> */}
                  <td className="">
                    <tr className="">
                      <td
                        className="address-padding"
                        style={{
                          borderRight: "2px solid black",
                          borderBottom: "2px solid black",
                        }}
                      >
                        <div className="inv-fw-bold">Ship To:</div>
                        <div className="inv-sto-name">{data.rpin}</div>
                        {/* <div>Humayun Ansari</div> */}
                        <div>{data.address}</div>
                        <div>{data.destination}</div>
                        <div className="inv-fw-bold">PIN:{data.rpind}</div>
                      </td>
                    </tr>
                    <tr className="">
                      <td
                        className="address-padding"
                        style={{
                          borderRight: "2px solid black",
                        }}
                      >
                        <div>
                          <span className="inv-fw-bold">Seller:</span>
                          CLOUDSMAYA SERVICES PRIVATE LIMITED
                        </div>
                        <div>
                          <span className="inv-fw-bold">Address:</span>{" "}
                          {data.sadd}
                        </div>
                      </td>
                    </tr>
                  </td>
                  <td className="">
                    <div className="company-logo-section">
                      <td className="inv-td" style={{ width: "38%" }}>
                        <img
                          alt="CLOUDSMAYA SERVICES PRIVATE LIMITED"
                          // src={data.cl_logo}
                          src="https://static-delhivery.s3.amazonaws.com/client_logo/246178_bfd433-cloudsmayaservicespr-do_logo.jpg?response-content-disposition=attachment%3B%20filename%3D246178-CLIENT_LOGO.jpg&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIASYPQWAPQ6WP6UK7O%2F20240425%2Fap-southeast-1%2Fs3%2Faws4_request&X-Amz-Date=20240425T043735Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEFMaDmFwLXNvdXRoZWFzdC0xIkcwRQIhALFR%2BrpRCTSaNOm9Z92L%2FQmXym2wSYWQsNDWKY2c8qvEAiASgrbsnnp564Ic2tV8q5KgeJONVEqRGJs9YjO9tHZuYyrTBQic%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F8BEAAaDDE5MDAyMDE5MTIwMSIMl0up%2FTKXeOlMAsOYKqcFYZVG5DR44AYzOJRpyHK3fTQWivqJouisb8fX9lRy7YoWP4sv1WNVF6Z6rwRk9QrmIYPqa%2BI5SUO95wqsiZEYKjeOpGdmKzNTl89I8dr78Heg1S5lXtyCF%2FBCVtlixfaI7tONbTwUgaDTEgK9QVZy8%2Fgr96HrFI3w7FnLjWVPo%2B6KozognEH1CIHHbFUVDQzlAQhkW6J89vOIuwXimVppYKGFdh8IxS3gxCAl85NAlvaKsqF%2FLZcEImut1YQ8e92qG7x%2BWU9H8ZJir0AUExa3aMVBPAG6RaynHQ0uQ0%2FjHIpLK%2FlsPF%2Bk33shpLitlHRSaH67wJkoddizP65shxcOsBR1myif4%2BN2tlaAiGtzd9f%2F1HLK7wn6PqkdHS0uDW28FSKzxpl8jzz1BkL5Dwod8DVV7ttOZM4UQBCscnbPgTm8JuiMHWeo8%2BOEMqSh1Y%2BOwJHsOnbmIg4O8ZRizUpIhdvrjfgiNXoiGx%2BUK7X9Q%2FL7e2IlmSMqo1grB1qXze3HwuAnviDskTbtVjdSCs7ML7w7PFxEjn3GHe3azF90xexk8yZcbWnG9Ue81rfoUOuTzRgUb0Jx7ljkzX9JK5o%2BnYec9qDGXrrEYegrJl2%2FuuVc2NF%2B2chLth%2F4UtTMjFXztbK0EYKzDPuXbUfapSOqGeHpOQtlSINERwIp%2FefA9xILQIy2jBkbrxbFvVGQ%2FmO1WEU6Tl%2FhOhV90NcY5D5z%2BK2fLHUk1wR9y4mrkq02qTfyBIOWMB%2BV%2FHlIfGZXxP4hcz9b%2BLY8zPYnfUL5CueDQuga%2FoL7nR4BtJHiEgd6vW54Ras7SmtpWuudTIcIpdIAjV31oiRgep5fyW%2B%2FYXOfxTAkmEzMdsXAuRVlZ5Vq1PBpCCzCPPC6fW17f%2FGWjAknTgcIAK19rjCml6exBjqxAet2Vi3xseiGJtVNtEN3OinxVOwB07tmsIQGIUsv%2FZRGkEKcKgAYjBKvx6YX6FUjRuevGEHEAQTeSREEz9hiW%2FTJN6CXMvc4A6YFhNBU2OODZ2aV3a38bns6s9Ll8vBN%2FBLZOjv%2F3sawW%2BCSHwVXRVBwvoONFAbTYafPzjDGHV3DN%2BX%2BYWGDbSIUdQoZBsgcY6PZdmA3fS9n9NPJ90%2F2ERE8HJNdnmYlz9NCcvz72lbdhw%3D%3D&X-Amz-Signature=6a6a3fa0c13a679528cd3f2963100e3cbd1764cd0f4698ea05c7964c3d5cdfaf"
                          className="hide-on-error inv-img"
                          height="50px"
                        />
                        <span className="image-error-text inv-span">
                          CLOUDSMAYA SERVICES PRIVATE LIMITED
                        </span>
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <img
                          src="https://www.delhivery.com/direct/app/email/logo.png"
                          className="inv-del-img"
                        />
                      </td>
                    </div>
                    <div className="center-flex">
                      <img src={data.barcode} alt="" />
                    </div>
                    <div
                      style={{
                        borderTop: "2px solid black",
                        width: "100%",
                        // marginTop: "12px",
                        marginTop: "8px",
                      }}
                    >
                      <td className="p-onemm">{data.rpin}</td>
                      <td align="right" className="td-fw-bld">
                        {data.sort_code}
                      </td>
                    </div>
                    <div className="p-onemm" colspan="3">
                      <div>
                        <span className="inv-fw-bold">Date:</span> 2024-4-3 10:
                        48: 1
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <span>
              ----------------------------------------------------- Fold Here
              -----------------------------------------------------
            </span>

            <table className="inv-tab-3 mt-1">
              <tbody>
                <tr>
                  <td className="inv-td-prod-det text-center" colSpan={6}>
                    <h5 className="m-0">
                      <b> TAX INVOICE</b>
                    </h5>
                  </td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <th className="inv-td-prod-det" colSpan={2}>
                    BILL TO
                  </th>
                  <th className="inv-td-prod-det" colSpan={2}>
                    SHIP TO
                  </th>
                  <th className="inv-td-prod-det" colSpan={2}>
                    SELLER DETAILS
                  </th>
                </tr>
              </tbody>
              <tbody>
                <tr className="inv-tr-azdf">
                  <td
                    className="address-padding"
                    style={{
                      borderRight: "2px solid black",
                      borderBottom: "2px solid black",
                    }}
                    colSpan={2}
                  >
                    <div className="inv-sto-name">{data.name}</div>
                    {/* <div>Humayun Ansari</div> */}
                    <div>{data.address}</div>
                    <div className="inv-fw-bold">PIN:{data.rpin}</div>
                  </td>
                  <td
                    className="address-padding"
                    style={{
                      borderRight: "2px solid black",
                      borderBottom: "2px solid black",
                    }}
                    colSpan={2}
                  >
                    <div className="inv-sto-name">{data.name}</div>
                    {/* <div>Humayun Ansari</div> */}
                    <div>{data.address}</div>
                    <div className="inv-fw-bold">PIN:{data.rpin}</div>
                  </td>
                  <td
                    className="address-padding"
                    style={{
                      borderRight: "2px solid black",
                    }}
                    colSpan={2}
                  >
                    <div>
                      <span className="inv-fw-bold">Sold By:</span> CLOUDSMAYA
                      SERVICES PRIVATE LIMITED
                    </div>
                    <div>
                      <span className="inv-fw-bold">
                        GSTIN:{data.seller_gst_tin}
                      </span>
                    </div>
                    <div>
                      <span className="inv-fw-bold">Order No.:</span>{" "}
                      {data.order_no}
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <th className="inv-td-prod-det">Description</th>
                  <th className="inv-td-prod-det">Price</th>
                  <th className="inv-td-prod-det">Qty</th>
                  <th className="inv-td-prod-det">Taxable Value</th>
                  <th className="inv-td-prod-det">Taxes</th>
                  <th className="inv-td-prod-det">Total</th>
                </tr>
              </tbody>
              <tbody>
                <tr className="inv-tr-azdf">
                  <td className="inv-td-prod-det">{data.prd}</td>
                  <td align="center" className="inv-adjdslds">
                    {data.rs}
                  </td>
                  <td align="center" className="inv-adjdslds">
                    {data.qty}
                  </td>
                  <td align="center" className="inv-adjdslds">
                    450
                  </td>
                  <td align="center" className="inv-adjdslds">
                    IGST @5.0%
                  </td>
                  <td align="center" className="inv-adjdslds">
                    504
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr className="inv-border-2">
                  <th className="inv-td-prod-det" colSpan={5}>
                    Total
                  </th>
                  <th className="inv-td-prod-det">{data.rs}</th>
                </tr>
              </tfoot>
            </table>
            <table className="inv-adrs-table">
              <tbody>
                <tr>
                  <td className="p-onemm ">Return Address: {data.radd}</td>
                </tr>
              </tbody>
            </table>
          </div>}
        </div>
      </section>
    </>
  );
}
