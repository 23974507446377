import axios from "axios";
import "../css/ApplicationSet.css";
import React, { useState, useContext, useEffect } from "react";
import { MarketContext } from "../Context";
import WorkInProgress from "./WorkInProgress";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";

const ApplicationSet = () => {
  const navigation = useNavigate();
  const { token, loca, showAll, setAppName, appname,getVerify } =
    useContext(MarketContext);
  const [loading, setLoading] = useState(true);
  const [record, setRecord] = useState([]);
  const [userApplication, setUserApplication] = useState({});
  const [app, setApp] = useState("");

  useEffect(() => {
    getApplicationsDetails();
  }, []);

  const getApplicationsDetails = () => {
    axios
      .get(loca + "/marketplace/get/applications", {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then(
        (response) => {
          const appsRcd = response.data.user_application;
          setRecord([...appsRcd[0].applications]);
          setUserApplication(appsRcd[1].application_set);
          setApp(appsRcd[1].application_set.name)
          setLoading(false);
        },
        (error) => {
          navigation("/error");
        }
      );
  };

  const changeApp = (val) => {
    setApp(val);
  };

  const setUserApp = () => {
    // Check if the application with the same name is already set
    if (app === appname) {
      // Display a toast message indicating that the application is already set
      toast(`${app} is already set. You cannot set it again.`, {
        position: "bottom-center",
        theme: "colored",
        type: "warning",
      });
      return;
    }
    // If the application is not already set, proceed with the API call
    axios
      .get(`${loca}/marketplace/set/userapplication/${app}`, {
        headers: {
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then((resp) => {
        const userSet = resp.data;
        if (userSet) {
          getVerify()
          // setAppName(record[record.findIndex((a) => a.name === app)].name);
          // getApplicationsDetails();
          // toast("Application Set Successfully", {
          //   position: "bottom-center",
          //   theme: "colored",
          //   type: "success",
          // });
        } else {
          toast("Something Went Wrong", {
            position: "bottom-center",
            theme: "colored",
            type: "error",
          });
        }
      })
      .catch((error) => {
        console.error("Error setting user application:", error);
        toast("An error occurred while setting the application", {
          position: "bottom-center",
          theme: "colored",
          type: "error",
        });
      });
  };

  if (!loading) {
    return (
      <div style={{ width: "100%" }}>
        <div className="container  cardcss p-1">
          {showAll === "false" ? (
            <div>
              <div className="text-start d-flex align-items-center mt-3 mx-2">
                <h5> Application:</h5>
                <select
                  className="form-select py-1 ms-2"
                  onChange={(e) => changeApp(e.target.value)}
                  value={app}
                >
                  {record.map((obj, index) => (
                    <option
                      key={index}
                      value={obj.name}
                    >
                      {obj.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="d-flex justify-content-start">
                <button
                  className="btn btn-dark  mt-2 mb-2 ms-2 px-2 py-1"
                  onClick={setUserApp}
                >
                  Set
                </button>
              </div>
            </div>
          ) : (
            <h2 className="ms-2">Show all is checked</h2>
          )}
        </div>
      </div>
    );
  } else {
    <WorkInProgress />;
  }
};

export default ApplicationSet;
