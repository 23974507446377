import React, { useContext } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import img from "../ppp.png";
import { MarketContext } from "../Context";

export default function LogoutModal({ modalIsOpen, closeModal, username }) {
  const { tokenName } = useContext(MarketContext);
  const navigate = useNavigate();
  const logOut = () => {
    localStorage.removeItem(tokenName);
    localStorage.removeItem("userDetails");
    navigate("/login");
  };

  const customStyles = {
    content: {
      zIndex: 102,
      width: "320px",
      height: "280px",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "11px",
      background: "#282a3a",
    },
  };
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      ariaHideApp={false}
    >
      <div
        className="profile_img text-center p4"
        style={{ color: "white", textAlign: "center" }}
      >
        <div>
          <img className="imgggg" src={img} />
        </div>

        <p
          className="text-capitalize"
          style={{
            fontSize: "20px",
            color: "white",
            textAlign: "center",
            marginTop: "4px",
          }}
        >
          {username}
        </p>
      </div>
      <div className="d-flex flex-column gap-2">
        <button className="btn btn-danger " onClick={() => logOut()}>
          Logout
        </button>
        <button className="btn btn-primary" onClick={closeModal}>
          Cancel
        </button>
      </div>
    </Modal>
  );
}
