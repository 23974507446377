import React, { useContext, useEffect, useState } from "react";
import NewFilterCompo from "./NewFilterCompo";
import "../css/FormCSS.css";
import MultipleSelectComp from "./MultipleSelectComp";
import { MarketContext } from "../Context";
import { UncontrolledTooltip } from "reactstrap";
import AlertCompo from "../small_compo/AlertCompo";
import CheckMandatoryField from "./CheckMandatoryField";
import FormLabels from "../small_compo/FormLabels";
import { Modal } from "react-bootstrap";
import { Editor } from "@monaco-editor/react";

const FormInnerCompo = ({
  obj,
  m_rid,
  setContextMenu,
  formChangefn,
  validationfn,
  passwordReadOnly,
  tableName,
  afterCellEdit,
  x,
  y,
  onSelection,
  clickRefrence,
  getSingleInfo,
  refrecord,
  showContainer,
  setRefRcd,
  showlist,
  col_mn,
  callfilter,
  setFiltArray,
  timeline,
  calltimeline,
  callScript,
  reScript,
  JoditEditor,
  editor,
  choice_mn,
  setMSC,
  handleFileChange,
  deleteImage,
  launchChoose,
  verifyError,
  groupkeyValue,
  setGroupKeyValue,
  loca,
  col_depend,
  deftimeline,
  dcFiltVal,
  record,
  setRecord,
  isClick,
  page_error,
  handlePdfFileChange,
}) => {
  const { userDetails, imageloca, formHeight } = useContext(MarketContext);
  const handleAddParentInput = () => {
    let t = {
      name: "",
      choice: [{ value: "" }],
    };
    let properties = groupkeyValue.properties;
    properties.push(t);
    setGroupKeyValue({ properties });
  };

  const handleAddChildInput = (parentIndex) => {
    let t = { value: "" };
    let childProps = groupkeyValue.properties[parentIndex].choice;
    childProps.push(t);
    groupkeyValue.properties[parentIndex].choice = childProps;
    setGroupKeyValue({ properties: groupkeyValue.properties });
  };

  const handleRemoveParentInput = (parentIndex) => {
    let array = groupkeyValue.properties;
    if (parentIndex !== -1) {
      array.splice(parentIndex, 1);
    }
    let prp = {};
    prp.properties = array;
    setGroupKeyValue(prp);
  };

  const handleRemoveChildInput = (parentIndex, childindex) => {
    let array = groupkeyValue.properties[parentIndex].choice;
    if (childindex !== -1) {
      array.splice(childindex, 1);
    }
    groupkeyValue.properties[parentIndex].choice = array;
    setGroupKeyValue({ properties: groupkeyValue.properties });
  };

  useEffect(() => {
    if (obj.type === "image_upload") {
      obj.localimg = false;
    }
    if (obj.type === "group_key_value") {
      setGroupKeyValue(obj.value);
    }
  }, [userDetails, obj]);
  if (obj.type === "reference") {
    if (obj.value.name === "null" || obj.value.name === null) {
      obj.value.name = "";
    }
  } else {
    if (obj.value === "null" || obj.value === null) {
      obj.value = "";
    }
  }
  const [showModal, setShowModal] = useState(false);
  const [imgURL, setImgURL] = useState("");

  const addInput = (obj) => {
    let t = {};
    let properties = JSON.parse(JSON.stringify(obj.value));
    properties.push(t);
    obj.value.push(t);
    setRecord([...record]);
  };

  const removeInput = (index, obj) => {
    let array = JSON.parse(JSON.stringify(obj.value));
    if (index !== -1) {
      array.splice(index, 1);
    }
    obj.value = array;
    setRecord([...record]);
  };
  const [editorHeight, setEditorHeight] = useState("15vh");

  const handleExpandClick = () => {
    // Toggle between expanded and normal height
    const newHeight = editorHeight === "15vh" ? "100vh" : "15vh";
    setEditorHeight(newHeight);
  };

  const removePdf = (obj) => {
    obj.value = "";
    setRecord([...record]);
  };
  return (
    <div>
      {obj.uivalid.visible === "true" && (
        <div className="  inppd " key={obj.name}>
          {obj.type === "String" ? (
            <div
              className=" form-group objpdg "
              style={{ width: obj.width === "50%" ? "50%" : "100%" }}
            >
              <FormLabels
                obj={obj}
                setContextMenu={setContextMenu}
                m_rid={m_rid}
              />
              {obj.verified === "unverified" && (
                <AlertCompo type={"danger"} msg={verifyError} />
              )}
              <input
                type="text"
                className={
                  obj.verified === "unverified"
                    ? "form-control formpadd_danger unverifi"
                    : "form-control formpadd "
                }
                value={obj.value}
                disabled={obj.uivalid.read_only === "true"}
                maxLength={obj.uivalid.max_length}
                onChange={(e) =>
                  formChangefn(
                    e.target.value,
                    obj.index,
                    obj.name,
                    obj.type,
                    obj.id,
                    false,
                    obj
                  )
                }
                onBlur={(e) =>
                  validationfn(
                    e.target.value,
                    obj.index,
                    obj.name,
                    obj.type,
                    obj.id
                  )
                }
              ></input>
            </div>
          ) : null}
          {obj.type === "email" ? (
            <div
              className=" form-group objpdg "
              style={{ width: obj.width === "50%" ? "50%" : "100%" }}
            >
              <FormLabels
                obj={obj}
                setContextMenu={setContextMenu}
                m_rid={m_rid}
              />

              {obj.verified === "unverified" && (
                <AlertCompo type={"danger"} msg={"Please verify your email!"} />
              )}
              <input
                type="text"
                className={
                  obj.verified === "unverified"
                    ? "form-control formpadd_danger unverifi"
                    : "form-control formpadd "
                }
                value={obj.value}
                disabled={obj.uivalid.read_only === "true"}
                placeholder="eg:abc@gmail.com"
                maxLength={obj.uivalid.max_length}
                onChange={(e) =>
                  formChangefn(
                    e.target.value,
                    obj.index,
                    obj.name,
                    obj.type,
                    obj.id,
                    false,
                    obj
                  )
                }
                onBlur={(e) =>
                  validationfn(
                    e.target.value,
                    obj.index,
                    obj.name,
                    obj.type,
                    obj.id
                  )
                }
              ></input>
            </div>
          ) : null}
          {obj.type === "JSONObject" ? (
            <div className=" form-group objpdg ">
              <FormLabels
                obj={obj}
                setContextMenu={setContextMenu}
                m_rid={m_rid}
              />

              {obj.verified === "unverified" && (
                <AlertCompo type={"danger"} msg={verifyError} />
              )}
              <textarea
                type="text"
                className={
                  obj.verified === "unverified"
                    ? "form-control formpadd_danger unverifi"
                    : "form-control formpadd "
                }
                value={obj.value}
                readOnly={obj.uivalid.read_only === "true"}
                maxLength={obj.uivalid.max_length}
                onChange={(e) =>
                  formChangefn(
                    e.target.value,
                    obj.index,
                    obj.name,
                    obj.type,
                    obj.id,
                    false,
                    obj
                  )
                }
                onBlur={(e) =>
                  validationfn(
                    e.target.value,
                    obj.index,
                    obj.name,
                    obj.type,
                    obj.id
                  )
                }
              ></textarea>
            </div>
          ) : null}
          {obj.type === "password" ? (
            <div className=" form-group objpdg ">
              <FormLabels
                obj={obj}
                setContextMenu={setContextMenu}
                m_rid={m_rid}
              />
              {obj.verified === "unverified" && (
                <AlertCompo
                  type={"danger"}
                  msg={" Please verify your charecter not accept number!"}
                />
              )}
              <input
                type="password"
                className={
                  obj.verified === "unverified"
                    ? "form-control formpadd_danger unverifi"
                    : "form-control formpadd  "
                }
                value={obj.value}
                disabled={
                  passwordReadOnly ? true : obj.uivalid.read_only === "true"
                }
                maxLength={obj.uivalid.max_length}
                onChange={(e) =>
                  formChangefn(
                    e.target.value,
                    obj.index,
                    obj.name,
                    obj.type,
                    obj.id,
                    false,
                    obj
                  )
                }
                onBlur={(e) =>
                  validationfn(
                    e.target.value,
                    obj.index,
                    obj.name,
                    obj.type,
                    obj.id
                  )
                }
              ></input>
            </div>
          ) : null}
          {obj.type === "script" ? (
            <div className=" form-group objpdg ">
              <FormLabels
                obj={obj}
                setContextMenu={setContextMenu}
                m_rid={m_rid}
              />

              {obj.verified === "unverified" && (
                <AlertCompo
                  type={"danger"}
                  msg={" Please verify your character not accept number!"}
                />
              )}
              <div>
                <div>
                  <i
                    className="fa-solid fa-expand"
                    onClick={handleExpandClick}
                    style={{ cursor: "pointer" }}
                  ></i>
                </div>

                {editorHeight === "15vh" ? (
                  <div style={{ border: "2px solid black", height: "15vh" }}>
                    <Editor
                      // width="99vw"
                      // height='100vh'
                      language="javascript"
                      theme="vs-dark"
                      value={obj.value}
                      onChange={(e, i) => {
                        formChangefn(
                          e,
                          obj.index,
                          obj.name,
                          obj.type,
                          obj.id,
                          false,
                          obj
                        );
                      }}
                      options={{
                        codeLens: false,
                        inlineSuggest: true,
                        fontSize: "16px",
                        formatOnType: true,
                        minimap: {
                          enabled: false,
                          showSlider: "always",
                        },
                      }}
                      onBlur={(e) => {
                        afterCellEdit(e.target.value, obj.name);
                      }}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      border: "2px solid black",
                      background: "white",
                      height: formHeight,
                      width: "100%",
                      position: "absolute",
                      top: "0",
                      left: "0",
                      zIndex: 1050,
                    }}
                  >
                    <div style={{ marginLeft: "15px", marginTop: "10px" }}>
                      <i
                        className="fa-solid fa-compress"
                        style={{ cursor: "pointer" }}
                        onClick={handleExpandClick}
                      ></i>
                    </div>
                    <Editor
                      language="javascript"
                      theme="vs-dark"
                      value={obj.value}
                      onChange={(e, i) => {
                        formChangefn(
                          e,
                          obj.index,
                          obj.name,
                          obj.type,
                          obj.id,
                          false,
                          obj
                        );
                      }}
                      options={{
                        codeLens: false,
                        inlineSuggest: true,
                        fontSize: "16px",
                        formatOnType: true,
                        minimap: {
                          enabled: false,
                          showSlider: "always",
                        },
                      }}
                      onBlur={(e) => {
                        afterCellEdit(e.target.value, obj.name);
                      }}
                    />
                  </div>
                )}
                <div
                  id="suggetion"
                  style={{
                    position: "absolute",
                    left: x + 22,
                    top: y + 25,
                  }}
                  onFocus={() => {
                    let d = document.getElementById("suggetion");
                    d.style.background = "blue";
                    d.style.color = "white";
                  }}
                ></div>
              </div>
            </div>
          ) : null}
          {obj.type === "multi_select" ? (
            <div className="form-group ">
              {obj.uivalid.mandatory === "true" &&
                obj.value.record.length !== 0 && (
                  <i className="fa fa-asterisk mndtryfalse"></i>
                )}

              {obj.uivalid.mandatory === "true" &&
                obj.value.record.length === 0 && (
                  <i className="fa fa-asterisk mndtrytrue"></i>
                )}
              <span
                id={"tiptar" + obj.index}
                className="field_hd"
                onContextMenu={(e) => {
                  e.preventDefault();
                  m_rid.current = obj.id;
                  setContextMenu(
                    e.button,
                    e.nativeEvent.pageX,
                    e.nativeEvent.pageY
                  );
                }}
              >
                {obj.label.name}
              </span>
              {obj.label.showTooltip === "true" && (
                <UncontrolledTooltip
                  arrowClassName="tip  "
                  className="tip "
                  innerClassName="tip text-dark text-nowrap"
                  target={"tiptar" + obj.index}
                  placement="right"
                >
                  <pre className="form-pre-tooltip">{obj.label.tooltip}</pre>
                </UncontrolledTooltip>
              )}
              {obj.label.showHelper === "true" && (
                <div className="my-2">
                  <AlertCompo
                    type={"warning"}
                    msg={
                      <pre className="form-pre-helper">{obj.label.helper}</pre>
                    }
                  />
                </div>
              )}
              <div>
                <MultipleSelectComp
                  list={{ ...obj.value }}
                  id={obj.id}
                  ind={obj.index}
                  loca={loca}
                  setMSC={(val) => {
                    setMSC(val, obj.index, obj.name, obj.type, obj.id);
                  }}
                  record={record}
                  type={obj.type}
                  tabname={obj.label}
                  dep_choice={obj.dep_choice}
                />
              </div>
            </div>
          ) : null}
          {obj.type === "int" || obj.type === "long" ? (
            <div
              className="form-group"
              style={{ width: obj.width === "50%" ? "50%" : "100%" }}
            >
              <FormLabels
                obj={obj}
                setContextMenu={setContextMenu}
                m_rid={m_rid}
              />

              {obj.verified === "unverified" && (
                <AlertCompo
                  type={"danger"}
                  msg={"Please verify your integer number!"}
                />
              )}

              <input
                type="text"
                className={
                  obj.verified === "unverified"
                    ? "form-control formpadd_danger unverifi"
                    : "form-control formpadd "
                }
                value={obj.value}
                disabled={obj.uivalid.read_only === "true"}
                maxLength={obj.uivalid.max_length}
                onChange={(e) =>
                  formChangefn(
                    e.target.value,
                    obj.index,
                    obj.name,
                    obj.type,
                    obj.id,
                    false,
                    obj
                  )
                }
                onBlur={(e) =>
                  validationfn(
                    e.target.value,
                    obj.index,
                    obj.name,
                    obj.type,
                    obj.id
                  )
                }
              ></input>
            </div>
          ) : null}
          {obj.type === "date" ? (
            <div className=" form-group">
              <FormLabels
                obj={obj}
                setContextMenu={setContextMenu}
                m_rid={m_rid}
              />

              {obj.verified === "unverified" && (
                <AlertCompo type={"danger"} msg={"Please verify your date!"} />
              )}
              <input
                style={{ width: "10em" }}
                id="dt"
                type="date"
                className={
                  obj.verified === "unverified"
                    ? "form-control objmargin unverifi"
                    : " form-control objmargin "
                }
                value={obj.value}
                readOnly={obj.uivalid.read_only === "true"}
                maxLength={obj.uivalid.max_length}
                onChange={(e) => {
                  formChangefn(
                    e.target.value,
                    obj.index,
                    obj.name,
                    obj.type,
                    obj.id,
                    false,
                    obj
                  );
                }}
                onBlur={(e) =>
                  validationfn(
                    e.target.value,
                    obj.index,
                    obj.name,
                    obj.type,
                    obj.id
                  )
                }
              ></input>
            </div>
          ) : null}
          {obj.type === "time" ? (
            <div className=" form-group">
              <FormLabels
                obj={obj}
                setContextMenu={setContextMenu}
                m_rid={m_rid}
              />

              {obj.verified === "unverified" && (
                <AlertCompo type={"danger"} msg={"Please verify your time!"} />
              )}
              <input
                type="time"
                step="1"
                className={
                  obj.verified === "unverified"
                    ? "objmargin unverifi"
                    : "objmargin "
                }
                value={obj.value}
                readOnly={obj.uivalid.read_only === "true"}
                maxLength={obj.uivalid.max_length}
                onChange={(e) =>
                  formChangefn(
                    e.target.value,
                    obj.index,
                    obj.name,
                    obj.type,
                    obj.id,
                    false
                  )
                }
                onBlur={(e) =>
                  validationfn(
                    e.target.value,
                    obj.index,
                    obj.name,
                    obj.type,
                    obj.id
                  )
                }
              ></input>
            </div>
          ) : null}
          {obj.type === "datetime" ? (
            <div className=" form-group">
              <FormLabels
                obj={obj}
                setContextMenu={setContextMenu}
                m_rid={m_rid}
              />

              {obj.verified === "unverified" && (
                <AlertCompo type={"danger"} msg={"Please verify your date!"} />
              )}

              <input
                type="datetime-local"
                step={1}
                style={{ width: "40%" }}
                className={
                  obj.verified === "unverified"
                    ? "form-control objmargin unverifi"
                    : "form-control objmargin "
                }
                value={obj.value}
                readOnly={obj.uivalid.read_only === "true"}
                maxLength={obj.uivalid.max_length}
                onChange={(e) =>
                  formChangefn(
                    e.target.value,
                    obj.index,
                    obj.name,
                    obj.type,
                    obj.id,
                    false,
                    obj
                  )
                }
                onBlur={(e) =>
                  validationfn(
                    e.target.value,
                    obj.index,
                    obj.name,
                    obj.type,
                    obj.id
                  )
                }
              ></input>
            </div>
          ) : null}
          {obj.type === "boolean" && obj.name !== "is_reset_password" ? (
            <div className="form-check fmcheck">
              <input
                type="checkbox"
                className={
                  obj.verified === "unverified"
                    ? "checkpadd unverifi"
                    : "checkpadd"
                }
                checked={obj.value === "true" ? true : false}
                disabled={obj.uivalid.read_only === "true"}
                onChange={(e) => {
                  formChangefn(
                    e.target.checked.toString(),
                    obj.index,
                    obj.name,
                    obj.type,
                    obj.id,
                    false,
                    obj
                  );
                }}
                onBlur={(e) =>
                  validationfn(
                    e.target.value,
                    obj.index,
                    obj.name,
                    obj.type,
                    obj.id
                  )
                }
              ></input>
              <span
                className="field_hd"
                onContextMenu={(e) => {
                  e.preventDefault();
                  m_rid.current = obj.id;
                  setContextMenu(
                    e.button,
                    e.nativeEvent.pageX,
                    e.nativeEvent.pageY
                  );
                }}
              >
                {obj.label.name}
              </span>
              {obj.label.showTooltip === "true" && (
                <UncontrolledTooltip
                  arrowClassName="tip  "
                  className="tip "
                  innerClassName="tip text-dark text-nowrap"
                  target={"tiptar" + obj.index}
                  placement="right"
                >
                  <pre className="form-pre-tooltip">{obj.label.tooltip}</pre>
                </UncontrolledTooltip>
              )}
            </div>
          ) : null}
          {obj.type === "boolean" && obj.name === "is_reset_password" ? (
            <div className="form-check fmcheck">
              <input
                type="checkbox"
                className={
                  obj.verified === "unverified"
                    ? "checkpadd unverifi"
                    : "checkpadd"
                }
                checked={
                  passwordReadOnly ? true : obj.value === "true" ? true : false
                }
                disabled={obj.uivalid.read_only === "true"}
                onChange={(e) => {
                  if (!passwordReadOnly) {
                    formChangefn(
                      e.target.checked.toString(),
                      obj.index,
                      obj.name,
                      obj.type,
                      obj.id,
                      false,
                      obj
                    );
                  }
                }}
                onBlur={(e) =>
                  validationfn(
                    e.target.value,
                    obj.index,
                    obj.name,
                    obj.type,
                    obj.id
                  )
                }
              ></input>
              <span
                className="field_hd"
                onContextMenu={(e) => {
                  e.preventDefault();
                  m_rid.current = obj.id;
                  setContextMenu(
                    e.button,
                    e.nativeEvent.pageX,
                    e.nativeEvent.pageY
                  );
                }}
              >
                {obj.label.name}
              </span>
              {obj.label.showTooltip === "true" && (
                <UncontrolledTooltip
                  arrowClassName="tip  "
                  className="tip "
                  innerClassName="tip text-dark text-nowrap"
                  target={"tiptar" + obj.index}
                  placement="right"
                >
                  <pre className="form-pre-tooltip">{obj.label.tooltip}</pre>
                </UncontrolledTooltip>
              )}
            </div>
          ) : null}
          {obj.type === "reference" ? (
            <div style={{ width: obj.width === "50%" ? "50%" : "100%" }}>
              <div className="form-group">
                {obj.uivalid.mandatory === "true" && obj.value.name !== "" && (
                  <i className="fa fa-asterisk mndtryfalse"></i>
                )}
                {obj.uivalid.mandatory === "true" && obj.value.name === "" && (
                  <i className="fa fa-asterisk mndtrytrue"></i>
                )}

                {obj.uivalid.mandatory === "true" &&
                  obj.uivalid.group_mandatory === "true" &&
                  obj.value.name === "" && (
                    <i className="fa fa-asterisk mndtrygptrue"></i>
                  )}
                <span
                  className="field_hd"
                  onContextMenu={(e) => {
                    e.preventDefault();
                    m_rid.current = obj.id;
                    setContextMenu(
                      e.button,
                      e.nativeEvent.pageX,
                      e.nativeEvent.pageY
                    );
                  }}
                >
                  {obj.label.name}
                </span>
                {obj.label.showTooltip === "true" && (
                  <UncontrolledTooltip
                    arrowClassName="tip  "
                    className="tip "
                    innerClassName="tip text-dark text-nowrap"
                    target={"tiptar" + obj.index}
                    placement="right"
                  >
                    <pre className="form-pre-tooltip">{obj.label.tooltip}</pre>
                  </UncontrolledTooltip>
                )}

                {obj.label.showHelper === "true" && (
                  <div className="my-2">
                    <AlertCompo
                      type={"warning"}
                      msg={
                        <pre className="form-pre-helper">
                          {obj.label.helper}
                        </pre>
                      }
                    />
                  </div>
                )}
                {obj.verified === "unverified" && (
                  <AlertCompo type={"danger"} msg={"Record not found!"} />
                )}
                <div style={{ display: "flex" }}>
                  <input
                    type="text"
                    className={
                      obj.verified === "unverified"
                        ? "form-control formpadd formbor ref-inp unverifi"
                        : "form-control formpadd ref-inp formbor"
                    }
                    maxLength={obj.uivalid.max_length}
                    value={obj.value.name}
                    disabled={obj.uivalid.read_only === "true" ? true : false}
                    onChange={(e) => {
                      formChangefn(
                        e.target.value,
                        obj.index,
                        obj.name,
                        obj.type,
                        obj.id,
                        false
                      );
                    }}
                    onBlur={(e) => {
                      setTimeout(() => {
                        if (obj.verified !== "verified") {
                          validationfn(
                            obj.value,
                            obj.index,
                            obj.name,
                            obj.type,
                            obj.id
                          );
                        }
                      }, 500);
                    }}
                  ></input>
                  <div
                    className="btnsrc vlpointer"
                    onClick={(e) => {
                      clickRefrence(
                        e,
                        obj.id,
                        obj.name,
                        obj.type,
                        obj.index,
                        obj.label,
                        obj.uivalid.read_only,
                        false
                      );
                    }}
                  >
                    <i
                      className="fa fa-search"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                    ></i>
                  </div>
                  {obj?.rcd_info === "true" && obj.value.name !== "" && (
                    <div
                      className="btnsrc vlpointer"
                      onClick={(e) => {
                        getSingleInfo(
                          e,
                          obj,
                          obj.id,
                          obj.name,
                          obj.type,
                          obj.index,
                          obj.label.name,
                          obj.uivalid.read_only,
                          obj.depend_column
                        );
                      }}
                    >
                      <i className="fa-solid fa-circle-info"></i>
                    </div>
                  )}
                </div>
              </div>
              {refrecord.index === obj.index &&
                showContainer &&
                refrecord.record.length > 0 && (
                  <div className="sf_container">
                    {refrecord.record.map((obj_ref, or_i) => (
                      <div
                        className="refrcd"
                        onClick={(e) =>
                          setRefRcd(
                            obj_ref.value,
                            obj.index,
                            obj.name,
                            obj.type,
                            obj_ref.id,
                            obj_ref.properties ? obj_ref.properties : ""
                          )
                        }
                        key={or_i}
                      >
                        {obj_ref.value}
                      </div>
                    ))}
                  </div>
                )}
            </div>
          ) : null}
          {obj.type === "filter" ? (
            <div className="form-group objpdg ">
              <span
                className="field_hd"
                onContextMenu={(e) => {
                  e.preventDefault();
                  m_rid.current = obj.id;
                  setContextMenu(
                    e.button,
                    e.nativeEvent.pageX,
                    e.nativeEvent.pageY
                  );
                }}
              >
                {obj.label.name}
              </span>
              {obj.label.showTooltip === "true" && (
                <UncontrolledTooltip
                  arrowClassName="tip  "
                  className="tip "
                  innerClassName="tip text-dark text-nowrap"
                  target={"tiptar" + obj.index}
                  placement="right"
                >
                  <pre className="form-pre-tooltip">{obj.label.tooltip}</pre>
                </UncontrolledTooltip>
              )}

              <div>
                <NewFilterCompo
                  showlist={showlist}
                  col_mn={col_mn}
                  call_fil={(filtarray, in_index, col_id) =>
                    callfilter(filtarray, obj.index, in_index, col_id)
                  }
                  filtarray={obj.value.filter}
                  setFiltArray={setFiltArray}
                  timeline={timeline}
                  setTimeLine={(timeline) => calltimeline(timeline, obj.index)}
                  setScript={(script) => callScript(script, obj.index)}
                  isScript={false}
                  script={reScript}
                  col_depend={col_depend}
                  dcFiltVal={dcFiltVal}
                  deftimeline={deftimeline}
                ></NewFilterCompo>
              </div>
            </div>
          ) : null}
          {obj.type === "condition" ? (
            <div className="form-group objpdg ">
              <span
                className="field_hd"
                onContextMenu={(e) => {
                  e.preventDefault();
                  m_rid.current = obj.id;
                  setContextMenu(
                    e.button,
                    e.nativeEvent.pageX,
                    e.nativeEvent.pageY
                  );
                }}
              >
                {obj.label.name}
              </span>
              {obj.label.showTooltip === "true" && (
                <UncontrolledTooltip
                  arrowClassName="tip  "
                  className="tip "
                  innerClassName="tip text-dark text-nowrap"
                  target={"tiptar" + obj.index}
                  placement="right"
                >
                  <pre className="form-pre-tooltip">{obj.label.tooltip}</pre>
                </UncontrolledTooltip>
              )}
              {obj.label.showHelper === "true" && (
                <div className="my-2">
                  <AlertCompo
                    type={"warning"}
                    msg={
                      <pre className="form-pre-helper">{obj.label.helper}</pre>
                    }
                  />
                </div>
              )}

              <div>
                <NewFilterCompo
                  showlist={showlist}
                  col_mn={col_mn}
                  call_fil={(filtarray, in_index, col_id) =>
                    callfilter(filtarray, obj.index, in_index, col_id)
                  }
                  filtarray={obj.value.filter}
                  setFiltArray={setFiltArray}
                  timeline={timeline}
                  setTimeLine={(timeline) => calltimeline(timeline, obj.index)}
                  setScript={(script) => callScript(script, obj.index)}
                  isScript={false}
                  script={reScript}
                  col_depend={col_depend}
                  dcFiltVal={dcFiltVal}
                  deftimeline={deftimeline}
                ></NewFilterCompo>
              </div>
            </div>
          ) : null}
          {obj.type === "filter_script" ? (
            <div className="form-group objpdg">
              <span
                onContextMenu={(e) => {
                  e.preventDefault();
                  m_rid.current = obj.id;
                  setContextMenu(
                    e.button,
                    e.nativeEvent.pageX,
                    e.nativeEvent.pageY
                  );
                }}
              >
                {obj.label.name}
              </span>
              {obj.label.showTooltip === "true" && (
                <UncontrolledTooltip
                  arrowClassName="tip  "
                  className="tip "
                  innerClassName="tip text-dark text-nowrap"
                  target={"tiptar" + obj.index}
                  placement="right"
                >
                  <pre className="form-pre-tooltip">{obj.label.tooltip}</pre>
                </UncontrolledTooltip>
              )}
              {obj.label.showHelper === "true" && (
                <div className="my-2">
                  <AlertCompo
                    type={"warning"}
                    msg={
                      <pre className="form-pre-helper">{obj.label.helper}</pre>
                    }
                  />
                </div>
              )}
              <div>
                <NewFilterCompo
                  showlist={showlist}
                  col_mn={col_mn}
                  call_fil={(filtarray, in_index, col_id) =>
                    callfilter(filtarray, obj.index, in_index, col_id)
                  }
                  filtarray={obj.value.filter}
                  setFiltArray={setFiltArray}
                  timeline={timeline}
                  setTimeLine={(timeline) => calltimeline(timeline, obj.index)}
                  setScript={(script) => callScript(script, obj.index)}
                  isScript={true}
                  script={reScript}
                  selectedScript={obj.value.script}
                  col_depend={col_depend}
                  dcFiltVal={dcFiltVal}
                  deftimeline={deftimeline}
                ></NewFilterCompo>
              </div>
            </div>
          ) : null}
          {obj.type === "html" ? (
            <div className=" form-group objpdg ">
              <FormLabels
                obj={obj}
                setContextMenu={setContextMenu}
                m_rid={m_rid}
              />
              {obj.verified === "unverified" && (
                <AlertCompo
                  type={"danger"}
                  msg={"Please verify your charecter not accept number!"}
                />
              )}
              <div
                className="row"
                style={{
                  background: "#ffffff",
                  color: "#000",
                }}
              >
                <div className="col-md">
                  <JoditEditor
                    value={obj.value}
                    ref={editor}
                    onChange={(content) => {
                      formChangefn(
                        content,
                        obj.index,
                        obj.name,
                        obj.type,
                        obj.id,
                        false,
                        obj
                      );
                    }}
                  />
                </div>
                <div className=" card col-md-3 side-cont">
                  {col_mn.length > 0 &&
                    col_mn.map((co, ci) => (
                      <div
                        key={ci}
                        className="list-group-item side-list "
                        onClick={() => {
                          let cont = obj.value + " ${" + co.name + "} ";
                          formChangefn(
                            cont,
                            obj.index,
                            obj.name,
                            obj.type,
                            obj.id,
                            false,
                            obj
                          );
                        }}
                      >
                        {co.name}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ) : null}
          {obj.type === "table_lookup" ? (
            <div className="form-check fmcheck">
              <FormLabels
                obj={obj}
                setContextMenu={setContextMenu}
                m_rid={m_rid}
              />

              <select
                className="form-control form-select formpadd "
                aria-label="Default select example"
                value={obj.value}
                onChange={(e) => {
                  onSelection(e.target.value);
                  formChangefn(
                    e.target.checked,
                    obj.index,
                    obj.name,
                    obj.type,
                    obj.id,
                    false,
                    obj
                  );
                }}
                maxLength={obj.uivalid.max_length}
                readOnly={obj.uivalid.read_only === "true"}
              >
                <option value="None">None</option>

                {obj.lookup.map((ch, chi) => (
                  <option key={chi} value={ch.name}>
                    {ch.name}
                  </option>
                ))}
              </select>
            </div>
          ) : null}
          {obj.type === "depend_table" && (
            <div className="form-group ">
              {obj.uivalid.mandatory === "true" &&
                obj.value.name !== "None" &&
                obj.value.name !== "" && (
                  <i className="fa fa-asterisk mndtryfalse"></i>
                )}

              {obj.uivalid.mandatory === "true" &&
                obj.value.name === "" &&
                obj.uivalid.group_mandatory === "false" && (
                  <i className="fa fa-asterisk mndtrytrue"></i>
                )}
              {obj.uivalid.mandatory === "true" &&
                obj.uivalid.group_mandatory === "true" &&
                obj.value.name === "" && (
                  <i className="fa fa-asterisk mndtrygptrue"></i>
                )}
              <span
                className="field_hd"
                onContextMenu={(e) => {
                  e.preventDefault();
                  m_rid.current = obj.id;
                  setContextMenu(
                    e.button,
                    e.nativeEvent.pageX,
                    e.nativeEvent.pageY
                  );
                }}
              >
                {obj.label.name}
              </span>

              {obj.label.showTooltip === "true" && (
                <UncontrolledTooltip
                  arrowClassName="tip  "
                  className="tip "
                  innerClassName="tip text-dark text-nowrap"
                  target={"tiptar" + obj.index}
                  placement="right"
                >
                  <pre className="form-pre-tooltip">{obj.label.tooltip}</pre>
                </UncontrolledTooltip>
              )}
              {obj.label.showHelper === "true" && (
                <div className="my-2">
                  <AlertCompo
                    type={"warning"}
                    msg={
                      <pre className="form-pre-helper">{obj.label.helper}</pre>
                    }
                  />
                </div>
              )}
              <div className="d-flex">
                <select
                  className="form-control form-select formpadd "
                  aria-label="Default select example"
                  value={obj.value.id}
                  onChange={(e) => {
                    let id = 0;
                    let name = "";
                    for (let i = 0; i < choice_mn.length; i++) {
                      if (choice_mn[i].id === e.target.value) {
                        id = choice_mn[i].id;
                        name = choice_mn[i].name;
                      }
                    }
                    formChangefn(
                      name,
                      obj.index,
                      obj.name,
                      obj.type,
                      id,
                      false,
                      obj
                    );
                  }}
                  maxLength={obj.uivalid.max_length}
                  disabled={obj.uivalid.read_only === "true"}
                >
                  <option value="">None</option>

                  {choice_mn.map((ch, chi) => (
                    <option key={chi} label={ch.name} value={ch.id}>
                      {ch.name}
                    </option>
                  ))}
                </select>
                {obj?.rcd_info === "true" && (
                  <div
                    className="btnsrc vlpointer"
                    onClick={(e) => {
                      getSingleInfo(
                        e,
                        obj,
                        obj.id,
                        obj.name,
                        obj.type,
                        obj.index,
                        obj.label.name,
                        obj.uivalid.read_only,
                        obj.depend_column
                      );
                    }}
                    style={{ marginLeft: "2px" }}
                  >
                    <i className="fa-solid fa-circle-info"></i>
                  </div>
                )}
              </div>
            </div>
          )}
          {obj.type === "choice" && (
            <div className="form-group ">
              {obj.uivalid.mandatory === "true" &&
                obj.value !== "None" &&
                obj.value !== "none" &&
                obj.value !== "" && (
                  <i className="fa fa-asterisk mndtryfalse"></i>
                )}
                {console.log(obj)}
                
              {obj.uivalid.mandatory === "true" &&
                (obj.value === "None"||obj.value === "none" || obj.value === "") && (
                  <i className="fa fa-asterisk mndtrytrue"></i>
                )}
              <span
                className="field_hd"
                onContextMenu={(e) => {
                  e.preventDefault();
                  m_rid.current = obj.id;
                  setContextMenu(
                    e.button,
                    e.nativeEvent.pageX,
                    e.nativeEvent.pageY
                  );
                }}
              >
                {obj.label.name}
              </span>
              {obj.label.showTooltip === "true" && (
                <UncontrolledTooltip
                  arrowClassName="tip  "
                  className="tip "
                  innerClassName="tip text-dark text-nowrap"
                  target={"tiptar" + obj.index}
                  placement="right"
                >
                  <pre className="form-pre-tooltip">{obj.label.tooltip}</pre>
                </UncontrolledTooltip>
              )}
              {obj.label.showHelper === "true" && (
                <div className="my-2">
                  <AlertCompo
                    type={"warning"}
                    msg={
                      <pre className="form-pre-helper">{obj.label.helper}</pre>
                    }
                  />
                </div>
              )}

              <select
                className="form-control form-select formpadd "
                aria-label="Default select example"
                value={obj.value}
                onChange={(e) => {
                  onSelection(e.target.value);
                  formChangefn(
                    e.target.value,
                    obj.index,
                    obj.name,
                    obj.type,
                    obj.id,
                    false,
                    obj
                  );
                }}
                maxLength={obj.uivalid.max_length}
                disabled={obj.uivalid.read_only === "true"}
              >
                <option value="none">
                  {/* {obj.value === "" ? "None" : obj.value} */}
                  None
                </option>

                {obj.choice.map((ch, chi) => (
                  <option key={chi} value={ch.value}>
                    {ch.label}
                  </option>
                ))}
              </select>
            </div>
          )}
          {obj.type === "long_description" || obj.type === "note" ? (
            <div className=" form-group objpdg ">
              <FormLabels
                obj={obj}
                setContextMenu={setContextMenu}
                m_rid={m_rid}
              />
              {obj.verified === "unverified" && (
                <AlertCompo
                  type={"danger"}
                  msg={"Please verify your character not accept number!"}
                />
              )}
              <textarea
                type="text"
                className={
                  obj.verified === "unverified"
                    ? "form-control formpadd_danger unverifi"
                    : "form-control formpadd "
                }
                value={obj.value}
                readOnly={obj.uivalid.read_only === "true"}
                maxLength={obj.uivalid.max_length}
                onChange={(e) =>
                  formChangefn(
                    e.target.value,
                    obj.index,
                    obj.name,
                    obj.type,
                    obj.id,
                    false,
                    obj
                  )
                }
                onBlur={(e) =>
                  validationfn(
                    e.target.value,
                    obj.index,
                    obj.name,
                    obj.type,
                    obj.id
                  )
                }
              ></textarea>
            </div>
          ) : null}
          {obj.type === "short_description" ? (
            <div className=" form-group objpdg ">
              <FormLabels
                obj={obj}
                setContextMenu={setContextMenu}
                m_rid={m_rid}
              />
              {obj.verified === "unverified" && (
                <AlertCompo
                  type={"danger"}
                  msg={"Please verify your character not accept number!"}
                />
              )}
              <input
                type="text"
                className={
                  obj.verified === "unverified"
                    ? "form-control formpadd_danger unverifi"
                    : "form-control formpadd "
                }
                value={obj.value}
                readOnly={obj.uivalid.read_only === "true"}
                maxLength={obj.uivalid.max_length}
                onChange={(e) =>
                  formChangefn(
                    e.target.value,
                    obj.index,
                    obj.name,
                    obj.type,
                    obj.id,
                    false,
                    obj
                  )
                }
                onBlur={(e) =>
                  validationfn(
                    e.target.value,
                    obj.index,
                    obj.name,
                    obj.type,
                    obj.id
                  )
                }
              ></input>
            </div>
          ) : null}
          {obj.type === "image_upload" ? (
            <div className=" form-group objpdg">
              <FormLabels
                obj={obj}
                setContextMenu={setContextMenu}
                m_rid={m_rid}
              />

              {obj.verified === "unverified" && (
                <AlertCompo
                  type={"danger"}
                  msg={"Input file is not an image!"}
                />
              )}
              <div className="my-2">
                {obj.value !== "" ? (
                  <div className="saved-imgs">
                    <div className="rr-img-div">
                      {obj.uivalid.read_only === "false" && (
                        <div
                          className="cancel-mark-div"
                          onClick={() => {
                            deleteImage(obj);
                          }}
                        >
                          <i
                            className="fa-solid fa-xmark fa-lg"
                            style={{ color: "red", cursor: "pointer" }}
                          ></i>
                        </div>
                      )}
                      <img
                        onClick={() => {
                          setImgURL(
                            obj.localimg ? obj.value : imageloca + obj.value
                          );
                          setShowModal(true);
                        }}
                        className="user-feedback-img cursor-pointer"
                        src={obj.localimg ? obj.value : imageloca + obj.value}
                      />
                    </div>
                  </div>
                ) : (
                  <div
                    id="add-image-for-preview"
                    className={`add-img-div rr-img-div ${
                      obj.uivalid.read_only === "false"
                        ? "cursor-pointer"
                        : "cursor-none"
                    }`}
                    onClick={() => {
                      if (obj.uivalid.read_only === "false") {
                        launchChoose(`${obj.index}imgInp`);
                      }
                    }}
                  >
                    <div className="my-2">
                      <i className="fa-solid fa-plus fa-2xl"></i>
                    </div>
                    <div>Add Image</div>
                    <input
                      className="ourCustomized"
                      id={`${obj.index}imgInp`}
                      type="file"
                      accept=".jpg, .jpeg,.webp"
                      onChange={(e) => {
                        // console.log(obj);
                        handleFileChange(e, obj);
                      }}
                    />
                  </div>
                )}
              </div>
              <Modal
                show={showModal}
                onHide={() => setShowModal(false)}
                centered
              >
                <Modal.Header className="border-0 pb-0" closeButton />
                <Modal.Body>
                  <div className="d-flex justify-content-center">
                    <img
                      src={imgURL}
                      style={{
                        width: "500px",
                        height: "500px",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          ) : null}
          {obj.type === "upload_pdf" ? (
            <div className=" form-group objpdg">
              <FormLabels
                obj={obj}
                setContextMenu={setContextMenu}
                m_rid={m_rid}
              />

              {obj.verified === "unverified" && (
                <AlertCompo
                  type={"danger"}
                  msg={"Input file is not an image!"}
                />
              )}
              <div className="my-2 border p-2 d-flex flex-column gap-2">
                {obj.value ? (
                  <label style={{ position: "relative" }}>
                    <div
                      // style={{ position: "absolute" }}
                      className="cancel-mark-div"
                      onClick={() => {
                        removePdf(obj);
                      }}
                    >
                      <i
                        className="fa-solid fa-xmark fa-lg"
                        style={{ color: "red", cursor: "pointer" }}
                      ></i>
                    </div>
                    <span className="fw-bold">Uploaded File : </span>
                    {obj.value.split("/")[obj.value.split("/").length - 1]}
                  </label>
                ) : (
                  <input
                    onChange={(e) => handlePdfFileChange(e, obj)}
                    type="file"
                    accept=".pdf"
                    name={obj.value}
                  />
                )}
              </div>
            </div>
          ) : null}
          {obj.type === "group_key_value" ? (
            <div className="form-group objpdg">
              {obj.uivalid.mandatory === "true" &&
                obj.value.properties
                  .map((obj) => [
                    obj.name,
                    ...obj.choice.map((choice) => choice.value),
                  ])
                  .flat()
                  .some((value) => value === "") && (
                  <i className="fa fa-asterisk mndtrytrue"></i>
                )}
              {obj.uivalid.mandatory === "true" &&
                !obj.value.properties
                  .map((obj) => [
                    obj.name,
                    ...obj.choice.map((choice) => choice.value),
                  ])
                  .flat()
                  .some((value) => value === "") && (
                  <i className="fa fa-asterisk mndtryfalse"></i>
                )}
              <span
                id={"tiptar" + obj.index}
                className="field_hd"
                onContextMenu={(e) => {
                  e.preventDefault();
                  m_rid.current = obj.id;
                  setContextMenu(
                    e.button,
                    e.nativeEvent.pageX,
                    e.nativeEvent.pageY
                  );
                }}
              >
                {obj.label.name}
              </span>
              {obj.label.showTooltip === "true" && (
                <UncontrolledTooltip
                  arrowClassName="tip"
                  className="tip"
                  innerClassName="tip text-dark text-nowrap"
                  target={"tiptar" + obj.index}
                  placement="right"
                >
                  <pre className="form-pre-tooltip">{obj.label.tooltip}</pre>
                </UncontrolledTooltip>
              )}
              {obj.label.showHelper === "true" && (
                <div className="my-2">
                  <AlertCompo
                    type={"warning"}
                    msg={
                      <pre className="form-pre-helper">{obj.label.helper}</pre>
                    }
                  />
                </div>
              )}

              {obj.label.showTooltip === "true" && (
                <UncontrolledTooltip
                  arrowClassName="tip  "
                  className="tip "
                  innerClassName="tip text-dark text-nowrap"
                  target={"tiptar" + obj.index}
                  placement="right"
                >
                  <pre className="form-pre-tooltip">{obj.label.tooltip}</pre>
                </UncontrolledTooltip>
              )}
              {obj.label.showHelper === "true" && (
                <div className="my-2">
                  <AlertCompo
                    type={"warning"}
                    msg={
                      <pre className="form-pre-helper">{obj.label.helper}</pre>
                    }
                  />
                </div>
              )}

              <div style={{ padding: "2px 15px" }} className="row gap-2">
                {obj.value.properties.map((parent, parentIndex) => (
                  <div
                    key={parentIndex}
                    className="d-flex flex-column col-md-3 border pt-1 p-2 rounded"
                    style={{
                      background: "aliceblue",
                      width: "300px",
                    }}
                  >
                    <label className="me-2">Name:</label>
                    <div className="d-flex flex-column align-items-center ">
                      <div className="d-flex align-items-center w-100">
                        <input
                          style={{
                            border: "1px solid black",
                          }}
                          type="text"
                          name="child"
                          className="form-control formpadd me-2 my-1"
                          value={parent.name}
                          disabled={obj.uivalid.read_only === "true"}
                          maxLength={obj.uivalid.max_length}
                          onChange={(e) =>
                            formChangefn(
                              e.target.value,
                              obj.index,
                              obj.name,
                              obj.type,
                              obj.id,
                              false,
                              obj,
                              parentIndex,
                              null,
                              "name"
                            )
                          }
                          onBlur={(e) =>
                            validationfn(
                              e.target.value,
                              obj.index,
                              obj.name,
                              obj.type,
                              obj.id
                            )
                          }
                        />
                        {obj.uivalid.read_only !== "true" && (
                          <>
                            {parentIndex === 0 ? (
                              <i
                                className="fa-solid fa-plus fa-xl cursor-pointer"
                                onClick={() => handleAddParentInput()}
                              ></i>
                            ) : (
                              <i
                                className="fa-solid fa-minus fa-xl cursor-pointer"
                                onClick={() =>
                                  handleRemoveParentInput(parentIndex)
                                }
                              ></i>
                            )}
                          </>
                        )}
                      </div>
                      <div className="d-flex flex-column ps-2 pe-1 w-100">
                        <label>Choices:</label>
                        {parent.choice.map((child, childIndex) => (
                          <div
                            key={childIndex}
                            className="d-flex align-items-center"
                          >
                            <input
                              type="text"
                              name="child"
                              className="form-control formpadd me-2 my-1 border border-success"
                              value={child.value}
                              disabled={obj.uivalid.read_only === "true"}
                              maxLength={obj.uivalid.max_length}
                              onChange={(e) =>
                                formChangefn(
                                  e.target.value,
                                  obj.index,
                                  obj.name,
                                  obj.type,
                                  obj.id,
                                  false,
                                  obj,
                                  parentIndex,
                                  childIndex,
                                  "choice"
                                )
                              }
                              onBlur={(e) =>
                                validationfn(
                                  e.target.value,
                                  obj.index,
                                  obj.name,
                                  obj.type,
                                  obj.id
                                )
                              }
                            />
                            {obj.uivalid.read_only !== "true" && (
                              <>
                                {childIndex === 0 ? (
                                  <i
                                    className="fa-solid fa-plus fa-lg cursor-pointer"
                                    onClick={() =>
                                      handleAddChildInput(parentIndex)
                                    }
                                  ></i>
                                ) : (
                                  <i
                                    className="fa-solid fa-minus fa-lg cursor-pointer"
                                    onClick={() =>
                                      handleRemoveChildInput(
                                        parentIndex,
                                        childIndex
                                      )
                                    }
                                  ></i>
                                )}
                              </>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : null}
          {obj.type === "key_value" ? (
            <div style={{ width: obj.width === "50%" ? "50%" : "100%" }}>
              <div className="form-group">
                {obj.uivalid.mandatory === "true" && !obj.selected && (
                  <i className="fa fa-asterisk mndtrytrue"></i>
                )}
                {obj.uivalid.mandatory === "true" &&
                obj.selected &&
                obj.hidden_val &&
                obj.hidden_val.properties.some((obj) => obj.value === "") ? (
                  <i className="fa fa-asterisk mndtrytrue"></i>
                ) : obj.selected &&
                  obj.hidden_val &&
                  obj.hidden_val.properties.some((obj) => obj.value !== "") ? (
                  <i className="fa fa-asterisk mndtryfalse"></i>
                ) : (
                  ""
                )}
                <span
                  className="field_hd"
                  onContextMenu={(e) => {
                    e.preventDefault();
                    m_rid.current = obj.id;
                    setContextMenu(
                      e.button,
                      e.nativeEvent.pageX,
                      e.nativeEvent.pageY
                    );
                  }}
                >
                  {obj.label.name}
                </span>
                {obj.label.showTooltip === "true" && (
                  <UncontrolledTooltip
                    arrowClassName="tip  "
                    className="tip "
                    innerClassName="tip text-dark text-nowrap"
                    target={"tiptar" + obj.index}
                    placement="right"
                  >
                    <pre className="form-pre-tooltip">{obj.label.tooltip}</pre>
                  </UncontrolledTooltip>
                )}
                {obj.label.showHelper === "true" && (
                  <div className="my-2">
                    <AlertCompo
                      type={"warning"}
                      msg={
                        <pre className="form-pre-helper">
                          {obj.label.helper}
                        </pre>
                      }
                    />
                  </div>
                )}
                <div style={{ display: "flex" }}>
                  <input
                    type="text"
                    className={
                      obj.verified === "unverified"
                        ? "form-control formpadd formbor unverifi"
                        : "form-control formpadd formbor"
                    }
                    maxLength={obj.uivalid.max_length}
                    value={
                      page_error || isClick ? obj.gkv?.col_name : obj.value
                    }
                    // value={isClick ? obj.gkv?.col_name : obj.value}
                    disabled={obj.uivalid.read_only === "true" ? true : false}
                    onChange={(e) => {
                      formChangefn(
                        e.target.value,
                        obj.index,
                        obj.name,
                        obj.type,
                        obj.id,
                        false
                      );
                    }}
                    onBlur={(e) => {
                      setTimeout(() => {
                        if (obj.verified !== "verified") {
                          validationfn(
                            obj.value,
                            obj.index,
                            obj.name,
                            obj.type,
                            obj.id
                          );
                        }
                      }, 500);
                    }}
                  ></input>
                  <div
                    className="btnsrc vlpointer"
                    onClick={(e) => {
                      clickRefrence(
                        e,
                        obj.id,
                        obj.name,
                        obj.type,
                        obj.index,
                        obj.label,
                        obj.uivalid.read_only,
                        true
                      );
                    }}
                  >
                    <i className="fa fa-search"></i>
                  </div>
                </div>
              </div>
              {obj.value.length > 1 &&
                refrecord.index === obj.index &&
                showContainer &&
                refrecord.record.length > 0 && (
                  <div className="sf_container">
                    {refrecord.record.map((obj_ref, or_i) => (
                      <div
                        className="refrcd"
                        onClick={(e) =>
                          setRefRcd(
                            obj_ref.value,
                            obj.index,
                            obj.name,
                            obj.type,
                            obj_ref.id,
                            obj_ref.properties ? obj_ref.properties : ""
                          )
                        }
                        key={or_i}
                      >
                        {obj_ref.value}
                      </div>
                    ))}
                  </div>
                )}
              {obj.selected &&
                obj.gkv &&
                obj.gkv.column_value &&
                obj.gkv.column_value.properties.map((props, ind) => (
                  <div key={ind} style={{ padding: "2px 15px" }}>
                    <div key={1} className="d-flex flex-column">
                      <div className="d-flex flex-column">
                        <div className="field_hd text-capitalize">
                          {props.name}
                        </div>
                        <div className="d-flex">
                          <div
                            className="form-control my-2 ms-1 "
                            style={{ width: "auto", fontSize: "18px" }}
                          >
                            {props.choice.map((vals, indd) => (
                              <div
                                key={indd}
                                className="form-check form-check-inline"
                              >
                                <input
                                  className="form-check-input"
                                  onChange={(e) =>
                                    formChangefn(
                                      obj.value,
                                      obj.index,
                                      obj.name,
                                      "key_value_hidden_val",
                                      obj.id,
                                      false,
                                      obj,
                                      ind,
                                      indd
                                    )
                                  }
                                  onBlur={(e) =>
                                    validationfn(
                                      e.target.value,
                                      obj.index,
                                      obj.name,
                                      obj.type,
                                      obj.id
                                    )
                                  }
                                  type="radio"
                                  name={`inlineRadioOptions${ind}`}
                                  id={`inlineRadio${indd}`}
                                  value={vals.value}
                                  checked={
                                    obj.hidden_val &&
                                    obj.hidden_val.properties[ind]?.value ===
                                      vals.value
                                  }
                                />

                                <label
                                  className="form-check-label"
                                  // for="inlineRadio1"
                                >
                                  {vals.value}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          ) : null}
          {obj.type === "label" ? (
            <div className=" form-group">
              <CheckMandatoryField obj={obj} />
              <h5
                id={"tiptar" + obj.index}
                className="field_hd mb-0"
                onTouchStart={(e) => {
                  setTimeout(() => {
                    e.preventDefault();
                    m_rid.current = obj.id;
                    setContextMenu(2, e.touches[0].pageX, e.touches[0].pageY);
                  }, 100);
                }}
                onContextMenu={(e) => {
                  e.preventDefault();
                  m_rid.current = obj.id;
                  setContextMenu(
                    e.button,
                    e.nativeEvent.pageX,
                    e.nativeEvent.pageY
                  );
                }}
              >
                {obj.label.name}:
              </h5>
              {obj.label.showTooltip === "true" && (
                <UncontrolledTooltip
                  arrowClassName="tip  "
                  className="tip "
                  innerClassName="tip text-dark text-nowrap"
                  target={"tiptar" + obj.index}
                  placement="right"
                >
                  <pre className="form-pre-tooltip">{obj.label.tooltip}</pre>
                </UncontrolledTooltip>
              )}
              {obj.label.showHelper === "true" && (
                <div className="my-2">
                  <AlertCompo
                    type={"warning"}
                    msg={
                      <pre className="form-pre-helper">{obj.label.helper}</pre>
                    }
                  />
                </div>
              )}
            </div>
          ) : null}

          {obj.type === "dynamic_key_value" && (
            <div className="form-group ">
              {obj.uivalid.mandatory === "true" &&
                ((Object.keys(obj.value[0])[0] !== "" &&
                  Object.keys(obj.value[0]).length > 0) ||
                  (Object.values(obj.value[0])[0] !== "" &&
                    Object.values(obj.value[0]).length > 0)) && (
                  <i className="fa fa-asterisk mndtryfalse"></i>
                )}

              {obj.uivalid.mandatory === "true" &&
                (Object.keys(obj.value[0])[0] === "" ||
                  Object.keys(obj.value[0]).length === 0) &&
                (Object.values(obj.value[0])[0] === "" ||
                  Object.values(obj.value[0]).length === 0) &&
                obj.uivalid.group_mandatory === "false" && (
                  <i className="fa fa-asterisk mndtrytrue"></i>
                )}
              {obj.uivalid.mandatory === "true" &&
                obj.uivalid.group_mandatory === "true" &&
                (Object.keys(obj.value[0])[0] === "" ||
                  Object.keys(obj.value[0]).length === 0) &&
                (Object.values(obj.value[0])[0] === "" ||
                  Object.values(obj.value[0]).length === 0) && (
                  <i className="fa fa-asterisk mndtrygptrue"></i>
                )}
              <span
                id={"tiptar" + obj.index}
                className="field_hd"
                onTouchStart={(e) => {
                  setTimeout(() => {
                    e.preventDefault();
                    m_rid.current = obj.id;
                    setContextMenu(2, e.touches[0].pageX, e.touches[0].pageY);
                  }, 100);
                }}
                onContextMenu={(e) => {
                  e.preventDefault();
                  setContextMenu(
                    e.button,
                    e.nativeEvent.pageX,
                    e.nativeEvent.pageY
                  );
                }}
              >
                {obj.label.name}
              </span>
              {obj.label.showTooltip === "true" && (
                <UncontrolledTooltip
                  arrowClassName="tip  "
                  className="tip "
                  innerClassName="tip text-dark text-nowrap"
                  target={"tiptar" + obj.index}
                  placement="right"
                >
                  <pre
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: "small",
                      textAlign: "start",
                    }}
                  >
                    {obj.label.tooltip}
                  </pre>
                </UncontrolledTooltip>
              )}
              {obj.label.showHelper === "true" && (
                <div className="alert alert-warning">
                  <pre
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: 14,
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    {obj.label.helper}
                  </pre>
                </div>
              )}
              {obj.value.map((objj, outerIndex) => (
                <div className="d-flex">
                  <div className="d-flex">
                    <input
                      type="text"
                      name="child"
                      className="form-control formpadd me-2 my-1"
                      value={Object.keys(objj)[0]}
                      readOnly={obj.uivalid.read_only === "true"}
                      disabled={obj.uivalid.read_only === "true"}
                      maxLength={obj.uivalid.max_length}
                      onChange={(e) =>
                        formChangefn(
                          e.target.value,
                          obj.index,
                          obj.name,
                          obj.type,
                          obj.id,
                          false,
                          obj,
                          outerIndex
                        )
                      }
                      onBlur={(e) =>
                        validationfn(
                          e.target.value,
                          obj.index,
                          obj.name,
                          obj.type,
                          obj.id
                        )
                      }
                    />
                    <label className="me-2" style={{ alignContent: "center" }}>
                      {" "}
                      :{" "}
                    </label>
                    <input
                      type="text"
                      name="child"
                      className="form-control formpadd me-2 my-1"
                      value={Object.values(objj)[0]}
                      readOnly={obj.uivalid.read_only === "true"}
                      disabled={obj.uivalid.read_only === "true"}
                      maxLength={obj.uivalid.max_length}
                      onChange={(e) =>
                        formChangefn(
                          e.target.value,
                          obj.index,
                          obj.name,
                          obj.type,
                          obj.id,
                          false,
                          obj,
                          outerIndex,
                          1,
                          "string"
                        )
                      }
                      onBlur={(e) =>
                        validationfn(
                          e.target.value,
                          obj.index,
                          obj.name,
                          obj.type,
                          obj.id
                        )
                      }
                    />
                    {obj.uivalid.read_only !== "true" && (
                      <div>
                        {outerIndex === 0 ? (
                          <i
                            style={{
                              fontSize: "26px",
                              cursor: "pointer",
                              marginTop: "9px",
                            }}
                            className="fa-solid fa-plus"
                            onClick={() => addInput(obj)}
                          ></i>
                        ) : (
                          <i
                            style={{
                              fontSize: "26px",
                              cursor: "pointer",
                              marginTop: "9px",
                            }}
                            className="fa-solid fa-minus"
                            onClick={() => removeInput(outerIndex, obj)}
                          ></i>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FormInnerCompo;
