import React, { useEffect } from "react";
import "../css/MainComponent.css";
import LoginForm from "./LoginForm.jsx";
import AppAccess from "./AppAccess.jsx";
import AdminComponent from "./AdminComponent.jsx";
import DashboradComponent from "./DashboradComponent.jsx";
import InitialSetupcomponent from "./InitialSetupComponent.jsx";
import FormComponent from "./FormComponent.jsx";
import PortalComponent from "./PortalComponent.jsx";
import RegisterComponent from "./RegisterComponent.jsx";
import RoleSelectionComponent from "./RoleSelectionComponent.jsx";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import PrivateWrapper from "./PrivateWrapper.jsx";
import ListComponent from "./ListComponent.jsx";
import OwnerPrefComp from "./OwnerPrefrenceComponent.jsx";
import FlowComponent from "./FlowComponent.jsx";
import MultiInsertComponent from "./MultiInsertComponent.jsx";
import MultiTableRelation from "./MultiTableRelation.jsx";
import ApplicationSet from "./ApplicationSet.jsx";
import ReportCompo from "./ReportCompo.jsx";
import ClientInfoCompo from "./ClientInfoCompo.jsx";
import ClientInfoRecCompo from "./ClientInfoRecCompo.jsx";
import EditorComponent from "./EditorComponent.jsx";
import UiPolicy from "./UiPolicy.jsx";
import UpdatePassword from "./UpdatePassword.jsx";
import NewApplication from "./NewApplication.jsx";
import ForgetPassword from "./ForgetPassword.jsx";
import OtpVerification from "./OtpVerification.jsx";
import GenerateNewPin from "./GenerateNewPin.jsx";
import SetNewPassword from "./SetNewPassword.jsx";
import VarBlankCompo from "./VarBlankCompo.jsx";
import "react-toastify/dist/ReactToastify.css";
import ErrorPage from "./ErrorPage.jsx";
import { ToastContainer } from "react-bootstrap";
import UserWrapper from "./UserWrapper.jsx";
import AppSetup from "./AppSetup.jsx";
import KnowledgeBase from "./KnowledgeBase.jsx";
import KnowledgeCompo from "./KnowledgeCompo.jsx";
import KnowledgeCategoryCompo from "./KnowledgeCategoryCompo.jsx";
import HtmlPage from "./HtmlPage.jsx";
import AllNotification from "./AllNotification.jsx";
import InvoiceLabel from "./InvoiceLabel.jsx";
import PortalCompo from "./PortalCompo.jsx";
import PortalResult from "./PortalResult.jsx";
import GridCompo from "./GridCompo.jsx";
import ConfigureCompo from "./ConfigureCompo.jsx";
import TestCompo from "../TestCompo/TestCompo.jsx";
import WebWrapper from "./WebWrapper.jsx";
import FormViewComponent from "./FormViewCompo.jsx";
import UiPolicyAction from "./UiPolicyAction.jsx";
import ChecklistComponent from "./ChecklistComponent.jsx";

const MainComponent = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <WebWrapper />,
      children: [
        {
          path: "/error",
          element: <ErrorPage error={null} />,
        },
        {
          path: "/login",
          element: <LoginForm />,
        },
        {
          path: "/signup",
          element: <RegisterComponent />,
        },
        {
          path: "/ForgetPassword",
          element: <ForgetPassword />,
        },
        {
          path: "/otpverify",
          element: <OtpVerification />,
        },
        {
          path: "/Newpin",
          element: <GenerateNewPin />,
        },
        {
          path: "/setNewPassword",
          element: <SetNewPassword />,
        },
        {
          path: "/NewApplication",
          element: <NewApplication />,
        },
        {
          path: "/testt",
          element: <TestCompo />,
        },
        {
          element: <PrivateWrapper />,
          children: [
            {
              path: "/portal",
              element: <PortalCompo />,
            },
            {
              path: "/test",
              element: <TestCompo />,
            },
            {
              path: "/portalOutPut",
              element: <PortalResult />,
            },
            {
              path: "/grid",
              element: <GridCompo />,
            },
            {
              path: "/admin",
              element: <AdminComponent />,
            },
            {
              path: "/dashboard",
              element: <DashboradComponent />,
            },
            {
              path: "/initialSetup",
              element: <InitialSetupcomponent />,
            },
            {
              path: "/form",
              element: <FormComponent />,
            },
            {
              path: "/nvoice",
              element: <InvoiceLabel />,
            },
            {
              path: "/list",
              element: <ListComponent />,
            },
            {
              path: "/config_list",
              element: <ConfigureCompo />,
            },
            {
              path: "/OwnerPref",
              element: <OwnerPrefComp />,
            },
            {
              path: "/application",
              element: <ApplicationSet />,
            },
            {
              path: "/flow",
              element: <FlowComponent />,
            },
            {
              path: "/report",
              element: <ReportCompo />,
            },
            {
              path: "/viewcompo",
              element: <FormViewComponent />,
            },
            {
              path: "/policy",
              element: <UiPolicy />,
            },
            {
              path: "/policy_action",
              element: <UiPolicyAction />,
            },
            {
              path: "/multiinsert",
              element: <MultiInsertComponent />,
            },
            {
              path: "/multitable",
              element: <MultiTableRelation />,
            },
            {
              path: "/checklicompo",
              element: <ChecklistComponent />,
            },
            {
              path: "/updatepassword",
              element: <UpdatePassword />,
            },
            {
              path: "/appaccess",
              element: <AppAccess />,
            },
            {
              path: "/clientinfo",
              element: <ClientInfoCompo />,
            },
            {
              path: "/var",
              element: <VarBlankCompo />,
            },
            {
              path: "/appsetup",
              element: <AppSetup />,
            },
            {
              path: "/role-selection",
              element: <RoleSelectionComponent />,
            },
            {
              path: "/knowledge",
              element: <KnowledgeCompo />,
            },
            {
              path: "/knowledgeBase",
              element: <KnowledgeBase />,
            },
            {
              path: "/knowledgeCategory",
              element: <KnowledgeCategoryCompo />,
            },
            {
              path: "/htmlcall",
              element: <HtmlPage />,
            },
          ],
        },
        {
          element: <UserWrapper />,
          children: [
            {
              path: "/user/dashboard",
              element: <DashboradComponent />,
            },
            {
              path: "/user/form",
              element: <FormComponent />,
            },
            {
              path: "/user/list",
              element: <ListComponent />,
            },
            {
              path: "/user/OwnerPref",
              element: <OwnerPrefComp />,
            },
            {
              path: "/user/application",
              element: <ApplicationSet />,
            },
            {
              path: "/user/var",
              element: <VarBlankCompo />,
            },
            {
              path: "/user/flow",
              element: <FlowComponent />,
            },
            {
              path: "/user/report",
              element: <ReportCompo />,
            },
            {
              path: "/user/viewcompo",
              element: <FormViewComponent />,
            },
            {
              path: "/user/policy",
              element: <UiPolicy />,
            },
            {
              path: "/user/policy_action",
              element: <UiPolicyAction />,
            },
            {
              path: "/user/checklicompo",
              element: <ChecklistComponent />,
            },
            {
              path: "/user/updatepassword",
              element: <UpdatePassword />,
            },
            {
              path: "/user/role-selection",
              element: <RoleSelectionComponent />,
            },
            {
              path: "/user/multiinsert",
              element: <MultiInsertComponent />,
            },
            {
              path: "/user/multitable",
              element: <MultiTableRelation />,
            },
            {
              path: "/user/clientinfo",
              element: <ClientInfoCompo />,
            },
            {
              path: "/user/clientinforecord",
              element: <ClientInfoRecCompo />,
            },
            {
              path: "/user/portal",
              element: <PortalComponent />,
            },
            {
              path: "/user/texteditor",
              element: <EditorComponent />,
            },
            {
              path: "/user/knowledge",
              element: <KnowledgeCompo />,
            },
            {
              path: "/user/knowledgeBase",
              element: <KnowledgeBase />,
            },
            {
              path: "/user/knowledgeCategory",
              element: <KnowledgeCategoryCompo />,
            },
            {
              path: "/user/allNotification",
              element: <AllNotification />,
            },
            {
              path: "/user/clientinforecord",
              element: <ClientInfoRecCompo />,
            },
          ],
        },
      ],
    },
  ]);

  return (
    <div>
      <RouterProvider router={router} />
      {/* <ToastContainer
        toaststyle={{ marginTop: "2vh" }}
        position="top-center"
        autoclose={1800}
      /> */}
    </div>
  );
};
export default MainComponent;
