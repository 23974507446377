import React, { useContext, useState } from "react";
import "../css/Authentication.css";
import { MarketContext } from "../Context";

import { Card, CardBody, CardHeader, CardFooter } from "reactstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const ForgetPassword = () => {
  const { token, loca, appname } = useContext(MarketContext);
  const [email, setEmail] = useState("");
  const navigation = useNavigate();

  const logincall = () => {
    navigation("/login");
  };
  const fieldverify = (e) => {
    if (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        e
      )
    ) {
      setEmail(e);
    } else {
      setEmail("");
    }
  };

  const sendOTPcallfn = () => {
    if (email === "") {
      alert("check your email");
    } else {
      let fp = { forget_password: { email: email } };
      axios
        .post(loca + "/marketplace/set/forgetpassword", fp, {
          headers: {
            "market-application": appname,
          },
        })
        .then(
          (resp) => {
            const fpd = resp.data;
            let c = "Error" in fpd;
            if ("Error" in fpd) {
              alert(fpd.Error);
            } else {
              let e = fpd.email;
              localStorage.setItem("email", e);
              navigation("/otpverify", {
                state: { email: email },
              });
            }
          },
          (error) => {
            navigation("/error");
          }
        );
    }
  };

  return (
    <div className="parent-div">
      <Card className="bg-auth-1" style={{ width: "300px" }}>
        <CardHeader className="bg-auth-1 border-0">
          <h4 className="bg-auth-1 text-center mb-0 mt-2">Forgot Password</h4>
          <hr />
        </CardHeader>

        <CardBody className="py-0 text-center">
          <div className="text-center mb-3 mt-2">
            <i
              className="fa fa-user"
              
              style={{
                color: "white",
                border: "2.5px solid white",
                height: "4.5rem",
                width: "4.5rem",
                fontSize: "62px",
                textAlign: "center",
                borderRadius: "39px",
              }}
            ></i>
          </div>
          <div className="pb-2">
            <input
              type="email"
              name="email"
              placeholder="Enter Email"
              className="inpptt "
              onBlur={(e) => fieldverify(e.target.value)}
            ></input>
          </div>
          <div className="my-2">
            <button
              className="btn btn-warning btn-width"
              onClick={sendOTPcallfn}
            >
              Send OTP
            </button>
          </div>
          <hr className="mb-1" />
        </CardBody>

        <CardFooter className="border-0 pt-0">
          <div
            className="d-flex align-items-center cursor-pointer"
            onClick={logincall}
          >
            <i
              className="fa fa-arrow-left p-0 mx-1 mb-1"
              style={{
                color: "white",
                cursor: "pointer",
              }}
            ></i>
            <span>Back</span>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};
export default ForgetPassword;
