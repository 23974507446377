import axios from "axios";
import React, { useContext, useEffect, useState, useRef } from "react";
import { MarketContext } from "../Context";
import { Modal, Button } from "react-bootstrap";
import ModelList from "./ModelList";
import "../css/filterCompo.css";

const NewFilterCompo = ({
  filtarray,
  col_mn,
  timeline,
  setTimeLine,
  call_fil,
  tableName,
  tabId,
  isMobile,
  isScript,
  script,
  setScript,
  selectedScript,
  col_depend,
  setFiltArray,
  dcFiltVal,
  isRelatedList,
}) => {
  const { token, loca, appname } = useContext(MarketContext);
  const onerender = useRef(true);
  const [selectedOption, setSelectedOption] = useState("");
  const int_fields = useRef([]).current;
  const email_fields = useRef([]).current;
  const date_fields = useRef([]).current;
  const dtm_fields = useRef([]).current;
  const choice_fields = useRef([]).current;
  const boln_fields = useRef([]).current;
  const str_fields = useRef([]).current;
  const ref_fields = useRef([]).current;
  const refUT_fields = useRef([]).current;
  const booleanfld = useRef([]).current;
  const tm_list = useRef([]).current;
  const [filter_unset, setFilter_Unset] = useState(false);
  const [columnId, setColumnId] = useState();
  const [objIndex, setObjIndex] = useState();
  const [showModelList, setshowModelList] = useState(false);
  const [userTable, setUserTable] = useState("false");
  const [unverified, setUnverified] = useState(false);
  const [flag, setFlag] = useState(false);
  const [refrecord, setRefrecord] = useState({});

  const initallist = () => {
    str_fields.push("");
    str_fields.push("is");
    str_fields.push("is not");
    str_fields.push("starts with");
    str_fields.push("ends with");
    str_fields.push("contains");
    int_fields.push("");
    int_fields.push(">");
    int_fields.push("<");
    int_fields.push("is");
    email_fields.push("");
    email_fields.push("is");
    email_fields.push("starts with");
    boln_fields.push("");
    boln_fields.push("is");
    date_fields.push("");
    date_fields.push(">");
    date_fields.push("is");
    date_fields.push("<");
    dtm_fields.push("");
    dtm_fields.push(">");
    dtm_fields.push("is");
    dtm_fields.push("<");
    choice_fields.push("");
    choice_fields.push("is");
    choice_fields.push("is not");
    choice_fields.push("starts with");
    choice_fields.push("contains");
    choice_fields.push("ends with");
    booleanfld.push("");
    booleanfld.push("true");
    booleanfld.push("false");
    ref_fields.push("");
    ref_fields.push("is");
    refUT_fields.push("");
    refUT_fields.push("is");
    refUT_fields.push("is me");
    refUT_fields.push("is dependent");
    if (!isScript) {
      tm_list.push({ name: "", label: "None" });
      tm_list.push({ name: "current24", label: "Current 24" });
      tm_list.push({ name: "previous24", label: "Previous 24" });
      tm_list.push({ name: "week", label: "Week" });
      tm_list.push({ name: "last14days", label: "Last 14 Days" });
      tm_list.push({ name: "last30", label: "Last 30" });
      tm_list.push({ name: "last6month", label: "Last 6 Month" });
      tm_list.push({ name: "last1year", label: "Last 1 Year" });
    }
    if (filtarray[0].cl !== "" && filtarray[0].cl !== null) {
      for (let i = 0; i < col_mn.length; i++) {
        if (col_mn[i].label === filtarray[0].cl) {
          setColumnId(col_mn[i].id);
          break;
        }
      }
    }
    setFlag(!flag);
    // filtarray.push(filt);
    // getListRecord();
  };

  const changecolumn = (e, index, col_id) => {
    const vl = e;
    var farray = filtarray;
    var col_id = "";
    var ut = "";
    for (var i = 0; i < col_mn.length; i++) {
      if (col_mn[i].name === vl) {
        farray[index].ct = col_mn[i].type;
        farray[index].cl = col_mn[i].label;
        farray[index].co = col_mn[i].name.toString();
        farray[index].mc = "";
        farray[index].an = "";
        ut = col_mn[i].userTable;
        col_id = col_mn[i].id;
        break;
      }
    }
    setColumnId(col_id);
    call_fil(farray, index, col_id);
    setFiltArray([...farray]);
    setUserTable(ut);
  };

  const changemiddle = (e, index) => {
    var farray = filtarray;
    if (e !== undefined) {
      farray[index].mc = e.toString();
    }
    call_fil(farray, index, -1);
  };

  const changelast = (e, index) => {
    var farray = filtarray;
    var val = farray[0].co;
    var max_length = "";
    var validation = "";
    for (let i = 0; i < col_mn.length; i++) {
      if (col_mn[i].name === val) {
        max_length = col_mn[i].uiValidation.max_length;
        validation = col_mn[i].uiValidation.uiValidation;
      }
    }
    var regx = new RegExp("^[0-9]{0," + max_length + "}$");
    if (validation === "number") {
      if (e !== undefined && regx.test(e)) {
        farray[index].an = e.toString();
      }
    } else {
      farray[index].an = e.toString();
    }
    call_fil(farray, index, -1);
  };

  const changelastForInt = (e, index) => {
    var farray = filtarray;
    var val = farray[0].co;
    var max_length = "";
    for (let i = 0; i < col_mn.length; i++) {
      if (col_mn[i].name === val) {
        max_length = col_mn[i].uiValidation.max_length;
      }
    }
    var regx = new RegExp("^[0-9]{0," + max_length + "}$");
    if (e !== undefined && /^[0-9]*$/.test(e) && regx.test(e)) {
      farray[index].an = e.toString();
    }
    call_fil(farray, index, -1);
  };

  const changelastref = (e, index, name, type) => {
    var farray = filtarray;
    var vl = farray[index].co;
    var col_id = "";
    for (var i = 0; i < col_mn.length; i++) {
      if (col_mn[i].name === vl) {
        col_id = col_mn[i].id;
        break;
      }
    }
    if (e.target.value !== undefined) {
      formChangefn(e.target.value, index, name, type, col_id, false);
      farray[index].rf = { id: "0", value: e.target.value.toString() };
    }
    call_fil(farray, index, -1);
  };

  const setRef = (r_id, val) => {
    var farray = filtarray;
    var col_index = objIndex;
    farray[col_index].rf = { id: r_id, value: val };
    call_fil(filtarray, objIndex, -1);
    cancelModelList();
  };

  const formChangefn = async (vl, index, ob, type, id, vrf) => {
    if (vl.length > 2) {
      if (vrf === true) {
        var farray = filtarray;
        farray[index].rf.value = vl;
        farray[index].rf.id = id;
        call_fil(farray, index, -1);
        setRefrecord({});
        // setState({ filtarray: farray, refrecord: rfrcd2 });
      } else {
        var veri = '{"referenceRecord":[{"columnid":"' + id + '"},';
        veri += '{"tabvalue":"' + vl + '"}]}';
        var rff = [];
        axios
          .post(loca + `/marketplace/reference/record`, veri.toString(), {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + token,
              "market-application": appname,
            },
          })
          .then((resp) => {
            const refrencercd = resp.data;
            if (refrencercd !== "") {
              if ("Error" in refrencercd) {
              } else {
                var ref_rcd = refrencercd.referenceRecordList[2].records.length;

                if (ref_rcd === 0) {
                  setUnverified(true);
                } else {
                  setUnverified(false);
                }
                if (ref_rcd >= 5) {
                  ref_rcd = 5;
                }

                let rcod = refrencercd.referenceRecordList[2].records;

                for (var i = 0; i < ref_rcd; i++) {
                  rff.push(refrencercd.referenceRecordList[2].records[i]);

                  if (rcod[i].value === vl) {

                    let farray = filtarray;
                    farray[index].rf.value = rcod[i].value;
                    farray[index].rf.id = rcod[i].id;
                    call_fil(farray, index, -1);
                    setRefrecord({});
                  }
                }
              }
            }
            var rf = { index: index, record: rff };
            setRefrecord(rf);
            // setState({ refrecord: rf });
          });
      }
    } else {
      var farray = filtarray;
      farray[index].rf.value = "";
      farray[index].rf.id = "";
      setUnverified(false);
      setRefrecord({});
    }
  };

  const cancelModelList = () => {
    // this.setState({ show: false });
    setshowModelList(false);
  };

  const addbtn = (ind) => {
    if (filterset()) {
      filtarray.push({
        co: "",
        cl: "",
        mc: "",
        an: "",
        ct: "",
        af: "AND",
        rf: { id: "", value: "" },
        dc: { id: "", value: "" },
        ch: [],
      });
      call_fil(filtarray, ind, -1);
      setFilter_Unset(false);
    } else {
      setFilter_Unset(true);
    }
  };

  const orbtn = (ind) => {
    if (filterset()) {
      // var len = filtarray.length;
      filtarray.push({
        co: "",
        cl: "",
        mc: "",
        an: "",
        ct: "",
        af: "OR",
        rf: { id: "", value: "" },
        dc: { id: "", value: "" },
        ch: [],
      });
      // call_fil(filtarray);
      call_fil(filtarray, ind, -1);
      setFilter_Unset(false);
    } else {
      setFilter_Unset(true);
    }
  };

  const filterset = () => {
    for (var i = 0; i < filtarray.length; i++) {
      if (filtarray[i].ct === "") {
        return false;
      } else {
        if (
          filtarray[i].ct === "String" ||
          filtarray[i].ct === "long_description" ||
          filtarray[i].ct === "short_description" ||
          filtarray[i].ct === "int" ||
          filtarray[i].ct === "choice" ||
          filtarray[i].ct === "boolean" ||
          filtarray[i].ct === "date" ||
          filtarray[i].ct === "datetime" ||
          filtarray[i].ct === "filter" ||
          filtarray[i].ct === "json" ||
          filtarray[i].ct === "html" ||
          filtarray[i].ct === "email"
        ) {
          if (filtarray[i].an === "") {
            return false;
          }
        } else if (
          filtarray[i].ct === "reference" ||
          filtarray[i].ct === "depend_table"
        ) {
          if (filtarray[i].rf.value === "") {
            return false;
          }
        }
      }
    }
    return true;
  };

  const setRefRcd = (vl, index, ob, type, id) => {
    formChangefn(vl, index, ob, type, id, true);
  };

  const cancelfilt = (i) => {
    filtarray.splice(i, 1);
    if (filtarray.length === 0) {
      filtarray.push({
        co: "",
        cl: "",
        mc: "",
        an: "",
        af: "",
      });
    }
    if (filterset) {
      setFilter_Unset(false);
    }
    call_fil(filtarray, i, -1);
  };

  const changeDependentRefColumn = (e, index) => {
    var farray = filtarray;
    var col_id = "";
    for (var i = 0; i < col_depend.length; i++) {
      if (col_depend[i].name === e.target.value) {
        col_id = col_depend[i].id;
        break;
      }
    }
    if (e.target.value !== undefined) {
      farray[index].dc = { id: col_id, value: e.target.value.toString() };
    }
    setFiltArray(farray);
  };

  const [depColVal, setDepColVal] = useState();
  const [dcVal, setDcVal] = useState(dcFiltVal);
  const dependColumnValue = (abc, index) => {
    let farray = filtarray;
    if (col_depend.length > 0) {
      for (let i = 0; i < col_depend.length; i++) {
        if (col_depend[i].name === abc) {
          setDepColVal(col_depend[i].label);
          farray[index].dc = {
            id: col_depend[i].id,
            value: abc.toString(),
            label: col_depend[i].label,
          };
          setFiltArray([...farray]);
          break;
        } else {
          setDepColVal();
          setDcVal("");
        }
      }
    } else {
      setDepColVal();
      setDcVal("");
    }
  };

  const changeDependentColumn = (e, index) => {
    dependColumnValue(e.target.value, index);
    var farray = filtarray;
    var col_id = "";
    for (var i = 0; i < col_mn.length; i++) {
      if (col_mn[i].name === e.target.value) {
        col_id = col_mn[i].id;
        break;
      }
    }
    if (e.target.value !== undefined) {
      farray[index].rf = { id: col_id, value: e.target.value.toString() };
    }
    setFiltArray([...farray]);
  };

  useEffect(() => {
    if (onerender.current) {
      initallist();
    } else {
      onerender.current = false;
    }
  }, []);

  let reflist_UT = () => {
    if (refUT_fields.length > 0) {
      return refUT_fields.map((item, b_i) => (
        <option key={b_i} value={item}>
          {item}
        </option>
      ));
    }
  };

  let strlist = () => {
    if (str_fields.length > 0) {
      return str_fields.map((item, st_i) => (
        <option key={st_i} value={item}>
          {item}
        </option>
      ));
    }
  };

  let intlist = () => {
    if (int_fields.length > 0) {
      return int_fields.map((item, s_i) => (
        <option key={s_i} value={item}>
          {item}
        </option>
      ));
    }
  };

  let bolnlist = () => {
    if (boln_fields.length > 0) {
      return boln_fields.map((item, bo_i) => (
        <option key={bo_i} value={item}>
          {item}
        </option>
      ));
    }
  };

  let reflist = () => {
    if (ref_fields.length > 0) {
      return ref_fields.map((item, b_i) => (
        <option key={b_i} value={item}>
          {item}
        </option>
      ));
    }
  };

  let datelist = () => {
    if (date_fields.length > 0) {
      return date_fields.map((item, b_i) => (
        <option key={b_i} value={item}>
          {item}
        </option>
      ));
    }
  };

  let emailist = () => {
    if (email_fields.length > 0) {
      return email_fields.map((item, b_i) => (
        <option key={b_i} value={item}>
          {item}
        </option>
      ));
    }
  };

  let choicelist = () => {
    if (choice_fields.length > 0) {
      return choice_fields.map((item, b_i) => (
        <option key={b_i} value={item}>
          {item}
        </option>
      ));
    }
  };

  let dtmlist = () => {
    if (dtm_fields.length > 0) {
      return dtm_fields.map((item, b_i) => (
        <option key={b_i} value={item}>
          {item}
        </option>
      ));
    }
  };

  let booleanfd = () => {
    if (booleanfld.length > 0) {
      return booleanfld.map((item, bb_i) => (
        <option key={bb_i} value={item}>
          {item}
        </option>
      ));
    }
  };

  return (
    <div style={{ textAlign: "start" }}>
      <div className={isMobile ? "scrl" : "form-control filteroverflow"}>
        {isScript ? (
          <div className="row ">
            <div className="col-md-2 mb-2">Script :</div>
            <div className="col-md-2 mb-2">
              <select
                className="inpt_typefil inpu_rad"
                type="text"
                onChange={(e) => setScript(e.target.value)}
                value={selectedScript}
              >
                <option value={""}>None</option>
                {script.map((tm, tm_o) => (
                  <option value={tm.name} key={tm_o}>
                    {tm.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        ) : (
          <div className="disfl ">
            <div className="pdrt10 pdlf10 pdtp5">Time Line :</div>
            <div className="fntSz15">
              <select
                className="inpt_typefil inpu_rad"
                type="text"
                onChange={(e) => setTimeLine(e.target.value)}
                //  value={deftimeline?.current}
                value={timeline ? timeline : ""}
              >
                {tm_list.map((tm, tm_o) => (
                  <option value={tm.name} key={tm_o}>
                    {tm.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
        {filter_unset && (
          <div className="alert alert-danger">
            Please set previous filter before adding a new filter
          </div>
        )}
        {filtarray &&
          filtarray.length > 0 &&
          filtarray.map((abc, index) => (
            <div key={index}>
              {isMobile ? (
                <div className="disfl martop" key={index}>
                  {abc.af === "AND" && filtarray.length > 1 && (
                    <div className="pdrt10">
                      <span className="addandcancel"> And </span>
                    </div>
                  )}
                  {abc.af === "OR" && filtarray.length > 1 && (
                    <div className="pdrt10">
                      <span className="addandcancel"> OR </span>
                    </div>
                  )}
                  <div className="pdrt10">
                    <select
                      className=" selct_optfil "
                      onChange={(e) => changecolumn(e.target.value, index)}
                      value={abc.co}
                    >
                      {col_mn.map((clm, cl_o) => (
                        <option value={clm.name} key={cl_o}>
                          {clm.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="pdrt10">
                    <div>
                      <select
                        className=" selct_optfil"
                        onChange={(e) => changemiddle(e.target.value, index)}
                        value={abc.mc}
                      >
                        {abc.ct === "String" ? strlist() : null},
                        {abc.ct === "long_description" ? strlist() : null},
                        {abc.ct === "short_description" ? strlist() : null},
                        {abc.ct === "int" ? intlist() : null},
                        {abc.ct === "boolean" ? bolnlist() : null},
                        {abc.ct === "reference" && userTable === false
                          ? reflist()
                          : null}
                        ,
                        {abc.ct === "reference" && userTable === true
                          ? reflist_UT()
                          : null}
                        ,{" "}
                        {abc.ct === "key_value" && userTable === false
                          ? reflist()
                          : null}
                        ,
                        {abc.ct === "key_value" && userTable === true
                          ? reflist_UT()
                          : null}
                        {abc.ct === "date" ? datelist() : null},
                        {abc.ct === "datetime" ? dtmlist() : null},
                        {abc.ct === "email" ? emailist() : null},
                        {abc.ct === "choice" ? choicelist() : null},
                        {abc.ct === "depend_table" ? reflist() : null},
                        {abc.ct === "multi_select" ? reflist() : null},
                      </select>
                    </div>
                  </div>
                  <div className="pdrt10">
                    <div>
                      {abc.ct === "String" ||
                        abc.ct === "long_description" ||
                        abc.ct === "short_description" ? (
                        <input
                          className="inpt_typefil"
                          type="text"
                          placeholder="value"
                          value={abc.an}
                          onChange={(e) => changelast(e.target.value, index)}
                        ></input>
                      ) : null}
                      {abc.ct === "int" ? (
                        <input
                          className="inpt_typefil"
                          type="text"
                          placeholder="value"
                          value={abc.an}
                          onChange={(e) =>
                            changelastForInt(e.target.value, index)
                          }
                        ></input>
                      ) : null}
                      {abc.ct === "date" ? (
                        <input
                          className="inpt_typefil"
                          type="date"
                          value={abc.an}
                          onChange={(e) => changelast(e.target.value, index)}
                        ></input>
                      ) : null}

                      {abc.ct === "boolean" ? (
                        <select
                          className="inpt_typefil"
                          type="text"
                          value={abc.an}
                          onChange={(e) => changelast(e.target.value, index)}
                        >
                          {abc.ct === "boolean" ? booleanfd() : null},
                        </select>
                      ) : null}
                      {abc.ct === "datetime" ? (
                        <input
                          className="inpt_typefil"
                          type="datetime-local"
                          step="1"
                          value={abc.an}
                          onChange={(e) => changelast(e.target.value, index)}
                        ></input>
                      ) : null}
                      {abc.ct === "choice" ? (
                        <select
                          className=" selct_optfil"
                          // defaultValue={abc.ch[0].name}
                          value={abc.an}
                          onChange={(e) => changelast(e.target.value, index)}
                        >
                          {abc.ch.length > 0 &&
                            abc.ch.map((ch, ind) => (
                              <option key={ind} value={ch.name}>
                                {ch.name}
                              </option>
                            ))}
                        </select>
                      ) : null}
                      {(abc.ct === "reference" ||
                        abc.ct === "depend_table" ||
                        abc.ct === "key_value") &&
                        abc.mc === "is dependent" ? (
                        <div>
                          <div style={{ display: "flex" }}>
                            <select
                              className="selct_optfil"
                              onChange={(e) => changeDependentColumn(e, index)}
                              value={abc.rf.value}
                            >
                              {col_mn.map((clm, cl_o) => (
                                <option value={clm.name} key={cl_o}>
                                  {clm.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      ) : null}
                      {(abc.ct === "reference" ||
                        abc.ct === "depend_table" ||
                        abc.ct === "multi_select" ||
                        abc.ct === "key_value") &&
                        abc.mc !== "is me" &&
                        abc.mc !== "is dependent" ? (
                        <div>
                          <div style={{ display: "flex", height: "1.9rem" }}>
                            <input
                              className="inpt_typefil_ref"
                              type="text"
                              placeholder="value"
                              value={abc.rf.value}
                              onChange={(e) => changelastref(e, index)}
                            ></input>
                            <div
                              className="btnsrc_fil vlpointer"
                              onClick={(e) => {
                                setObjIndex(index);
                                setshowModelList(true);
                              }}
                            >
                              <i
                                className="fa fa-search"

                                data-bs-toggle="modal"
                                data-bs-target="#staticBackdrop"
                              ></i>
                            </div>
                            {refrecord.index === index &&
                              refrecord.record.length > 0 && (
                                <div className="ref_multircd">
                                  {refrecord.record.map((obj_ref, or_i) => (
                                    <div
                                      onClick={(e) =>
                                        setRefRcd(
                                          obj_ref.value,
                                          index,
                                          obj_ref.name,
                                          obj_ref.type,
                                          obj_ref.id
                                        )
                                      }
                                      key={or_i}
                                    >
                                      {obj_ref.value}
                                    </div>
                                  ))}
                                </div>
                              )}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="selct_optfil" style={{ display: "flex" }}>
                    {(abc.ct === "reference" ||
                      abc.ct === "depend_table" ||
                      abc.ct === "key_value") &&
                      abc.mc === "is dependent" ? (
                      <div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span>Dependent on:</span>
                          <div style={{ paddingLeft: "5px" }}>
                            <input
                              className="inpt_typefil inpu_rad"
                              disabled={true}
                              type="text"
                              value={depColVal ? depColVal : dcVal ? dcVal : ""}
                            />
                            {/* <select
                                className="selct_optfil"
                                onChange={(e) =>
                                  changeDependentRefColumn(e, index)
                                  // changeDependentRefColumn(e, abc.rf.value)
                                }
                                value={abc.dc.value}
                              >
                                {col_depend.map((clm, cl_o) => (
                                  <option value={clm.name} key={cl_o}>
                                    {clm.label}
                                  </option>
                                ))}
                              </select> */}
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="pdrt10"></div>
                  <div className="col-md-2 ">
                    <div className="padbtnnn">
                      <div className="cancelbtn">
                        <i
                          className="fa fa-times"

                          onClick={(e) => cancelfilt(index)}
                        ></i>
                      </div>
                      <button
                        type="button"
                        // className=" btnnn-sm btn btn-primary btn-sm"
                        className="insrtbtn2 btn btn-primary btn-sm sub-btn-form"
                        onClick={() => {
                          addbtn(index);
                        }}
                      >
                        AND
                      </button>

                      <button
                        type="button"
                        // className=" btnnn-sm btn btn-primary btn-sm"
                        className="insrtbtn2 btn btn-primary btn-sm sub-btn-form"
                        onClick={() => {
                          orbtn(index);
                        }}
                      >
                        OR
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="disfl martop" key={index}>
                  {abc.af === "AND" && filtarray.length > 1 && (
                    <div className="pdrt10">
                      <span className="addandcancel"> And </span>
                    </div>
                  )}
                  {abc.af === "OR" && filtarray.length > 1 && (
                    <div className="pdrt10">
                      <span className="addandcancel"> OR </span>
                    </div>
                  )}
                  <div className="pdlf10 pdrt10">
                    <select
                      disabled={isRelatedList && index === 0}
                      className=" selct_optfil "
                      onChange={(e) => {
                        setSelectedOption(e.target.value);
                        changecolumn(e.target.value, index); // Assuming index is available
                      }}
                      value={abc.co}
                    >
                      {col_mn.length > 0 &&
                        [
                          col_mn[0],
                          ...col_mn
                            .slice(1)
                            .sort((a, b) => a.label.localeCompare(b.label)),
                        ].map((clm, cl_o) => (
                          <option value={clm.name} key={cl_o}>
                            {clm.label}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="pdrt10">
                    <div>
                      <select
                        disabled={isRelatedList && index === 0}
                        className=" selct_optfil"
                        onChange={(e) => changemiddle(e.target.value, index)}
                        value={abc.mc}
                      >
                        {abc.ct === "String" ? strlist() : null},
                        {abc.ct === "long_description" ? strlist() : null},
                        {abc.ct === "short_description" ? strlist() : null},
                        {abc.ct === "int" ? intlist() : null},
                        {abc.ct === "boolean" ? bolnlist() : null},
                        {abc.ct === "reference" && userTable === "false"
                          ? reflist()
                          : null}
                        ,
                        {abc.ct === "reference" && userTable === "true"
                          ? reflist_UT()
                          : null}
                        ,
                        {abc.ct === "key_value" && userTable === "false"
                          ? reflist()
                          : null}
                        ,
                        {abc.ct === "key_value" && userTable === "true"
                          ? reflist_UT()
                          : null}
                        {abc.ct === "date" ? datelist() : null},
                        {abc.ct === "datetime" ? dtmlist() : null},
                        {abc.ct === "email" ? emailist() : null},
                        {abc.ct === "choice" ? choicelist() : null},
                        {abc.ct === "depend_table" ? reflist() : null},
                        {abc.ct === "multi_select" ? reflist() : null}
                      </select>
                    </div>
                  </div>
                  {abc.ct !== "" && (
                    <div className="pdrt10">
                      <div>
                        {abc.ct === "String" ||
                          abc.ct === "short_description" ||
                          abc.ct === "long_description" ||
                          abc.ct === "email" ? (
                          <input
                            disabled={isRelatedList && index === 0}
                            className="inpt_typefil inpu_rad"
                            type="text"
                            placeholder="value"
                            value={abc.an}
                            onChange={(e) => changelast(e.target.value, index)}
                          ></input>
                        ) : null}
                        {abc.ct === "int" ? (
                          <input
                            disabled={isRelatedList && index === 0}
                            className="inpt_typefil inpu_rad"
                            type="text"
                            placeholder="value"
                            value={abc.an}
                            onChange={(e) =>
                              changelastForInt(e.target.value, index)
                            }
                          ></input>
                        ) : null}
                        {abc.ct === "date" ? (
                          <input
                            disabled={isRelatedList && index === 0}
                            className="inpt_typefil inpu_rad"
                            type="date"
                            value={abc.an}
                            onChange={(e) => changelast(e.target.value, index)}
                          ></input>
                        ) : null}

                        {abc.ct === "boolean" ? (
                          <select
                            disabled={isRelatedList && index === 0}
                            className="inpt_typefil inpu_rad"
                            type="text"
                            value={abc.an}
                            onChange={(e) => changelast(e.target.value, index)}
                          >
                            {abc.ct === "boolean" ? booleanfd() : null},
                          </select>
                        ) : null}
                        {abc.ct === "datetime" ? (
                          <input
                            disabled={isRelatedList && index === 0}
                            className="inpt_typefil"
                            type="datetime-local"
                            step="1"
                            value={abc.an}
                            onChange={(e) => changelast(e.target.value, index)}
                          ></input>
                        ) : null}
                        {abc.ct === "choice" ? (
                          <select
                            className=" selct_optfil inpu_rad"
                            disabled={isRelatedList && index === 0}
                            value={abc.an}
                            onChange={(e) => changelast(e.target.value, index)}
                          >
                            {abc.ch.length > 0 &&
                              abc.ch.map((ch, ind) => (
                                <option key={ind} value={ch.name}>
                                  {ch.name}
                                </option>
                              ))}
                          </select>
                        ) : null}
                        {(abc.ct === "reference" ||
                          abc.ct === "depend_table" ||
                          abc.ct === "key_value") &&
                          abc.mc === "is dependent" ? (
                          <div>
                            <div style={{ display: "flex" }}>
                              <select
                                className="selct_optfil"
                                onChange={(e) =>
                                  changeDependentColumn(e, index)
                                }
                                value={abc.rf.value}
                                disabled={isRelatedList && index === 0}
                              >
                                {col_mn.map((clm, cl_o) => (
                                  <option value={clm.name} key={cl_o}>
                                    {clm.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        ) : null}
                        {(abc.ct === "reference" ||
                          abc.ct === "depend_table" ||
                          abc.ct === "multi_select" ||
                          abc.ct === "key_value") &&
                          abc.mc !== "is me" &&
                          abc.mc !== "is dependent" ? (
                          <div>
                            {/* {unverified && (
                              <div
                                className="alert alert-danger"
                                role="alert"
                                style={{
                                  padding: "0.2rem 0.2rem",
                                  marginBottom: "0px",
                                }}
                              >
                                Record not found!
                              </div>
                            )} */}
                            <div style={{ display: "flex", height: "1.9rem" }}>
                              <input
                                disabled={isRelatedList && index === 0}
                                className="inpt_typefil_ref"
                                type="text"
                                placeholder="value"
                                value={abc.rf.value}
                                onChange={(e) => changelastref(e, index)}
                              ></input>
                              <div
                                className="btnsrc_fil vlpointer"
                                onClick={(e) => {
                                  if (isRelatedList) {
                                    if (index !== 0) {
                                      setObjIndex(index);
                                      setshowModelList(true);
                                    }
                                  } else {
                                    setObjIndex(index);
                                    setshowModelList(true);
                                  }
                                }}
                              >
                                <i className="fa fa-search"></i>
                              </div>
                            </div>
                            {refrecord.index === index &&
                              refrecord.record.length > 0 && (
                                <div className="ref_multircd">
                                  {refrecord.record.map((obj_ref, or_i) => (
                                    <div
                                      onClick={(e) =>
                                        setRefRcd(
                                          obj_ref.value,
                                          index,
                                          obj_ref.name,
                                          obj_ref.type,
                                          obj_ref.id
                                        )
                                      }
                                      key={or_i}
                                    >
                                      {obj_ref.value}
                                    </div>
                                  ))}
                                </div>
                              )}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  )}

                  <div className="pdrt10" style={{ display: "flex" }}>
                    {(abc.ct === "reference" ||
                      abc.ct === "depend_table" ||
                      abc.ct === "key_value") &&
                      abc.mc === "is dependent" ? (
                      <div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span>Dependent on:</span>
                          <div style={{ paddingLeft: "5px" }}>
                            <input
                              className="inpt_typefil inpu_rad"
                              disabled={true}
                              type="text"
                              value={depColVal ? depColVal : dcVal ? dcVal : ""}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <div className="d-flex align-items-center">
                    {isRelatedList && index === 0 ? (
                      ""
                    ) : (
                      <i
                        className="fa fa-times"
                        onClick={(e) => cancelfilt(index)}
                      ></i>
                    )}
                    <button
                      type="button"
                      // className=" btnnn-sm btn btn-primary btn-sm"
                      className="insrtbtn2 btn btn-primary btn-sm sub-btn-form"
                      onClick={() => {
                        addbtn(index);
                      }}
                    >
                      AND
                    </button>

                    <button
                      type="button"
                      // className=" btnnn-sm btn btn-primary btn-sm"
                      className="insrtbtn2 btn btn-primary btn-sm sub-btn-form"
                      onClick={() => {
                        orbtn(index);
                      }}
                    >
                      OR
                    </button>
                  </div>
                </div>
              )}
            </div>
          ))}
      </div>
      <Modal show={showModelList} onHide={cancelModelList} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Personalized List Column</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mod-ht">
          <ModelList
            setRef={setRef}
            columnid={columnId}
            onCancel={cancelModelList}
            previousFilter={"null"}
            previousTimeLine={" "}
            tableName={tableName}
            tabId={tabId}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={cancelModelList}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default NewFilterCompo;
