import React from 'react'
import '../css/WorkInProgress.css'

const WorkInProgress = () => {
  return (
    <div className='w-100 d-flex justify-content-center align-items-center'>

    <div className="main d-flex flex-column justify-content-center" style={{ margin: "16px 16px 15px 18px"}}>
        <div className="loader"></div>
        <span>Loading...</span>
    </div>
</div>
  )
}

export default WorkInProgress
